/** Component for line graphs with reference lines */
import * as React from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

type RearPendulumProps = {
  data: any;
  TOLocation: { x: number; y: number };
  MSwLocation: { x: number; y: number };
  stanceData: any;
};

export const RearPendulum = ({ data, TOLocation, MSwLocation, stanceData }: RearPendulumProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = React.useState('small');

  const chartData = [...data, stanceData];

  React.useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);

  const markerSizes = [];
  const markerColors = [];
  for (const value of Object.values(data)) {
    const Value = value as unknown as any;
    const type = Value.type;
    const name = Value.name;
    if (type === 'scatter') {
      markerSizes.push(3);
      if (name === 'TO') {
        markerColors.push('#E81A1A');
      } else {
        markerColors.push('#2066FA');
      }
    } else {
      markerSizes.push(0);
      markerColors.push('#E81A1A');
    }
  }
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
      min: -0.8,
      max: 0.8,
      tickAmount: 4,
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(1);
        },
        style: {
          fontSize: '8px',
        },
      },
      min: 0,
      max: 0.8,
      tickAmount: 4,
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: [1],
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    markers: {
      size: markerSizes,
      colors: markerColors,
      strokeWidth: 1,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      points: [
        {
          x: TOLocation.x,
          y: TOLocation.y,
          marker: {
            size: 0,
          },
          label: {
            offsetX: 10,
            offsetY: -2,
            borderColor: 'none',
            style: {
              color: '#707070',
              background: 'transparent',
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
            text: 'TO',
          },
        },
        {
          x: MSwLocation.x,
          y: MSwLocation.y,
          marker: {
            size: 0,
          },
          label: {
            offsetX: 12,
            offsetY: -2,
            borderColor: 'none',
            style: {
              color: '#707070',
              background: 'transparent',
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
            text: 'MSw',
          },
        },
      ],
    },
  };

  return (
    <>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Chart options={options} series={chartData} type='line' height={windowSize === 'small' ? 115 : 200} />
      </div>
    </>
  );
};
