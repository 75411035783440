import React from 'react';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { shieldCheckmarkOutline } from 'ionicons/icons';
import './newPassword.css';

export const MustContainItem = (props: { data: any }) => {
  //eslint-disable-next-line
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ['icon'];
    if (meetsReq) classArr.push('succes');
    return classArr.join(' ');
  };

  return (
    <>
      <IonItem lines='none' className='must-item'>
        <IonIcon icon={shieldCheckmarkOutline} slot='start' className={setClass()}></IonIcon>
        <IonLabel>
          <div className='must-text'>{label}</div>
        </IonLabel>
      </IonItem>
    </>
  );
};
