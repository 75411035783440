import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRouterOutlet,
  IonRow,
  IonSpinner,
  IonText,
  IonToast,
} from '@ionic/react';
import {
  alertOutline,
  calendarOutline,
  closeOutline,
  cloudDownloadOutline,
  eyeOutline,
  informationCircleOutline,
  menuOutline,
  pricetagsOutline,
  trashOutline,
  warningOutline,
} from 'ionicons/icons';
import * as React from 'react';
import './index.css';
import { Route } from 'react-router';

interface KnowledgeBaseDashboardCardProps {
  icon: string;
  title: string;
  description: string;
  url: string;
}

export const KnowledgeBaseDashboardCard = ({ icon, title, description, url }: KnowledgeBaseDashboardCardProps) => {
  return (
    <>
      <IonCard className='ion-no-margin KnowledgeBaseDashboardCard' routerLink={url} routerDirection='forward'>
        <IonCardHeader mode='md' className='knowledgeBaseCard_Header'>
          <IonGrid fixed className=''>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ paddingLeft: '10px' }}>
              <IonCol
                size='2'
                className='ion-justify-content-center ion-no-padding ion-text-center ion-align-items-center'
              >
                <IonImg src={icon} className='ion-align-self-center' />
              </IonCol>
              <IonCol size='10' className='ion-align-items-center'>
                <IonCardTitle className='knowledgeBaseCard_Title'>{title}</IonCardTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
        <IonCardContent style={{ paddingTop: '20px' }}>
          <IonText>{description}</IonText>
        </IonCardContent>
      </IonCard>
    </>
  );
};
