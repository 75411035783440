export const getPelvisScore = (pelvis: number) => {
  if (pelvis <= 8 && pelvis >= 5) return 2;
  else if (pelvis > 8 && pelvis < 10) return 1;
  else if (pelvis >= 3 && pelvis < 5) return 1;
  else return 0;
};

export const getHipScore = (hip: number) => {
  if (hip >= 31) return 2;
  else if (hip < 31 && hip > 27) return 1;
  else return 0;
};

export const getKneeScore = (knee: number) => {
  if (knee >= 55) return 2;
  else if (knee >= 45 && knee < 55) return 1;
  else return 0;
};

export const getAnkleScore = (ankle: number) => {
  if (ankle >= 25) return 2;
  else if (ankle >= 22 && ankle < 25) return 1;
  else return 0;
};

export const getHighSymmetryScore = (symmetry: number) => {
  if (symmetry <= 25) return 2;
  else if (symmetry > 25 && symmetry <= 40) return 1;
  else return 0;
};

export const getSymmetryScore = (symmetry: number) => {
  if (symmetry <= 10) return 2;
  else if (symmetry > 10 && symmetry <= 20) return 1;
  else return 0;
};

export const getHipExtensionScore = (hipExtension: number) => {
  if (hipExtension <= -8) return 2;
  else if (hipExtension > -8 && hipExtension < -5) return 1;
  else return 0;
};

export const getStanceFlexionScore = (stanceFlexion: number) => {
  if (stanceFlexion >= 12 && stanceFlexion <= 25) return 2;
  else if (stanceFlexion >= 8 && stanceFlexion < 12) return 1;
  else if (stanceFlexion > 25 && stanceFlexion <= 30) return 1;
  else return 0;
};

export const getVarusValgusScore = (varusValgus: number) => {
  if (varusValgus >= -3 && varusValgus < 3) return 2;
  else if (varusValgus >= -6 && varusValgus < -3) return 1;
  else if (varusValgus > 3 && varusValgus <= 6) return 1;
  else return 0;
};
