/**
 * Calculates the range of the y-axis values based on the provided data.
 * @param data - The data used to calculate the y-axis range.
 * @param isValueAbove - A boolean indicating whether the y-axis range should be calculated with a value above the maximum value.
 * @returns An object containing the minimum and maximum values for the y-axis range.
 */
export function calculateYaxisRange(data: any, isValueAbove: boolean): { yMinValue: number; yMaxValue: number } {
  function minMax() {
    const minMax = [];
    const numCycles = Object.keys(data?.reportId.thighAccelerationStance_Left).length;
    for (let i = 0; i < numCycles; i++) {
      const min = Math.min(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      const max = Math.max(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      minMax.push({ min, max });
    }
    return minMax;
  }

  const minMaxArray = minMax();

  const minValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.min < curr.min ? prev : curr;
  });

  const maxValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.max > curr.max ? prev : curr;
  });

  const valueToAdd = isValueAbove ? 50 : 1000;
  const yMinValue = minValueObject.min - valueToAdd;
  const yMaxValue = maxValueObject.max + valueToAdd;
  return { yMinValue, yMaxValue };
}
