import React from 'react';

import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  heading: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#43425D',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderBottom: 1,
    borderBottomColor: '#E2E2E2',
    alignContent: 'center',
    marginHorizontal: 2,
  },
  value: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#1649d8',
    border: 1,
    borderColor: 'blue',
  },
  FieldStyle: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignSelf: 'center',
    marginHorizontal: 2,
  },
  value_danger: {
    backgroundColor: 'red',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    marginTop: '2px',
    marginHorizontal: '1px',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  value_warning: {
    backgroundColor: 'orange',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    marginTop: '2px',
    marginHorizontal: '1px',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  value_green: {
    backgroundColor: 'green',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    marginTop: '2px',
    marginHorizontal: '1px',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
    alignSelf: 'center',
  },
  kpiLabel: {
    textAlign: 'center',
    fontSize: 8,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    padding: 10,
    alignSelf: 'center',
  },
  varusValgusLabel: {
    textAlign: 'center',
    fontSize: 6,
    fontFamily: 'Montserrat',
    color: '#767676',
    padding: 5,
    alignSelf: 'center',
  },
  kpiLabelwarning: {
    textAlign: 'center',
    fontSize: 8,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    padding: 10,
    alignSelf: 'center',
    borderBottom: 1,
    borderBottomColor: 'red',
  },
});

type FieldProps = {
  value: string | string[];
  children: React.ReactNode;
};

/** Field component with a heading, line, and one or two fields
 * @param value - The field value, can be a tuple or a single field value
 * @param children  - The heading, e.g., <Text>My field</Text>.
 */
export const Field = ({ value, children }: FieldProps) => {
  return (
    <>
      <View style={styles.FieldStyle}>
        <Text style={styles.heading}>{children}</Text>
        <Text style={styles.value}>{value}</Text>
      </View>
    </>
  );
};

type NameFieldProps = {
  name: string;
  value: string | string[];
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const NameField = ({ name, value }: NameFieldProps) => {
  return (
    <Field value={value}>
      <Text style={styles.heading}>{name}</Text>
    </Field>
  );
};

/** Component to draw the status dot with color set by the status field */
// type StatusProps = { status: number };
// export const StatusDot = ({ status }: StatusProps) => {
//   const colors = ['red', 'orange', 'green'];
//   const styles = {
//     backgroundColor: colors[status],
//     display: 'block',
//     marginLeft: '5px',
//     right: 0,
//     height: '20px',
//     width: '20px',
//     borderRadius: '50%',
//   };
//   return <div style={styles} />;
// };

type DotFieldProps = NameFieldProps & {
  status: number;
};

/** Field component with a title, status dot, and field value */
export const StatusDotField = ({ name, value, status }: DotFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      {status === 0 && <Text style={styles.value_danger}>{value}</Text>}
      {status === 1 && <Text style={styles.value_warning}>{value}</Text>}
      {status === 2 && <Text style={styles.value_green}>{value}</Text>}
    </View>
  );
};

type KPIFieldProps = {
  name: string;
  value: number;
};

export const KPIField_Zero = ({ name, value }: KPIFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      {value === 1 && <Text style={styles.kpiLabel}>OK</Text>}
      {value === 2 && <Text style={styles.kpiLabel}>Moderate</Text>}
      {value === 3 && <Text style={styles.kpiLabelwarning}>Poor</Text>}
    </View>
  );
};

export const KPIField_One = ({ name, value }: KPIFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      {value === 1 && <Text style={styles.kpiLabel}>Positive</Text>}
      {value === 2 && <Text style={styles.kpiLabel}>OK</Text>}
      {value === 3 && <Text style={styles.kpiLabelwarning}>Drop</Text>}
    </View>
  );
};

type ROMFieldProps = {
  name: string;
  value: string | string[];
};

export const ROMField = ({ name, value }: ROMFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      <Text style={styles.kpiLabel}>{value}</Text>
    </View>
  );
};

type AttractorFieldProps = {
  name: string;
  value: string;
};

export const AttractorField = ({ name, value }: AttractorFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      {(() => {
        switch (value) {
          case 'Fair':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Absent':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Hyperextension':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Hyperext.':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Drop':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Round':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Slow':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Obtuse':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Negative':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Poor':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Passive':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Late':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'No':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Collapse':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          default:
            return <Text style={styles.kpiLabel}>{value}</Text>;
        }
      })()}
    </View>
  );
};

export const AttractorField_ThreeOptions = ({ name, value }: AttractorFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      {(() => {
        switch (value) {
          case 'Absent':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Hyperextension':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Hyperext.':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Drop':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Round':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Slow':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Obtuse':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Negative':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Poor':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Passive':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Late':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'No':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          case 'Collapse':
            return <Text style={styles.kpiLabelwarning}>{value}</Text>;
          default:
            return <Text style={styles.kpiLabel}>{value}</Text>;
        }
      })()}
    </View>
  );
};

interface VarusValgusFieldProps {
  name: string;
  value: string;
  label: string;
}

export const VarusValgusField = ({ name, value, label }: VarusValgusFieldProps) => {
  return (
    <View style={styles.FieldStyle}>
      <Text style={styles.heading}>{name}</Text>
      <Text style={styles.kpiLabel}>{value}</Text>
      <Text style={styles.varusValgusLabel}>{label}</Text>
    </View>
  );
};
