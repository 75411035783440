import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonSpinner } from '@ionic/react';
import { useEffect } from 'react';
import { GraphSeriesData, KinematicYAxis } from '../../../../model/reportV2.model';

type FunctionalMovementGraphProps = {
  data: GraphSeriesData[];
  title: string;
  subtitle: string;
  strokeWidth: number;
  forPdf?: boolean;
  yAxisPdf?: any;
  yAxis: KinematicYAxis;
};

function calculateYAxis(data: any) {
  let yMin = 0;
  let yMax = 0;
  data.forEach((series: any) => {
    series.data.forEach((point: any) => {
      if (point.y < yMin) {
        // setYMin(Math.round(point.y));
        yMin = Math.round(point.y);
      }
      if (point.y > yMax) {
        // setYMax(Math.round(point.y));
        yMax = Math.round(point.y);
      }
    });
  });
  yMin = yMin - 10;
  yMax = yMax + 10;

  const yRange = yMax - yMin;
  const number = Math.round(yRange / 10 + 2);
  const tickAmount = number > 5 ? 5 : number;

  return { yMin, yMax, yRange, tickAmount };
}

export const FunctionalMovementKinematicsGraph = React.forwardRef(
  (
    { data, title, subtitle, strokeWidth, forPdf = false, yAxisPdf, yAxis }: FunctionalMovementGraphProps,
    ref: React.ForwardedRef<Chart>,
  ) => {
    const [loading, setLoading] = React.useState(!forPdf ? true : false);
    const options: ApexOptions = {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        offsetX: forPdf ? -5 : -10,
      },
      xaxis: {
        type: 'numeric',
        labels: {
          style: {
            fontSize: '8px',
          },
        },
      },
      yaxis: {
        show: true,
        min: yAxis.yMin,
        max: yAxis.yMax,
        tickAmount: yAxis.tickAmount,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(0);
          },
          style: {
            fontSize: '8px',
          },
        },
        axisTicks: {
          show: true,
        },
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      title: {
        text: !forPdf ? title : '',
        align: 'center',
        margin: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat',
          color: '#2066FA',
        },
      },
      subtitle: {
        text: subtitle,
        align: 'center',
        margin: forPdf ? 5 : -10,
        floating: forPdf ? true : false,
        offsetY: forPdf ? 5 : 25,
        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat',
        },
      },

      stroke: {
        width: strokeWidth,
        dashArray: [0],
      },
      legend: {
        show: forPdf ? false : true,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '9px',
        markers: {
          width: 8,
          height: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: 'red',
          },
        ],
      },
      markers: {
        size: [0, 0, 2],
        strokeWidth: 0,
        showNullDataPoints: false,
      },
      tooltip: {
        enabled: false,
      },
    };

    useEffect(() => {
      if (!forPdf) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }, [data]);
    return (
      <div className='kinematicsGraph_Wrapper'>
        {!loading ? (
          <Chart
            ref={ref}
            options={options}
            series={data}
            type='line'
            height={forPdf ? 'auto' : '100%'}
            width={forPdf ? 250 : '100%'}
          />
        ) : (
          <IonSpinner name='bubbles' color='primary' />
        )}
      </div>
    );
  },
);
