import sensorFootRight from '../../../../assets/sensors/SensorFootRight.svg';
import sensorFootLeft from '../../../../assets/sensors/SensorFootLeft.svg';
import sensorCalfRight from '../../../../assets/sensors/SensorCalfRight.svg';
import sensorCalfLeft from '../../../../assets/sensors/SensorCalfLeft.svg';
import sensorThighRight from '../../../../assets/sensors/SensorThighRight.svg';
import sensorThighLeft from '../../../../assets/sensors/SensorThighLeft.svg';
import sensorPelvisRight from '../../../../assets/sensors/SensorPelvisRight.svg';
import sensorPelvisLeft from '../../../../assets/sensors/SensorPelvisLeft.svg';

interface SensorImageMap {
  [key: string]: string;
  ['left pelvis']: string;
  ['right pelvis']: string;
  ['left thigh']: string;
  ['right thigh']: string;
  ['left calf']: string;
  ['right calf']: string;
  ['left foot']: string;
  ['right foot']: string;
}

export const MappedSegmentImages: SensorImageMap = {
  'left pelvis': sensorPelvisLeft,
  'right pelvis': sensorPelvisRight,
  'left thigh': sensorThighLeft,
  'right thigh': sensorThighRight,
  'left calf': sensorCalfLeft,
  'right calf': sensorCalfRight,
  'left foot': sensorFootLeft,
  'right foot': sensorFootRight,
};
