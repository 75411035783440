/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonLoading,
  IonToggle,
  IonCardContent,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { KinematicsGraph } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';
import { RunKinematicData } from '../../../model/reportV2.model';

type KinematicsReportProps = RouteComponentProps & {
  tabData: RunKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXCardHeader title={'Pelvis'} zoom={false} info={true}>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonText className='attractorCard_Title'>
                        <small>Show all Strides</small>
                      </IonText>
                    </IonRow>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonToggle
                        mode='ios'
                        className='icon-custom'
                        color='primary'
                        checked={showAllStrides}
                        onIonChange={(e) => {
                          toggleShowAllStrides();
                        }}
                      ></IonToggle>
                    </IonRow>
                  </ORYXCardHeader>

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis.Transversal.series}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Hip'} />
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Knee'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Ankle'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Sagittal.series}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Coronal.series}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle.Transversal.series}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
