import { DataPoint } from '../../../model/reportV2.model';

/**
 * Retrieves the footplant coordinates from the given data object.
 *
 * @param data - The data object containing footplant coordinates.
 * @param columnIndex - The index of the column to retrieve coordinates from.
 * @returns An array of DataPoint objects representing the footplant coordinates.
 */
export function getfootplantCoordinates(data: { [key: string]: number[] }, columnIndex: number): DataPoint[] {
  const xyValues = [];
  const numCycles = Object.keys(data).length;
  for (let i = 0; i < numCycles; i++) {
    xyValues.push({ x: data[`col${i}`][columnIndex], y: 0 });
  }
  return xyValues;
}
