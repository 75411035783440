import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../../../../components/menuItems/Header';
import { KnowledgeBaseSidebar } from '../../components';
import { useLocation } from 'react-router';
import { useEffect, useRef } from 'react';

export const WalkMeasurementDoc: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement>(null);

  const scrollToSection = (sectionId: string) => {
    const offset = -69;
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    };
    const scrollToElement = document.getElementById(sectionId);

    if (scrollToElement && contentRef.current) {
      const scrollX = 0;
      const scrollY = scrollToElement.offsetTop - offset;
      contentRef.current.scrollToPoint(scrollX, scrollY);
    }
  };
  return (
    <IonPage id='main'>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light' ref={contentRef}>
        <IonGrid className='Test'>
          <IonRow className='ion-justify-content-end'>
            {/* Do not use this col */}
            <IonCol size='1'>Placeholder...</IonCol>
            {/* Content */}
            <IonCol size='8' className='Test'>
              <IonTitle>Welcome back!</IonTitle>
              <div id='section1'>
                Section 1 content
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                A lot of Content!
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div id='section2'>Section 2 content</div>
            </IonCol>
            {/* SideBar */}
            <IonCol size='3' className='Test'>
              <KnowledgeBaseSidebar onSidebarLinkClick={scrollToSection} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
