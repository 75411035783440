import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    width: 'auto',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  borderBottom: {
    flexDirection: 'row',
    borderBottom: 1,
    borderBottomColor: '#E2E2E2',
    borderBottomStyle: 'solid',
  },
  tableCol: {
    flex: 1,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 10,
    paddingVertical: 2,
  },
  tableCellHeader_Indent: {
    fontSize: 10,
    fontStyle: 'italic',
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 20,
    paddingVertical: 2,
  },
  RowHeader: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    marginLeft: 10,
    textAlign: 'center',
  },
  tableCell: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 10,
    paddingVertical: 2,
  },
  largeCell: {
    flexBasis: '35%',
  },
  dataTableCell: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    color: '#767676',
    marginLeft: 10,
    textAlign: 'center',
    paddingVertical: 2,
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: '#E2E2E2',
    borderRightStyle: 'solid',
  },
  backgroundRed: {
    backgroundColor: '#eb3737',
    color: '#ffffff',
  },
  backgroundOrange: {
    backgroundColor: '#fb923c',
    color: '#767676',
  },
  backgroundGreen: {
    backgroundColor: '#22c55e',
    color: '#767676',
  },
});

const Table = ({ children }: { children: React.ReactNode }) => <View style={styles.table}>{children}</View>;

const TableRow = ({
  children,
  backgroundColor,
  borderBottom,
}: {
  children: React.ReactNode;
  backgroundColor: string;
  borderBottom: boolean;
}) => <View style={[{ backgroundColor }, borderBottom ? styles.borderBottom : styles.tableRow]}>{children}</View>;

const TableCell = ({ children, customStyle }: { children: React.ReactNode; customStyle: any }) => (
  <View style={[styles.tableCol, customStyle]}>{children}</View>
);

interface PDFRunInfoTableProps {
  spatioTemporalData: any;
  romData: any;
}

export const PDFWalkInfoTable = ({ spatioTemporalData, romData }: PDFRunInfoTableProps) => {
  const classNames = [styles.backgroundRed, styles.backgroundOrange, styles.backgroundGreen];
  const color = (status: number) => {
    return classNames[status];
  };

  const getPelvisScore = (pelvis: number) => {
    if (pelvis <= 8 && pelvis >= 5) return 2;
    else if (pelvis > 8 && pelvis < 10) return 1;
    else if (pelvis >= 3 && pelvis < 5) return 1;
    else return 0;
  };

  const getHipScore = (hip: number) => {
    if (hip >= 31) return 2;
    else if (hip < 31 && hip > 27) return 1;
    else return 0;
  };

  const getKneeScore = (knee: number) => {
    if (knee >= 55) return 2;
    else if (knee >= 45 && knee < 55) return 1;
    else return 0;
  };

  const getAnkleScore = (ankle: number) => {
    if (ankle >= 25) return 2;
    else if (ankle >= 22 && ankle < 25) return 1;
    else return 0;
  };

  const getHighSymmetryScore = (symmetry: number) => {
    if (symmetry <= 25) return 2;
    else if (symmetry > 25 && symmetry <= 40) return 1;
    else return 0;
  };

  const getSymmetryScore = (symmetry: number) => {
    if (symmetry <= 10) return 2;
    else if (symmetry > 10 && symmetry <= 20) return 1;
    else return 0;
  };

  const getHipExtensionScore = (hipExtension: number) => {
    if (hipExtension <= -8) return 2;
    else if (hipExtension > -8 && hipExtension < -5) return 1;
    else return 0;
  };

  const getStanceFlexionScore = (stanceFlexion: number) => {
    if (stanceFlexion >= 12 && stanceFlexion <= 25) return 2;
    else if (stanceFlexion >= 8 && stanceFlexion < 12) return 1;
    else if (stanceFlexion > 25 && stanceFlexion <= 30) return 1;
    else return 0;
  };

  const getVarusValgusScore = (varusValgus: number) => {
    if (varusValgus >= -3 && varusValgus < 3) return 2;
    else if (varusValgus >= -6 && varusValgus < -3) return 1;
    else if (varusValgus > 3 && varusValgus <= 6) return 1;
    else return 0;
  };
  return (
    <View>
      <Table>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={[styles.borderRight, styles.largeCell]}>
            <Text style={styles.RowHeader}> </Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.RowHeader}>Left</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.RowHeader}>Right</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={[styles.borderRight, styles.largeCell]}>
            <Text style={styles.tableCellHeader}>Stance phase (Percentage of stride time)</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[3][1].toFixed(0) + ' %'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[4][1].toFixed(0) + ' %'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={[styles.borderRight, styles.largeCell]}>
            <Text style={styles.tableCellHeader}>Stance phase (seconds)</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[0][1].toFixed(2) + ' s'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[1][1].toFixed(2) + ' s'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={[styles.borderRight, styles.largeCell]}>
            <Text style={styles.tableCellHeader}>Swing phase (Percentage of stride time)</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[3][2].toFixed(0) + ' %'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[4][2].toFixed(0) + ' %'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={false}>
          <TableCell customStyle={[styles.borderRight, styles.largeCell]}>
            <Text style={styles.tableCellHeader}>Swing phase (seconds)</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[0][2].toFixed(2) + ' s'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}>{spatioTemporalData[1][2].toFixed(2) + ' s'}</Text>
          </TableCell>
        </TableRow>
      </Table>

      <Table>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.RowHeader}> </Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.RowHeader}>Left</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.RowHeader}>Right</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.RowHeader}>Symmetry</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader}>Pelvis</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getPelvisScore(romData[0][0]))]}>
            <Text style={styles.dataTableCell}>{romData[0][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getPelvisScore(romData[0][0]))]}>
            <Text style={styles.dataTableCell}>{romData[0][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={color(getHighSymmetryScore(romData[0][2]))}>
            <Text style={styles.dataTableCell}>{romData[0][2].toFixed(0) + '%'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader}>Hip</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getHipScore(romData[1][0]))]}>
            <Text style={styles.dataTableCell}>{romData[1][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getHipScore(romData[1][1]))]}>
            <Text style={styles.dataTableCell}>{romData[1][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[color(getSymmetryScore(romData[1][2]))]}>
            <Text style={styles.dataTableCell}>{romData[1][2].toFixed(0) + '%'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Flexion</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[2][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[2][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Extension</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getHipExtensionScore(romData[3][0]))]}>
            <Text style={styles.dataTableCell}>{romData[3][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getHipExtensionScore(romData[3][1]))]}>
            <Text style={styles.dataTableCell}>{romData[3][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader}>Knee</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getKneeScore(romData[4][0]))]}>
            <Text style={styles.dataTableCell}>{romData[4][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getKneeScore(romData[4][1]))]}>
            <Text style={styles.dataTableCell}>{romData[4][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={color(getSymmetryScore(romData[4][2]))}>
            <Text style={styles.dataTableCell}>{romData[4][2].toFixed(0) + '%'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Stance flexion</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getStanceFlexionScore(romData[5][0]))]}>
            <Text style={styles.dataTableCell}>{romData[5][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getStanceFlexionScore(romData[5][1]))]}>
            <Text style={styles.dataTableCell}>{romData[5][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Varus / Valgus</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getVarusValgusScore(romData[6][0]))]}>
            <Text style={styles.dataTableCell}>{romData[6][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getVarusValgusScore(romData[6][1]))]}>
            <Text style={styles.dataTableCell}>{romData[6][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader}>Ankle</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getAnkleScore(romData[7][0]))]}>
            <Text style={styles.dataTableCell}>{romData[7][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={[styles.borderRight, color(getAnkleScore(romData[7][1]))]}>
            <Text style={styles.dataTableCell}>{romData[7][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={color(getSymmetryScore(romData[7][2]))}>
            <Text style={styles.dataTableCell}>{romData[7][2].toFixed(0) + '%'}</Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={true}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Dorsal flexion</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[8][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[8][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
        <TableRow backgroundColor={''} borderBottom={false}>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.tableCellHeader_Indent}>Plantar flexion</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[9][0].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={styles.borderRight}>
            <Text style={styles.dataTableCell}>{romData[9][1].toFixed(1) + '°'}</Text>
          </TableCell>
          <TableCell customStyle={undefined}>
            <Text style={styles.dataTableCell}></Text>
          </TableCell>
        </TableRow>
      </Table>
    </View>
  );
};
