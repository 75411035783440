import React, { useEffect, useReducer, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonLoading, IonSpinner } from '@ionic/react';
import './modalindex.css';

import { ORYXModalHeader } from '../../../../components/componentHeaders';
import { ORYXVerticalSwiper } from '../../../../components/swiper';

// Slides
import { SwiperSlide } from 'swiper/react';
import { JointCouplingModalGraph, RearPendulumModalGraph } from '../Graphs';

interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
  data: any;
  xMin: number;
  xMax: number;
  xRange: number;
}

export const ZoomModalJointCoupling = ({ buttons, setShowModal, data, xMin, xMax, xRange }: ModalProps) => {
  const [loading, setIsLoading] = useState(true);
  if (import.meta.env.MODE) console.log(data);

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    return () => {
      setIsLoading(true);
    };
  }, []);

  return (
    <>
      <ORYXModalHeader title='Hip / Knee Coupling' setShowInfo={cleanUp} buttons={true} />
      {loading ? (
        <IonSpinner name='bubbles' className='Modal_Spinner' color='primary' />
      ) : (
        <ORYXVerticalSwiper>
          <SwiperSlide className='' style={{ height: '100%' }}>
            <IonGrid fixed className='' style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
                <IonCol size='6' className='' style={{ height: '50%' }}>
                  <JointCouplingModalGraph
                    data={data.seriesJointCoupling_Left}
                    side={'Left'}
                    xMin={xMin}
                    xMax={xMax}
                    xRange={xRange}
                  />
                </IonCol>
                <IonCol size='6' style={{ height: '50%' }} className='ion-align-items-center'>
                  <JointCouplingModalGraph
                    data={data.seriesJointCoupling_Right}
                    side={'Right'}
                    xMin={xMin}
                    xMax={xMax}
                    xRange={xRange}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>
        </ORYXVerticalSwiper>
      )}
    </>
  );
};
