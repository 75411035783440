import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonCardSubtitle,
  IonIcon,
  IonButton,
  IonButtons,
} from '@ionic/react';
import './modalindex.css';
import IBkneeflexionmidstance1 from './assets/IBkneeflexionmidstance1.png';
import IBkneeflexionmidstance2 from './assets/IBkneeflexionmidstance2.png';
import { chevronDownOutline, chevronUpOutline, closeCircleOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';
import { ORYXModalHeader } from '../../../../components/componentHeaders';
interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}
export const ModalKneeFlexionMidstance = ({ buttons, setShowModal }: ModalProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Knee Angle' subtitle='Midstance' setShowInfo={cleanUp} buttons={true} />

      <Swiper
        modules={[Pagination, Mousewheel, Keyboard]}
        pagination={pagination}
        mousewheel={true}
        keyboard={true}
        initialSlide={0}
        speed={400}
        direction='vertical'
        className='mySwiper'
      >
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5' className='ion-no-padding'>
                <div>
                  <IonImg src={IBkneeflexionmidstance1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  During single limb support, one leg alone carries the full body weight and keeping it balanced. The
                  knee joint is put under serious pressure throughout this phase, reaching the climax of maximum
                  pressure at midstance.
                  <br></br>
                  <br></br>
                  Co-contractions of the quadriceps and hamstrings stabilize the varying ground reacting forces, passing
                  in front of, behind or through the knee.
                  <br></br>
                  <br></br>
                  While the hip abductors continue their activity to halt the contralateral pelvic drop (also see:
                  pelvic obliquity).
                  <br></br>
                  <br></br>
                  The quadriceps act concentrically to initiate knee extension, and the hip abductors continue their
                  activity, becoming isometric as they halt contralateral pelvic drop.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11'>
                <div>
                  <IonImg src={IBkneeflexionmidstance2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='11'>
                <div>
                  If everything works well, the knee should flex within bandwidth of approximately 12 – 25 degrees. Less
                  flexion indicates stiffness. This can be due to overload from prior training – whether due to poor
                  quadricep strength – or fear (typically in rehab from injury or surgery)
                  <br></br>
                  <br></br>
                  Too much flexion typically indicates poor hamstring strength. As a result the impact at heel strike
                  will be clearly audible.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
