import { DataPoint, RearPendulumPointsOfInterests_Left } from '../../../model/reportV2.model';

/**
 * Combines the points of interest from the given data into an array of DataPoints.
 *
 * @param points - The points of interest data.
 * @param xIndex - The index of the x-coordinate in each point of interest.
 * @param yIndex - The index of the y-coordinate in each point of interest.
 * @returns An array of DataPoints containing the combined x and y coordinates.
 */
export function combinePointsOfInterest(
  points: RearPendulumPointsOfInterests_Left,
  xIndex: number,
  yIndex: number,
): DataPoint[] {
  const numCycles = Object.keys(points).length;
  const scatterData: { x: number; y: number }[] = [];

  for (let i = 0; i < numCycles; i++) {
    scatterData.push({
      x: points[`col${i}`][xIndex],
      y: points[`col${i}`][yIndex],
    });
  }

  return scatterData;
}
