/** Component to create the Status and Symmetry fields used in reports */
import { IonItem, IonLabel, IonList } from '@ionic/react';
import * as React from 'react';
import { Attractors } from '../../../../model';
import './index.css';
import { dangerColor, succesColor, warningColor } from '../../../../theme/colors';

/** Convert attractors report data for use in the UI
 * Transpose the attractors report data from colId > row of attractors
 * to rows of attractor labels.
 */
//!Moved to reportV2.model & datafunctions
export type AttractorLabels = string[][];
export function toAttractorLabels(data: Attractors): AttractorLabels {
  const labels = [
    ['Positive', 'Negative'], // Positive Running - Toe off (0)
    ['Sufficient', 'Absent'], // Ankle Stiffness (1)
    ['Reactive', 'No extension', 'Hyperext.'], //Knee Extension - preswing (2)
    ['Lock', 'Ok', 'Drop'], // Hip Lock (3)
    ['Straight', 'Round'], // Rear Pendulum (4)
    ['Fierce', 'Slow'], // Swingleg Recovery (5)
    ['Ok', 'Obtuse'], // Knee angle - Midswing (6)
    ['Positive', 'Negative'], // Positive Running - Midstance (7)
    ['Ok', 'Poor'], // Projection Footplant (8)
    ['Active', 'Passive'], // Swingleg Retraction (9)
    ['Ok', 'Fair', 'Late'], // Scissors (10)
    ['Ok', 'Poor'], // Footplant from above (11)
    ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
    ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
    ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
    ['Ok', 'Fair', 'Poor'], // Hip Lock after version 2.1.9 (15)
    ['Ok', 'Fair', 'Poor'], // Ankle Stiffness after version 2.1.9 (16)
  ];
  return data.col0.map((_value: number, rowIdx: number) => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        const labelIdx = data[colId][rowIdx] - 1;
        return labels[rowIdx][labelIdx];
      });
  });
}
// After version 2.1.10
//!Moved to reportV2.model & datafunctions
export function toAttractorLabels_new(data: Attractors): AttractorLabels {
  const labels = [
    ['Positive', 'Negative'], // Positive Running - Toe off (0)
    ['Ok', 'Fair', 'Poor'], // Ankle Stiffness (1)
    ['Reactive', 'No extension', 'Hyperext.'], //Knee Extension - preswing (2)
    ['Ok', 'Fair', 'Poor'], // Hip Lock (3)
    ['Straight', 'Round'], // Rear Pendulum (4)
    ['Fierce', 'Slow'], // Swingleg Recovery (5)
    ['Ok', 'Obtuse'], // Knee angle - Midswing (6)
    ['Positive', 'Negative'], // Positive Running - Midstance (7)
    ['Ok', 'Poor'], // Projection Footplant (8)
    ['Active', 'Passive'], // Swingleg Retraction (9)
    ['Ok', 'Fair', 'Late'], // Scissors (10)
    ['Ok', 'Poor'], // Footplant from above (11)
    ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
    ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
    ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
  ];
  return data.col0.map((_value: number, rowIdx: number) => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        const labelIdx = data[colId][rowIdx] - 1;
        return labels[rowIdx][labelIdx];
      });
  });
}

type FieldProps = {
  value: string | string[];
  children: React.ReactNode;
};

/** Field component with a heading, line, and one or two fields
 * @param value - The field value, can be a tuple or a single field value
 * @param children  - The heading, e.g., <IonLabel>My field</IonLabel>.
 */
export const Field_v2 = ({ value, children }: FieldProps) => {
  return (
    <IonList>
      <IonItem className='ion-no-padding TextAlign_Center'>{children}</IonItem>
      <IonItem lines='none' className='ion-no-padding TextAlign_Center'>
        {Array.isArray(value) ? (
          <IonLabel color='primary' className='ion-text-wrap TextAlign_Center'>
            {value[0]}
            <span className='separator'>|</span>
            {value[1]}
          </IonLabel>
        ) : (
          <>
            <IonLabel color='secondary' className='TextAlign_Center Label ion-text-wrap' style={{ width: '100%' }}>
              {value}
            </IonLabel>
          </>
        )}
      </IonItem>
    </IonList>
  );
};

type VarusValgusLabelProps = {
  value: string | string[];
  children: React.ReactNode;
  label: string;
};

export const VarusValgusLabel = ({ value, children, label }: VarusValgusLabelProps) => {
  return (
    <IonList>
      <IonItem className='ion-no-padding TextAlign_Center'>{children}</IonItem>
      <IonItem lines='none' className='ion-no-padding TextAlign_Center'>
        <>
          <IonLabel color='secondary' className='TextAlign_Center Label ion-text-wrap' style={{ width: '100%' }}>
            {value} <br></br>
            <p>
              <small>{label}</small>
            </p>
          </IonLabel>
        </>
      </IonItem>
    </IonList>
  );
};

type NameFieldProps = {
  name: string;
  value: string | string[];
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const NameField_v2 = ({ name, value }: NameFieldProps) => {
  return (
    <Field_v2 value={value}>
      <IonLabel className='ion-text-wrap TextAlign_Center full_width'>{name}</IonLabel>
    </Field_v2>
  );
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const NameField = ({ name, value }: NameFieldProps) => {
  return (
    <Field_v2 value={value}>
      <IonLabel className='ion-text-wrap TextAlign_Center full_width'>{name}</IonLabel>
    </Field_v2>
  );
};

type VarusValgusFieldProps = {
  name: string;
  value: string | string[];
  label: string;
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const VarusValgusField = ({ name, value, label }: VarusValgusFieldProps) => {
  return (
    <VarusValgusLabel value={value} label={label}>
      <IonLabel className='ion-text-wrap TextAlign_Center full_width'>{name}</IonLabel>
    </VarusValgusLabel>
  );
};

/** Component to draw the status dot with color set by the status field */
type StatusProps = { status: number };
export const StatusDot = ({ status }: StatusProps) => {
  const colors = ['red', 'orange', 'green'];
  const styles = {
    backgroundColor: colors[status],
  };
  return <div className='status-dot' style={styles} />;
};

export const StatusDot_Centered = ({ status }: StatusProps) => {
  const colors = [succesColor, warningColor, dangerColor, succesColor];
  const styles = {
    backgroundColor: colors[status],
  };
  return <div className='status-dot-centered' style={styles} />;
};

type DotFieldProps = NameFieldProps & {
  status: number;
};

/** Field component with a title, status dot, and field value */
export const StatusDotField = ({ name, value, status }: DotFieldProps) => {
  return (
    <Field_v2 value={value}>
      <IonLabel>{name}</IonLabel>
      <StatusDot status={status} />
    </Field_v2>
  );
};
