import { ReportInfo } from './reportInfo.model';

export type ReportId = string;

// Types for the report as they come out of Matlab
export enum ReportType {
  WALK = 1,
  RUN = 2,
  SQUAT = 3,
  SLSQUAT = 4,
  ERROR = 999,
  KNEEL = 998,
  KNEER = 997,
}

export type Version = {
  [key: string]: number;
  col0: number; // Major
  col1: number; // Minor
  col2: number; // Patch
};

export type Stride = {
  [key: number]: number;
};

export type StridesData = {
  [key: string]: any;
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type StridesDataRun_Left = {
  [key: string]: StridesData;
  leftAnkle: StridesData;
  leftKnee: StridesData;
  leftHip: StridesData;
  leftPelvis: StridesData;
  leftThigh: StridesData;
  leftCalf: StridesData;
  leftFoot: StridesData;
  rightThigh_LeftStrides: StridesData;
  respectTheSagittalPlaneAnkle_Left: StridesData;
};

export type StridesDataRun_Right = {
  [key: string]: StridesData;
  rightAnkle: StridesData;
  rightKnee: StridesData;
  rightHip: StridesData;
  rightPelvis: StridesData;
  rightThigh: StridesData;
  rightCalf: StridesData;
  rightFoot: StridesData;
  leftThigh_RightStrides: StridesData;
  respectTheSagittalPlaneAnkle_Right: StridesData;
};

export type StridesData_Left = {
  [key: string]: StridesData;
  leftAnkle: StridesData;
  leftKnee: StridesData;
  leftHip: StridesData;
  leftPelvis: StridesData;
  leftThigh: StridesData;
  leftCalf: StridesData;
  leftFoot: StridesData;
  rightThigh_LeftStrides: StridesData;
  respectTheSagittalPlaneAnkle_Left: StridesData;
};

export type StridesData_Right = {
  [key: string]: StridesData;
  rightAnkle: StridesData;
  rightKnee: StridesData;
  rightHip: StridesData;
  rightPelvis: StridesData;
  rightThigh: StridesData;
  rightCalf: StridesData;
  rightFoot: StridesData;
};

export type Rom = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
  col2: number[]; // Symmetry
};
export type SpatioTemporal = {
  [key: string]: number[];
  col0: number[]; // Left (s)
  col1: number[]; // Right (s)
  col2: number[]; // Symmetry (%)
  col3: number[]; // Left (%)
  col4: number[]; // Right (%)
};

export type AreasOfConcern = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

//#region kinematic data
export type PointsOfInterests = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type KinematicData = {
  [key: string]: number[];
  col0: number[]; // Left Pelvis (Sagittal)
  col1: number[]; // Right Pelvis (Sagittal)
  col2: number[]; // Left Hip (Sagittal)
  col3: number[]; // Right Hip (Sagittal)
  col4: number[]; // Left Knee (Sagittal)
  col5: number[]; // Right Knee (Sagittal)
  col6: number[]; // Left Ankle (Sagittal)
  col7: number[]; // Right Ankle (Sagittal)
  col8: number[]; // Left Pelvis (Coronal)
  col9: number[]; // Right Pelvis (Coronal)
  col10: number[]; // Left Hip (Coronal)
  col11: number[]; // Right Hip (Coronal)
  col12: number[]; // Left Knee (Coronal)
  col13: number[]; // Right Knee (Coronal)
  col14: number[]; // Left Ankle (Coronal)
  col15: number[]; // Right Ankle (Coronal)
  col16: number[]; // Left Pelvis (Transveral)
  col17: number[]; // Right Pelvis (Transveral)
  col18: number[]; // Left Hip (Transveral)
  col19: number[]; // Right Hip (Transveral)
  col20: number[]; // Left Knee (Transveral)
  col21: number[]; // Right Knee (Transveral)
  col22: number[]; // Left Ankle (Transveral)
  col23: number[]; // Right Ankle (Transveral)
};

export type KinematicData_Sagittal = {
  [key: string]: number[];
  col0: number[]; // Left Pelvis
  col1: number[]; // Right Pelvis
  col2: number[]; // Left Hip
  col3: number[]; // Right Hip
  col4: number[]; // Left Knee
  col5: number[]; // Right Knee
  col6: number[]; // Left Ankle
  col7: number[]; // Right Ankle
};

export type KinematicData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Left Pelvis
  col1: number[]; // Right Pelvis
  col2: number[]; // Left Hip
  col3: number[]; // Right Hip
  col4: number[]; // Left Knee
  col5: number[]; // Right Knee
  col6: number[]; // Left Ankle
  col7: number[]; // Right Ankle
};

export type KinematicData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Left Pelvis
  col1: number[]; // Right Pelvis
  col2: number[]; // Left Hip
  col3: number[]; // Right Hip
  col4: number[]; // Left Knee
  col5: number[]; // Right Knee
  col6: number[]; // Left Ankle
  col7: number[]; // Right Ankle
};

// all cycle data
export type LeftPelvisData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightPelvisData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftHipData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightHipData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftThighData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightThighData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftKneeData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightKneeData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftCalfData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightCalfData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftAnkleData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightAnkleData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftFootData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightFootData = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftPelvisData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightPelvisData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftHipData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightHipData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftThighData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightThighData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftKneeData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightKneeData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftCalfData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightCalfData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftAnkleData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightAnkleData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftFootData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightFootData_Coronal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftPelvisData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightPelvisData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftHipData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightHipData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftThighData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightThighData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftKneeData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightKneeData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftCalfData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightCalfData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftAnkleData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightAnkleData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type LeftFootData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type RightFootData_Transversal = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

//#endregion

//#region Variability Data
export type CoordinativeVariabilityHip_Left = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityHip_Left
  col1: number[]; // coordinativeVariabilityHip_Left_Selected
  col2: number[]; // coordinativeVariabilityHip_Left_Mean
};

export type CoordinativeVariabilityHip_Right = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityHip_Right
  col1: number[]; // coordinativeVariabilityHip_Right_Selected
  col2: number[]; // coordinativeVariabilityHip_Right_Mean
};

export type CoordinativeVariabilityKnee_Left = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityKnee_Left
  col1: number[]; // coordinativeVariabilityKnee_Left_Selected
  col2: number[]; // coordinativeVariabilityKnee_Left_Mean
};

export type CoordinativeVariabilityKnee_Right = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityKnee_Right
  col1: number[]; // coordinativeVariabilityKnee_Right_Selected
  col2: number[]; // coordinativeVariabilityKnee_Right_Mean
};

export type CoordinativeVariabilityAnkle_Left = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityAnkle_Left
  col1: number[]; // coordinativeVariabilityAnkle_Left_Selected
  col2: number[]; // coordinativeVariabilityAnkle_Left_Mean
};

export type CoordinativeVariabilityAnkle_Right = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityAnkle_Right
  col1: number[]; // coordinativeVariabilityAnkle_Right_Selected
  col2: number[]; // coordinativeVariabilityAnkle_Right_Mean
};

export type CrpPelvisCThigh_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisThighT_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisCThighT_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisThigh_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisCThigh_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisThighT_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisCThighT_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpPelvisThigh_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type Mean_crpPelvisCThigh_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisThighT_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisCThighT_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisThigh_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisCThigh_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisThighT_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisCThighT_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpPelvisThigh_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type CrpPelvisThighMM_Left = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CrpPelvisThighMM_Right = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CrpThighCalfT_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpThighCCalfT_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpThighCalf_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpThighCalfT_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpThighCCalfT_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpThighCalf_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type Mean_crpThighCalfT_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpThighCCalfT_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpThighCalf_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpThighCalfT_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpThighCCalfT_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpThighCalf_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type CrpThighCalfMM_Left = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CrpThighCalfMM_Right = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CrpCalfTFootC_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpCalfFoot_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpCalfTFootC_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type CrpCalfFoot_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type Mean_crpCalfTFootC_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpCalfFoot_Left = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpCalfTFootC_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type Mean_crpCalfFoot_Right = {
  [key: string]: number[];
  col0: number[]; // Data
};

export type CrpCalfFootMM_Left = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CrpCalfFootMM_Right = {
  [key: string]: number[];
  col0: number[]; // Max
  col1: number[]; // Max Selected
  col2: number[]; // Max Mean
  col3: number[]; // Min
  col4: number[]; // Min Selected
  col5: number[]; // Min Mean
};

export type CoordinativeVariabilityHip_Left_Stance = {
  [key: string]: number[];
  col0: number[]; // pelvisSCThighSTLandscape_Left
  col1: number[]; // pelvisSCThighSTLandscape_Left_selected
  col2: number[]; // pelvisSCThighSTLandscape_Left_mean
};

export type CoordinativeVariabilityHip_Right_Stance = {
  [key: string]: number[];
  col0: number[]; // pelvisSCThighSTLandscape_Left
  col1: number[]; // pelvisSCThighSTLandscape_Left_selected
  col2: number[]; // pelvisSCThighSTLandscape_Left_mean
};

export type CoordinativeVariabilityEntry = {
  [key: string]: number[];
  col0: number[]; // all
  col1: number[]; // selected
  col2: number[]; // mean
};

export type CoordinativeVariability_Squat_Ankle = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityCalfFoot
  col1: number[]; // coordinativeVariabilityCalfTFootC
};

export type CoordinativeVariability_Squat_Hip = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityPelvisThigh
  col1: number[]; // coordinativeVariabilityPelvisCThigh
  col2: number[]; // coordinativeVariabilityPelvisThighT
  col3: number[]; // coordinativeVariabilityPelvisCThighT
};

export type CoordinativeVariability_Squat_Knee = {
  [key: string]: number[];
  col0: number[]; // coordinativeVariabilityThighCalf
  col1: number[]; // coordinativeVariabilityThighCalfT
  col2: number[]; // coordinativeVariabilityThighCCalfT
};

export type CoordinativeVariabilityData = {
  [key: string]: CoordinativeVariabilityEntry;
  coordinativeVariabilityDataAnkle_Left: CoordinativeVariabilityEntry; // Data
  coordinativeVariabilityDataAnkle_Right: CoordinativeVariabilityEntry; // Data
  coordinativeVariabilityDataKnee_Left: CoordinativeVariabilityEntry; // Data
  coordinativeVariabilityDataKnee_Right: CoordinativeVariabilityEntry; // Data
  coordinativeVariabilityDataHip_Left: CoordinativeVariabilityEntry; // Data
  coordinativeVariabilityDataHip_Right: CoordinativeVariabilityEntry; // Data
};

export type CoordinativeVariabilityData_Squat = {
  [key: string]:
    | CoordinativeVariability_Squat_Ankle
    | CoordinativeVariability_Squat_Hip
    | CoordinativeVariability_Squat_Knee;
  coordinativeVariabilityCalfFoot_Left: CoordinativeVariability_Squat_Ankle;
  coordinativeVariabilityCalfFoot_Right: CoordinativeVariability_Squat_Ankle;
  coordinativeVariabilityPelvisThigh_Left: CoordinativeVariability_Squat_Hip;
  coordinativeVariabilityPelvisThigh_Right: CoordinativeVariability_Squat_Hip;
  coordinativeVariabilityThighCalf_Left: CoordinativeVariability_Squat_Knee;
  coordinativeVariabilityThighCalf_Right: CoordinativeVariability_Squat_Knee;
};

export type CoordinativeVariabilityStance = {
  [key: string]: any;
  coordinativeVariabilityHip_Left_Stance: CoordinativeVariabilityHip_Left_Stance; // Data
  coordinativeVariabilityHip_Right_Stance: CoordinativeVariabilityHip_Right_Stance; // Data
};

export type CRPHip_Left = {
  [key: string]: StridesData | number[];
  crpPelvisCThighT_Left: StridesData; // Data
  crpPelvisCThigh_Left: StridesData; // Data
  crpPelvisThighMM_Left: number[];
  crpPelvisThighT_Left: StridesData; // Data
  crpPelvisThigh_Left: StridesData; // Data
};
export type CRPHip_Right = {
  [key: string]: StridesData | number[];
  crpPelvisCThighT_Right: StridesData; // Data
  crpPelvisCThigh_Right: StridesData; // Data
  crpPelvisThighMM_Right: number[];
  crpPelvisThighT_Right: StridesData; // Data
  crpPelvisThigh_Right: StridesData; // Data
};

export type CRPKnee_Left = {
  [key: string]: StridesData | number[];
  crpThighCCalfT_Left: StridesData; // Data
  crpThighCalfT_Left: StridesData; // Data
  crpThighCalfMM_Left: number[];
  crpCalfFoot_Left: StridesData; // Data
};

export type CRPKnee_Right = {
  [key: string]: StridesData | number[];
  crpThighCCalfT_Right: StridesData; // Data
  crpThighCalfT_Right: StridesData; // Data
  crpThighCalfMM_Right: number[];
  crpCalfFoot_Right: StridesData; // Data
};

export type CRPAnkle_Left = {
  [key: string]: StridesData | number[];
  crpCalfFootMM_Left: number[];
  crpCalfFoot_Left: StridesData; // Data
  crpCalfTFootC_Left: StridesData; // Data
};

export type CRPAnkle_Right = {
  [key: string]: StridesData | number[];
  crpCalfFootMM_Right: number[];
  crpCalfFoot_Right: StridesData; // Data
  crpCalfTFootC_Right: StridesData; // Data
};

export type crpMeans_Hip = {
  [key: string]: number[];
  col0: number[]; // mean_crpPelvisCThigh_Left
  col1: number[]; // mean_crpPelvisCThigh_Right
  col2: number[]; // mean_crpPelvisThighT_Left
  col3: number[]; // mean_crpPelvisThighT_Right
  col4: number[]; // mean_crpPelvisCThighT_Left
  col5: number[]; // mean_crpPelvisCThighT_Right
  col6: number[]; // mean_crpPelvisThigh_Left
  col7: number[]; //mean_crpPelvisThigh_Right
};

export type crpMeans_Knee = {
  [key: string]: number[];
  col0: number[]; // mean_crpThighCalfT_Left
  col1: number[]; // mean_crpThighCalfT_Right
  col2: number[]; // mean_crpThighCCalfT_Left
  col3: number[]; // mean_crpThighCCalfT_Right
  col4: number[]; // mean_crpThighCalf_Left
  col5: number[]; // mean_crpThighCalf_Right
};

export type crpMeans_Ankle = {
  [key: string]: number[];
  col0: number[]; // mean_crpCalfTFootC_Left
  col1: number[]; // mean_crpCalfTFootC_Right
  col2: number[]; // mean_crpCalfFoot_Left
  col3: number[]; // mean_crpCalfFoot_Right
};
export type CRPData_Mean = {
  [key: string]: crpMeans_Hip | crpMeans_Knee | crpMeans_Ankle;
  meanCrpCalfFoot: crpMeans_Ankle;
  meanCrpThighCalf: crpMeans_Knee;
  meanCrpPelvisThigh: crpMeans_Hip;
};

//#endregion

//#region Walk report Data
export type PointsOfInterests_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type PointsOfInterests_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
};

export type KeyPerformanceIndicators = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type MobilityStatus = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

//#endregion

//#region Run report Data
export type PointsOfInterest_Stance = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type Attractors = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type RearPendulumData = {
  [key: string]: number[];
  col0: number[]; // Left Ankle X
  col1: number[]; // Left Ankle Y
  col2: number[]; // Right Ankle X
  col3: number[]; // Right Ankle Y
};
export type RearPendulumPIs = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type RearPendulumPointsOfInterests_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
  // x positions toeOff Left
  // z positions toeOff Left
  // x positions midSwing Left
  // z positions midSwing Left
};
export type RearPendulumPointsOfInterests_Right = {
  [key: string]: number[];
  col0: number[]; // x positions toeOff Right
  col1: number[]; // z positions toeOff Right
  col2: number[]; // x positions midSwing Right
  col3: number[]; // z positions midSwing Right
};

export type RearPendulumVariance = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type RearPendulumXPositions_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type RearPendulumXPositions_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type RearPendulumZPositions_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type RearPendulumZPositions_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type RunningStatusTableData = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
  col2: number[]; // Symmetry
};

export type ProjectionsFootPlant_Left = {
  [key: string]: number[];
  col0: number[]; // initialContact
  col1: number[]; // midStance
  col2: number[]; // heelOff
};

export type ProjectionsFootPlant_Right = {
  [key: string]: number[];
  col0: number[]; // initialContact
  col1: number[]; // midStance
  col2: number[]; // heelOff
};

export type ProjectionFootPlant = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Std Left
  col2: number[]; // Right
  col3: number[]; // Std Right
};

export type RightThigh_LeftStrides = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type LeftThigh_RightStrides = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type Scissors_Left = {
  [key: string]: number[];
  col0: number[]; // X values
  col1: number[]; // Y values
};

export type Scissors_Right = {
  [key: string]: number[];
  col0: number[]; // X values
  col1: number[]; // Y values
};

export type ScissorsMatrix = {
  [key: string]: number[];
  col0: number[]; // scissorsMatrixCombi_AverageVelocity_LeftLegFront
  col1: number[]; // scissorsMatrixCombi_AverageVelocity_RightLegFront
};

export type ThighVelocity = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type ThighVelocity_Std = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type ThighAcceleration = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type ThighAcceleration_Std = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type ThighAccelerationStance_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type ThighAccelerationStance_Right = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};

export type RespectTheSagittalPlaneData = {
  [key: string]: number[];
  col0: number[]; // Left Knee Coronal
  col1: number[]; // Right Knee Coronal
  col2: number[]; // Left Ankle Coronal
  col3: number[]; // Right Ankle Coronal
};
//#endregion

//#region Squat report Data
export type Rom_Squats = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // std Left
  col2: number[]; // Right
  col3: number[]; // std Right
  col4: number[]; // Symmetry
};

export type Roms_Left = {
  [key: string]: number[];
  col0: number[]; // Pelvis
  col1: number[]; // Hip
  col2: number[]; // Knee
  col3: number[]; // Ankle
  col4: number[]; // VarusValgus
};

export type Roms_Right = {
  [key: string]: number[];
  col0: number[]; // Pelvis
  col1: number[]; // Hip
  col2: number[]; // Knee
  col3: number[]; // Ankle
  col4: number[]; // VarusValgus
};

export type RepetitionTime = {
  [key: string]: number[];
  col0: number[]; // Repetition Time
  col1: number[]; // Repetition Time Std
  col2: number[]; // Repetition Time Quality
  col3: number[]; // Number of Repetitions
};

export type RepetitionTimesData = {
  [key: string]: number[];
  col0: number[]; // repetitionTimes
  col1: number[]; // repetitionQualities
};

export type KeyPerfomanceIndicators_Squats = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type KeyPerfomanceIndicators_Squats_Left = {
  [key: string]: number[];
  col0: number[]; // Heel Raises
  col1: number[]; // Hip Flexions
  col2: number[]; // Hip Exorotations
  col3: number[]; // Hip Endorotations
  col4: number[]; // Varus Valgus
  col5: number[]; // Labels Varus Valgus
};

export type KeyPerfomanceIndicators_Squats_Right = {
  [key: string]: number[];
  col0: number[]; // Heel Raises
  col1: number[]; // Hip Flexions
  col2: number[]; // Hip Exorotations
  col3: number[]; // Hip Endorotations
  col4: number[]; // Varus Valgus
  col5: number[]; // Labels Varus Valgus
};

export type RepetitionTime_SLS = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type RepetitionTimeData_SLS_Left = {
  [key: string]: number[];
  col0: number[]; // repetitionTimes
  col1: number[]; // repetitionQualities
  col2: number[]; // grades_Left
};

export type RepetitionTimeData_SLS_Right = {
  [key: string]: number[];
  col0: number[]; // repetitionTimes
  col1: number[]; // repetitionQualities
  col2: number[]; // grades_Right
};

export type KeyPerformanceIndicators_SLS = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type KeyPerformanceIndicators_SLS_Left = {
  [key: string]: number[];
  col0: number[]; // Heel Raises
  col1: number[]; // Hip Flexions
  col2: number[]; // Hip Exorotations
  col3: number[]; // Hip Endorotations
  col4: number[]; // Hip Abductions
  col5: number[]; // Hip Adductions
  col6: number[]; // Varus Valgus
  col7: number[]; // Labels Varus Valgus
};

export type KeyPerformanceIndicators_SLS_Right = {
  [key: string]: number[];
  col0: number[]; // Heel Raises
  col1: number[]; // Hip Flexions
  col2: number[]; // Hip Exorotations
  col3: number[]; // Hip Endorotations
  col4: number[]; // Hip Abductions
  col5: number[]; // Hip Adductions
  col6: number[]; // Varus Valgus
  col7: number[]; // Labels Varus Valgus
};
//#endregion

/**  Walk report data type */

export type ReportWalk = {
  version: Version;
  reportType: ReportType;
  rom: Rom;
  mobilityStatus: MobilityStatus;
  spatioTemporal: SpatioTemporal;
  keyPerformanceIndicators: KeyPerformanceIndicators;
  kinematicData: KinematicData_Sagittal;
  kinematicData_Coronal: KinematicData_Coronal;
  kinematicData_Transversal: KinematicData_Transversal;
  pointsOfInterests: PointsOfInterests;
  pointsOfInterests_Left: PointsOfInterests_Left;
  pointsOfInterests_Right: PointsOfInterests_Right;
  areasOfConcern: AreasOfConcern;
};

export type WalkReportData = {
  [key: string]: any;
  reportId: ReportWalk;
  version: Version;
  reportType: ReportType;
  rom: Rom;
  mobilityStatus: MobilityStatus;
  spatioTemporal: SpatioTemporal;
  keyPerformanceIndicators: KeyPerformanceIndicators;
  kinematicData_Sagittal: KinematicData_Sagittal;
  kinematicData_Coronal: KinematicData_Coronal;
  kinematicData_Transversal: KinematicData_Transversal;
  leftPelvis: LeftPelvisData;
  rightPelvis: RightPelvisData;
  leftHip: LeftHipData;
  rightHip: RightHipData;
  leftThigh: LeftThighData;
  rightThigh: RightThighData;
  leftKnee: LeftKneeData;
  rightKnee: RightKneeData;
  leftCalf: LeftCalfData;
  rightCalf: RightCalfData;
  leftAnkle: LeftAnkleData;
  rightAnkle: RightAnkleData;
  leftFoot: LeftFootData;
  rightFoot: RightFootData;
  leftPelvis_Coronal: LeftPelvisData_Coronal;
  rightPelvis_Coronal: RightPelvisData_Coronal;
  leftHip_Coronal: LeftHipData_Coronal;
  rightHip_Coronal: RightHipData_Coronal;
  leftThigh_Coronal: LeftThighData_Coronal;
  rightThigh_Coronal: RightThighData_Coronal;
  leftKnee_Coronal: LeftKneeData_Coronal;
  rightKnee_Coronal: RightKneeData_Coronal;
  leftCalf_Coronal: LeftCalfData_Coronal;
  rightCalf_Coronal: RightCalfData_Coronal;
  leftAnkle_Coronal: LeftAnkleData_Coronal;
  rightAnkle_Coronal: RightAnkleData_Coronal;
  leftFoot_Coronal: LeftFootData_Coronal;
  rightFoot_Coronal: RightFootData_Coronal;
  leftPelvis_Transversal: LeftPelvisData_Transversal;
  rightPelvis_Transversal: RightPelvisData_Transversal;
  leftHip_Transversal: LeftHipData_Transversal;
  rightHip_Transversal: RightHipData_Transversal;
  leftThigh_Transversal: LeftThighData_Transversal;
  rightThigh_Transversal: RightThighData_Transversal;
  leftKnee_Transversal: LeftKneeData_Transversal;
  rightKnee_Transversal: RightKneeData_Transversal;
  leftCalf_Transversal: LeftCalfData_Transversal;
  rightCalf_Transversal: RightCalfData_Transversal;
  leftAnkle_Transversal: LeftAnkleData_Transversal;
  rightAnkle_Transversal: RightAnkleData_Transversal;
  leftFoot_Transversal: LeftFootData_Transversal;
  rightFoot_Transversal: RightFootData_Transversal;
  coordinativeVariabilityHip_Left: CoordinativeVariabilityHip_Left;
  coordinativeVariabilityHip_Right: CoordinativeVariabilityHip_Right;
  coordinativeVariabilityKnee_Left: CoordinativeVariabilityKnee_Left;
  coordinativeVariabilityKnee_Right: CoordinativeVariabilityKnee_Right;
  coordinativeVariabilityAnkle_Left: CoordinativeVariabilityAnkle_Left;
  coordinativeVariabilityAnkle_Right: CoordinativeVariabilityAnkle_Right;
  crpPelvisCThigh_Left: CrpPelvisCThigh_Left;
  crpPelvisThighT_Left: CrpPelvisThighT_Left;
  crpPelvisCThighT_Left: CrpPelvisCThighT_Left;
  crpPelvisThigh_Left: CrpPelvisThigh_Left;
  crpPelvisCThigh_Right: CrpPelvisCThigh_Right;
  crpPelvisThighT_Right: CrpPelvisThighT_Right;
  crpPelvisCThighT_Right: CrpPelvisCThighT_Right;
  crpPelvisThigh_Right: CrpPelvisThigh_Right;
  mean_CrpPelvisCThigh_Left: Mean_crpPelvisCThigh_Left;
  mean_CrpPelvisThighT_Left: Mean_crpPelvisThighT_Left;
  mean_CrpPelvisCThighT_Left: Mean_crpPelvisCThighT_Left;
  mean_CrpPelvisThigh_Left: Mean_crpPelvisThigh_Left;
  mean_CrpPelvisCThigh_Right: Mean_crpPelvisCThigh_Right;
  mean_CrpPelvisThighT_Right: Mean_crpPelvisThighT_Right;
  mean_CrpPelvisCThighT_Right: Mean_crpPelvisCThighT_Right;
  mean_CrpPelvisThigh_Right: Mean_crpPelvisThigh_Right;
  crpPelvisThighMM_Left: CrpPelvisThighMM_Left;
  crpPelvisThighMM_Right: CrpPelvisThighMM_Right;
  crpThighCalfT_Left: CrpThighCalfT_Left;
  crpThighCCalfT_Left: CrpThighCCalfT_Left;
  crpThighCalf_Left: CrpThighCalf_Left;
  crpThighCalfT_Right: CrpThighCalfT_Right;
  crpThighCCalfT_Right: CrpThighCCalfT_Right;
  crpThighCalf_Right: CrpThighCalf_Right;
  mean_CrpThighCalfT_Left: Mean_crpThighCalfT_Left;
  mean_CrpThighCCalfT_Left: Mean_crpThighCCalfT_Left;
  mean_CrpThighCalf_Left: Mean_crpThighCalf_Left;
  mean_CrpThighCalfT_Right: Mean_crpThighCalfT_Right;
  mean_CrpThighCCalfT_Right: Mean_crpThighCCalfT_Right;
  mean_CrpThighCalf_Right: Mean_crpThighCalf_Right;
  crpThighCalfMM_Left: CrpThighCalfMM_Left;
  crpThighCalfMM_Right: CrpThighCalfMM_Right;
  crpCalfTFootC_Left: CrpCalfTFootC_Left;
  crpCalfFoot_Left: CrpCalfFoot_Left;
  crpCalfTFootC_Right: CrpCalfTFootC_Right;
  crpCalfFoot_Right: CrpCalfFoot_Right;
  mean_CrpCalfTFootC_Left: Mean_crpCalfTFootC_Left;
  mean_CrpCalfFoot_Left: Mean_crpCalfFoot_Left;
  mean_CrpCalfTFootC_Right: Mean_crpCalfTFootC_Right;
  mean_CrpCalfFoot_Right: Mean_crpCalfFoot_Right;
  crpCalfFootMM_Left: CrpCalfFootMM_Left;
  crpCalfFootMM_Right: CrpCalfFootMM_Right;
  pointsOfInterests: PointsOfInterests;
  pointsOfInterests_Left: PointsOfInterests_Left;
  pointsOfInterests_Right: PointsOfInterests_Right;
  areasOfConcern: AreasOfConcern;
};

/** RunReport data type */
export type RunReportData = {
  [key: string]: any;
  reportType: ReportType;
  version: Version;
  attractors: Attractors;
  rom: Rom;
  spatioTemporal: SpatioTemporal;
  stridesLeft: StridesDataRun_Left;
  stridesRight: StridesDataRun_Right;
  stridesCoronalLeft: StridesData_Left;
  stridesCoronalRight: StridesData_Right;
  stridesTransversalLeft: StridesData_Left;
  stridesTransversalRight: StridesData_Right;
  coordinativeVariabilityStance: CoordinativeVariabilityStance;
  crpMean: CRPData_Mean;
  crpHipLeft: CRPHip_Left;
  crpHipRight: CRPHip_Right;
  crpKneeLeft: CRPKnee_Left;
  crpKneeRight: CRPKnee_Right;
  crpAnkleLeft: CRPAnkle_Left;
  crpAnkleRight: CRPAnkle_Right;
  coordinativeVariability: CoordinativeVariabilityData;

  pointsOfInterests: PointsOfInterests;
  rearPendulumData: RearPendulumData;
  rearPendulumPIs: RearPendulumPIs;
  rearPendulumPointsOfInterest_Left: RearPendulumPointsOfInterests_Left;
  rearPendulumPointsOfInterest_Right: RearPendulumPointsOfInterests_Right;
  rearPendulumVariance: RearPendulumVariance;
  rearPendulumXPositions_Left: StridesData;
  rearPendulumXPositions_Right: StridesData;
  rearPendulumZPositions_Left: StridesData;
  rearPendulumZPositions_Right: StridesData;
  runningStatusTable: RunningStatusTableData;
  projectionsFootPlant_Left: ProjectionsFootPlant_Left;
  projectionsFootPlant_Right: ProjectionsFootPlant_Right;
  projectionFootPlant: ProjectionFootPlant;
  rightThigh_LeftStrides: RightThigh_LeftStrides;
  leftThigh_RightStrides: LeftThigh_RightStrides;
  scissors_Left: Scissors_Left;
  scissors_Right: Scissors_Right;
  scissorsMatrix: ScissorsMatrix;
  thighVelocity: ThighVelocity;
  thighVelocity_Std: ThighVelocity_Std;
  thighAcceleration: ThighAcceleration;
  thighAcceleration_Std: ThighAcceleration_Std;
  coordinativeVariabilityHip_Left_Stance: CoordinativeVariabilityHip_Left_Stance;
  coordinativeVariabilityHip_Right_Stance: CoordinativeVariabilityHip_Right_Stance;
  pointsOfInterest_Stance: PointsOfInterest_Stance;
  thighAccelerationStance_Left: ThighAccelerationStance_Left;
  thighAccelerationStance_Right: ThighAccelerationStance_Right;
  respectTheSagittalPlaneData: RespectTheSagittalPlaneData;
  areasOfConcern: AreasOfConcern;
};

/** Squat report data type */
export type SquatReportData = {
  [key: string]: any;
  reportType: ReportType;
  version: Version;
  rom: Rom_Squats;
  rom_Left: Roms_Left;
  rom_Right: Roms_Right;
  repetitionTime: RepetitionTime;
  repetitionTimes: RepetitionTimesData;
  keyPerformanceIndicators: KeyPerfomanceIndicators_Squats;
  keyPerformanceIndicators_Left: KeyPerfomanceIndicators_Squats_Left;
  keyPerformanceIndicators_Right: KeyPerfomanceIndicators_Squats_Right;
  kinematicData_Sagittal: KinematicData_Sagittal;
  kinematicData_Coronal: KinematicData_Coronal;
  kinematicData_Transversal: KinematicData_Transversal;
  coordinativeVariability: CoordinativeVariabilityData;

  leftPelvis: LeftPelvisData;
  rightPelvis: RightPelvisData;
  leftHip: LeftHipData;
  rightHip: RightHipData;
  leftThigh: LeftThighData;
  rightThigh: RightThighData;
  leftKnee: LeftKneeData;
  rightKnee: RightKneeData;
  leftCalf: LeftCalfData;
  rightCalf: RightCalfData;
  leftAnkle: LeftAnkleData;
  rightAnkle: RightAnkleData;
  leftFoot: LeftFootData;
  rightFoot: RightFootData;
  leftPelvis_Coronal: LeftPelvisData_Coronal;
  rightPelvis_Coronal: RightPelvisData_Coronal;
  leftHip_Coronal: LeftHipData_Coronal;
  rightHip_Coronal: RightHipData_Coronal;
  leftThigh_Coronal: LeftThighData_Coronal;
  rightThigh_Coronal: RightThighData_Coronal;
  leftKnee_Coronal: LeftKneeData_Coronal;
  rightKnee_Coronal: RightKneeData_Coronal;
  leftCalf_Coronal: LeftCalfData_Coronal;
  rightCalf_Coronal: RightCalfData_Coronal;
  leftAnkle_Coronal: LeftAnkleData_Coronal;
  rightAnkle_Coronal: RightAnkleData_Coronal;
  leftFoot_Coronal: LeftFootData_Coronal;
  rightFoot_Coronal: RightFootData_Coronal;
  leftPelvis_Transversal: LeftPelvisData_Transversal;
  rightPelvis_Transversal: RightPelvisData_Transversal;
  leftHip_Transversal: LeftHipData_Transversal;
  rightHip_Transversal: RightHipData_Transversal;
  leftThigh_Transversal: LeftThighData_Transversal;
  rightThigh_Transversal: RightThighData_Transversal;
  leftKnee_Transversal: LeftKneeData_Transversal;
  rightKnee_Transversal: RightKneeData_Transversal;
  leftCalf_Transversal: LeftCalfData_Transversal;
  rightCalf_Transversal: RightCalfData_Transversal;
  leftAnkle_Transversal: LeftAnkleData_Transversal;
  rightAnkle_Transversal: RightAnkleData_Transversal;
  leftFoot_Transversal: LeftFootData_Transversal;
  rightFoot_Transversal: RightFootData_Transversal;
};

/** Single Leg Squats report data type */
export type SLSquatReportData = {
  [key: string]: any;
  reportType: ReportType;
  version: Version;
  rom: Rom_Squats;
  rom_Left: Roms_Left;
  rom_Right: Roms_Right;
  repetitionTime: RepetitionTime_SLS;
  repetitionTimes_Left: RepetitionTimeData_SLS_Left;
  repetitionTimes_Right: RepetitionTimeData_SLS_Right;
  keyPerformanceIndicators: KeyPerformanceIndicators_SLS;
  keyPerformanceIndicators_Left: KeyPerformanceIndicators_SLS_Left;
  keyPerformanceIndicators_Right: KeyPerformanceIndicators_SLS_Right;
  kinematicData_Sagittal: KinematicData_Sagittal;
  kinematicData_Coronal: KinematicData_Coronal;
  kinematicData_Transversal: KinematicData_Transversal;
  leftPelvis: LeftPelvisData;
  rightPelvis: RightPelvisData;
  leftHip: LeftHipData;
  rightHip: RightHipData;
  leftThigh: LeftThighData;
  rightThigh: RightThighData;
  leftKnee: LeftKneeData;
  rightKnee: RightKneeData;
  leftCalf: LeftCalfData;
  rightCalf: RightCalfData;
  leftAnkle: LeftAnkleData;
  rightAnkle: RightAnkleData;
  leftFoot: LeftFootData;
  rightFoot: RightFootData;
  leftPelvis_Coronal: LeftPelvisData_Coronal;
  rightPelvis_Coronal: RightPelvisData_Coronal;
  leftHip_Coronal: LeftHipData_Coronal;
  rightHip_Coronal: RightHipData_Coronal;
  leftThigh_Coronal: LeftThighData_Coronal;
  rightThigh_Coronal: RightThighData_Coronal;
  leftKnee_Coronal: LeftKneeData_Coronal;
  rightKnee_Coronal: RightKneeData_Coronal;
  leftCalf_Coronal: LeftCalfData_Coronal;
  rightCalf_Coronal: RightCalfData_Coronal;
  leftAnkle_Coronal: LeftAnkleData_Coronal;
  rightAnkle_Coronal: RightAnkleData_Coronal;
  leftFoot_Coronal: LeftFootData_Coronal;
  rightFoot_Coronal: RightFootData_Coronal;
  leftPelvis_Transversal: LeftPelvisData_Transversal;
  rightPelvis_Transversal: RightPelvisData_Transversal;
  leftHip_Transversal: LeftHipData_Transversal;
  rightHip_Transversal: RightHipData_Transversal;
  leftThigh_Transversal: LeftThighData_Transversal;
  rightThigh_Transversal: RightThighData_Transversal;
  leftKnee_Transversal: LeftKneeData_Transversal;
  rightKnee_Transversal: RightKneeData_Transversal;
  leftCalf_Transversal: LeftCalfData_Transversal;
  rightCalf_Transversal: RightCalfData_Transversal;
  leftAnkle_Transversal: LeftAnkleData_Transversal;
  rightAnkle_Transversal: RightAnkleData_Transversal;
  leftFoot_Transversal: LeftFootData_Transversal;
  rightFoot_Transversal: RightFootData_Transversal;
};

/** Report data type, cast this to WalkReportData or RunReportData depending on report type */
export type ReportData = WalkReportData | RunReportData | SquatReportData | SLSquatReportData;

/** Report type, merging ReportInfo with the report data for easy access */
export type Report = ReportInfo & {
  data: ReportData | undefined;
};

export type ReportDict = {
  [id: string]: Report;
};
