import React from 'react';
import { IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import './modalindex.css';
import IBimagekneeextensionpreswing1 from './assets/IBimagekneeextensionpreswing1.png';
import IBimagekneeextensionpreswing2 from './assets/IBimagekneeextensionpreswing2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';
import { ORYXModalHeader } from '../../../../components/componentHeaders';

interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}

export const ModalKneeExtensionPreswing = ({ buttons, setShowModal }: ModalProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Knee extension' subtitle='Terminal Stance' setShowInfo={cleanUp} buttons={true} />

      <Swiper
        modules={[Pagination, Mousewheel, Keyboard]}
        pagination={pagination}
        mousewheel={true}
        keyboard={true}
        initialSlide={0}
        speed={400}
        direction='vertical'
        className='mySwiper'
      >
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  From landing to midstance, the leg is compressed (also see: midstance).
                  <br></br>
                  <br></br>
                  If the leg is sufficiently stiff, it can act as a spring mass mechanism. At compression, kinetic
                  energy is stored by stretched tendons and fascia for re-use in terminal stance.
                  <br></br>
                  <br></br>
                  Just like a spring mass, the end position of the leg should be similar to the initial knee position,
                  resulting in similar drop and jump height.
                  <br></br>
                  <br></br>
                  As a key hub for energy transport, the knee plays an important role in this mechanism.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  After reversing the falling movement (at midstance), this elastic stretch is released with leg
                  extension as a result, catapulting the body back upwards.
                  <br></br>
                  <br></br>
                  After unloading elastic energy, there is very little time and range of motion (the hip is almost fully
                  extended) to add energy to the push-off.
                  <br></br>
                  <br></br>
                  Although there must be knee extension for proximo distal sequence, excessive extension indicates
                  emphasis of push-off, resulting in an excessive forward pelvic rotation.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
