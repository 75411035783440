/** Walk analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import './WalkAnalysisReportTab.css';

import { useEffect, useState } from 'react';

import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { WalkJointCouplingGraph } from '../components/Graphs';
import { JointCouplingData } from '../../../model/reportV2.model';

type WalkJointCouplingReportProps = RouteComponentProps & {
  tabData: JointCouplingData;
};

/**
 * Walk Joint Coupling Report Tab
 * @param report - The walk report data
 */
export const WalkJointCouplingReportTab = ({ tabData }: WalkJointCouplingReportProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (width != null) {
      if (width > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);

  //#endregion

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-no-padding full_height'>
              <IonCard className='ORYX_GraphCard'>
                <IonCardHeader className='attractorCard_Header'>
                  <IonGrid fixed className='ion-no-padding'>
                    <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                      <IonCol size='9' className=''>
                        <IonCardTitle className='attractorCard_Title'>Hip / Knee Coupling</IonCardTitle>
                      </IonCol>

                      <IonCol size='3' className='ion-justify-content-center'></IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonCardContent
                  className=''
                  style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', height: '92.5%' }}
                >
                  <IonRow className='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
                    <IonCol size='6' style={{ height: '65%' }}>
                      <WalkJointCouplingGraph
                        data={tabData.seriesJointCoupling_Left}
                        side={'Left'}
                        xMin={tabData.jointCouplingAxisRange.xMinValue}
                        xMax={tabData.jointCouplingAxisRange.xMaxValue}
                        xRange={tabData.jointCouplingAxisRange.xTickAmount}
                      />
                    </IonCol>
                    <IonCol size='6' style={{ height: '65%' }}>
                      <WalkJointCouplingGraph
                        data={tabData.seriesJointCoupling_Right}
                        side={'Right'}
                        xMin={tabData.jointCouplingAxisRange.xMinValue}
                        xMax={tabData.jointCouplingAxisRange.xMaxValue}
                        xRange={tabData.jointCouplingAxisRange.xTickAmount}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
