import { flattenData, toGraphData, toVariabilityData } from '.';
import { AnkleVariabilityData_FunctionalMovement } from '../../../model/reportV2.model';
import { colorSS, colorTC, variabilityAll_Left, variabilityAll_Right } from '../../../theme/colors';

/**
 * Retrieves ankle variability data based on the provided parameters.
 * @param data - The data object containing ankle variability data.
 * @param side - The side of the ankle ('Left' or 'Right').
 * @returns An object containing various series data for ankle variability.
 */
export function getAnkleVariabilityData_FunctionalMovement(
  data: any,
  side: 'Left' | 'Right',
): AnkleVariabilityData_FunctionalMovement {
  const overviewColor = side === 'Left' ? variabilityAll_Left : variabilityAll_Right;
  const crpDataCalfFoot = toGraphData(data[`crpAnkle${side}`][`crpCalfFoot_${side}`]);
  const crpSeriesDataCalfFoot = [
    {
      type: 'line',
      name: 'Calf (s) / Foot (s)',
      data: flattenData(crpDataCalfFoot),
      color: colorSS,
    },
  ];

  const crpDataCalfTFootC = toGraphData(data[`crpAnkle${side}`][`crpCalfTFootC_${side}`]);
  const crpSeriesDataCalfTFootC = [
    {
      type: 'line',
      name: 'Calf (t) / Foot (c)',
      data: flattenData(crpDataCalfTFootC),
      color: colorTC,
    },
  ];

  const coordinativeVariabilityDataAllAnkle = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityDataAnkle_${side}`].col0,
  );

  const coordinativeVariabilitySeriesAllAnkle = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataAllAnkle,
      color: overviewColor,
    },
  ];

  const coordinativeVariabilityDataCalfFoot = toVariabilityData(
    data.coordinativeVariabilitySquat[`coordinativeVariabilityCalfFoot_${side}`].col0,
  );

  const coordinativeVariabilitySeriesCalfFoot = [
    {
      type: 'area',
      name: 'Calf (s) / Foot (s)',
      data: coordinativeVariabilityDataCalfFoot,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataCalfTFootC = toVariabilityData(
    data.coordinativeVariabilitySquat[`coordinativeVariabilityCalfFoot_${side}`].col1,
  );

  const coordinativeVariabilitySeriesCalfTFootC = [
    {
      type: 'area',
      name: 'Calf (t) / Foot (c)',
      data: coordinativeVariabilityDataCalfTFootC,
      color: colorTC,
    },
  ];

  return {
    crpSeriesDataCalfTFootC,
    crpSeriesDataCalfFoot,
    coordinativeVariabilitySeriesAllAnkle,
    coordinativeVariabilitySeriesCalfFoot,
    coordinativeVariabilitySeriesCalfTFootC,
  };
}
