/** Component to show report metadata in a card */
import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import {
  arrowBack,
  calendarOutline,
  closeOutline,
  cloudDownloadOutline,
  eyeOutline,
  menuOutline,
  personCircleOutline,
  trashOutline,
  warningOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useReportContext } from '../../components/reportContext';
import { ReportInfo, ReportStatus, ReportType } from '../../model';
import Runner from '../../assets/icons/Running.png';
import Walker from '../../assets/icons/Walking.png';
import squatter from '../../assets/icons/Squat.png';
import slsquatter from '../../assets/icons/SingleLegSquat.png';
import './ReportCard.css';

// Custom Icons
import stethoscopeIcon from '../../assets/icons/stethoscope.svg';
import errorComponent from '../../assets/icons/errorComponentIcon.svg';

// PDF Download
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFDocument, PDFVersion } from '../ReportV2/PDFDownload/PDFDocument';
import { useAuthContext } from '../../components/authContext';
import { loadRUNData, loadSLSQUATData, loadSQUATData, loadWALKData } from '../ReportV2/PDFDownload/loadData';
import {
  FunctionalMovementKinematicsGraph,
  KinematicsGraph,
  ProjectionFootPlantGraph,
} from '../ReportV2/components/Graphs';
import Chart from 'react-apexcharts';
import { ErrorComponent } from '../../components/404Component';

export interface IReportCardProps {
  reportInfo: ReportInfo;
}

export const getActivity = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case 1:
      return 'Walk';
    case 2:
      return 'Run';
    case 3:
      return 'Squat';
    case 4:
      return 'Single Leg Squat';
    default:
      return 'Error';
  }
};

/** Component to show report metadata in a card
 * @param reportInfo - Shows a spinner if the reportInfo status is "pending", hence still processing in the cloud
 */
export const ReportCard = ({ reportInfo }: IReportCardProps) => {
  const userId = useAuthContext().state.userState?.user.uid;
  const reportType = reportInfo.reportType;
  const success =
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.RUN) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.WALK) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.SQUAT) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.SLSQUAT);
  const error =
    reportInfo.status === ReportStatus.ERROR ||
    reportInfo.reportType === ReportType.ERROR ||
    reportInfo.reportType === ReportType.KNEEL ||
    reportInfo.reportType === ReportType.KNEER;
  const pending = reportInfo.status === ReportStatus.PENDING;
  const activity = getActivity(reportType);
  const cardClassName = error ? 'cardError' : 'reportCard';
  // const statusLabel = reportInfo.status.charAt(0).toUpperCase() + reportInfo.status.slice(1);
  const areasOfConcern = reportInfo.areasOfConcern
    ? reportInfo.areasOfConcern.col0[0] + reportInfo.areasOfConcern.col1[0]
    : 0;
  const history = useHistory();
  const { doDelete } = useReportContext();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const reportRUN = reportInfo.reportType === ReportType.RUN;
  const reportWALK = reportInfo.reportType === ReportType.WALK;
  const reportSQUAT = reportInfo.reportType === ReportType.SQUAT;
  const reportSLSQUAT = reportInfo.reportType === ReportType.SLSQUAT;
  const [showErrorDoDelete, setShowErrorDoDelete] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [majorVersion, setMajorVersion] = useState(0);

  useEffect(() => {
    if (reportInfo.status === ReportStatus.SUCCESS) {
      setMajorVersion(reportInfo.version.col0);
    }
    return () => {
      setPDFDownloadChoosen(false);
    };
  }, [reportInfo.status]);

  // get the name of the person
  const indexToSlice = reportInfo.filename.lastIndexOf('-');
  const name = reportInfo.filename.slice(0, indexToSlice);

  // PDF Download link
  const [PDFDownloadChoosen, setPDFDownloadChoosen] = useState(false);
  const [PDFVersion, setPDFVersion] = useState<PDFVersion>('Specialist');
  const [dataReady, setDataReady] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [chartsMounted, setChartsMounted] = useState(false);
  const [PDFDownloadData, setPDFDownloadData] = useState<any>(undefined);
  const [PDFError, setPDFError] = useState(false);

  const runChartComponentRefs: {
    [key: string]: React.MutableRefObject<Chart | null>;
  } = {
    footplantAtInitialContact_Left: React.useRef(null),
    footplantAtMidStance_Left: React.useRef(null),
    footplantAtHeelOff_Left: React.useRef(null),
    footplantAtInitialContact_Right: React.useRef(null),
    footplantAtMidStance_Right: React.useRef(null),
    footplantAtHeelOff_Right: React.useRef(null),
    pelvisKinematicSagittal: React.useRef(null),
    pelvisKinematicCoronal: React.useRef(null),
    pelvisKinematicTransversal: React.useRef(null),
    hipKinematicSagittal: React.useRef(null),
    hipKinematicCoronal: React.useRef(null),
    hipKinematicTransversal: React.useRef(null),
    kneeKinematicSagittal: React.useRef(null),
    kneeKinematicCoronal: React.useRef(null),
    kneeKinematicTransversal: React.useRef(null),
    ankleKinematicSagittal: React.useRef(null),
    ankleKinematicCoronal: React.useRef(null),
    ankleKinematicTransversal: React.useRef(null),
  };

  const walkChartComponentRefs: {
    [key: string]: React.MutableRefObject<Chart | null>;
  } = {
    pelvisKinematicSagittal: React.useRef(null),
    pelvisKinematicCoronal: React.useRef(null),
    pelvisKinematicTransversal: React.useRef(null),
    hipKinematicSagittal: React.useRef(null),
    hipKinematicCoronal: React.useRef(null),
    hipKinematicTransversal: React.useRef(null),
    kneeKinematicSagittal: React.useRef(null),
    kneeKinematicCoronal: React.useRef(null),
    kneeKinematicTransversal: React.useRef(null),
    ankleKinematicSagittal: React.useRef(null),
    ankleKinematicCoronal: React.useRef(null),
    ankleKinematicTransversal: React.useRef(null),
  };

  const squatChartComponentRefs: {
    [key: string]: React.MutableRefObject<Chart | null>;
  } = {
    pelvisKinematicSagittal: React.useRef(null),
    pelvisKinematicCoronal: React.useRef(null),
    pelvisKinematicTransversal: React.useRef(null),
    hipKinematicSagittal: React.useRef(null),
    hipKinematicCoronal: React.useRef(null),
    hipKinematicTransversal: React.useRef(null),
    kneeKinematicSagittal: React.useRef(null),
    kneeKinematicCoronal: React.useRef(null),
    kneeKinematicTransversal: React.useRef(null),
    ankleKinematicSagittal: React.useRef(null),
    ankleKinematicCoronal: React.useRef(null),
    ankleKinematicTransversal: React.useRef(null),
  };

  const slSquatChartComponentRefs: {
    [key: string]: React.MutableRefObject<Chart | null>;
  } = {
    pelvisKinematicSagittal: React.useRef(null),
    pelvisKinematicCoronal: React.useRef(null),
    pelvisKinematicTransversal: React.useRef(null),
    hipKinematicSagittal: React.useRef(null),
    hipKinematicCoronal: React.useRef(null),
    hipKinematicTransversal: React.useRef(null),
    kneeKinematicSagittal: React.useRef(null),
    kneeKinematicCoronal: React.useRef(null),
    kneeKinematicTransversal: React.useRef(null),
    ankleKinematicSagittal: React.useRef(null),
    ankleKinematicCoronal: React.useRef(null),
    ankleKinematicTransversal: React.useRef(null),
  };

  // #region PDF Download
  // Load the PDF data on button click
  useEffect(() => {
    if (PDFDownloadChoosen && reportWALK) {
      // Load the data for the PDF based on the reportType
      loadWALKData(reportInfo, userId!)
        .then((data) => {
          // console.log('data:', data);
          setPDFDownloadData(data);
          setDataReceived(true);
          // setDataReady(true);
        })
        .catch((error) => {
          console.error('Error loading PDF data:', error);
          setPDFError(true);
        });
    } else if (PDFDownloadChoosen && reportRUN) {
      loadRUNData(reportInfo, userId!)
        .then((data) => {
          // console.log('data:', data);
          setPDFDownloadData(data);
          setDataReceived(true);
        })
        .catch((error) => {
          console.error('Error loading PDF data:', error);
          setPDFError(true);
        });
    } else if (PDFDownloadChoosen && reportSQUAT) {
      // Load the data for the PDF based on the reportType
      loadSQUATData(reportInfo, userId!)
        .then((data) => {
          // console.log('data:', data);
          setPDFDownloadData(data);
          setDataReceived(true);
          // setDataReady(true);
        })
        .catch((error) => {
          console.error('Error loading PDF data:', error);
          setPDFError(true);
        });
    } else if (PDFDownloadChoosen && reportSLSQUAT) {
      // Load the data for the PDF based on the reportType
      loadSLSQUATData(reportInfo, userId!)
        .then((data) => {
          // console.log('data:', data);
          setPDFDownloadData(data);
          setDataReceived(true);
          // setDataReady(true);
        })
        .catch((error) => {
          console.error('Error loading PDF data:', error);
          setPDFError(true);
        });
    }
  }, [PDFDownloadChoosen]);

  useEffect(() => {
    if (!dataReceived) return;

    if (reportWALK) {
      for (const key in walkChartComponentRefs) {
        if (walkChartComponentRefs[key].current === null) {
          return;
        }
      }
    } else if (reportRUN) {
      for (const key in runChartComponentRefs) {
        if (runChartComponentRefs[key].current === null) {
          return;
        }
      }
    } else if (reportSQUAT) {
      for (const key in squatChartComponentRefs) {
        if (squatChartComponentRefs[key].current === null) {
          return;
        }
      }
    } else if (reportSLSQUAT) {
      for (const key in slSquatChartComponentRefs) {
        if (slSquatChartComponentRefs[key].current === null) {
          return;
        }
      }
    }
    setChartsMounted(true);
  }, [dataReceived, walkChartComponentRefs, runChartComponentRefs, squatChartComponentRefs, slSquatChartComponentRefs]);

  useEffect(() => {
    if (!chartsMounted) return;
    if (reportWALK) {
      let promises = [];
      for (const key in walkChartComponentRefs) {
        promises.push(
          // @ts-ignore .chart is not specified in exported type & nullability is checked by chartsMounted
          walkChartComponentRefs[key].current.chart.dataURI().then(({ imgURI }: { imgURI: string }) => {
            PDFDownloadData[`${key}ChartDataUri`] = imgURI;
          }),
        );
      }

      Promise.all(promises)
        .then(() => setDataReady(true))
        .catch((error) => {
          console.error('Error rendering charts:', error);
          setPDFError(true);
        });
    } else if (reportRUN) {
      let promises = [];
      for (const key in runChartComponentRefs) {
        promises.push(
          // @ts-ignore .chart is not specified in exported type & nullability is checked by chartsMounted
          runChartComponentRefs[key].current.chart.dataURI().then(({ imgURI }: { imgURI: string }) => {
            PDFDownloadData[`${key}ChartDataUri`] = imgURI;
          }),
        );
      }

      Promise.all(promises)
        .then(() => setDataReady(true))
        .catch((error) => {
          console.error('Error rendering charts:', error);
          setPDFError(true);
        });
    } else if (reportSQUAT) {
      let promises = [];
      for (const key in squatChartComponentRefs) {
        promises.push(
          // @ts-ignore .chart is not specified in exported type & nullability is checked by chartsMounted
          squatChartComponentRefs[key].current.chart.dataURI().then(({ imgURI }: { imgURI: string }) => {
            PDFDownloadData[`${key}ChartDataUri`] = imgURI;
          }),
        );
      }

      Promise.all(promises)
        .then(() => setDataReady(true))
        .catch((error) => {
          console.error('Error rendering charts:', error);
          setPDFError(true);
        });
    } else if (reportSLSQUAT) {
      let promises = [];
      for (const key in slSquatChartComponentRefs) {
        promises.push(
          // @ts-ignore .chart is not specified in exported type & nullability is checked by chartsMounted
          slSquatChartComponentRefs[key].current.chart.dataURI().then(({ imgURI }: { imgURI: string }) => {
            PDFDownloadData[`${key}ChartDataUri`] = imgURI;
          }),
        );
      }

      Promise.all(promises)
        .then(() => setDataReady(true))
        .catch((error) => {
          console.error('Error rendering charts:', error);
          setPDFError(true);
        });
    }
  }, [chartsMounted]);

  // #endregion PDF Download

  return (
    <>
      <IonToast
        isOpen={showErrorDoDelete}
        onDidDismiss={() => setShowErrorDoDelete(false)}
        message={`No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`}
        duration={2000}
        color={'error'}
      />
      <IonToast
        isOpen={PDFError}
        onDidDismiss={() => setPDFError(false)}
        message={`Can't download PDF for ${reportInfo.filename}, please contact support`}
        duration={2000}
        color={'error'}
      />
      {/* Download modal */}
      <IonModal
        isOpen={downloadModal}
        className='ORYX_PDFDownload_Modal'
        onDidDismiss={() => {
          setDownloadModal(false);
          setPDFDownloadChoosen(false);
        }}
      >
        {/* Two buttons to distinguish between specialist and client report, when button is clicked center the spinner and start download */}
        <IonContent className=''>
          <IonRow
            className='ion-justify-content-center ion-align-items-center'
            style={{ height: '200px', marginTop: 'auto', marginBottom: 'auto', position: 'relative' }}
          >
            <>
              {PDFDownloadChoosen && dataReady ? (
                <PDFDownloadLink
                  document={<PDFDocument reportInfo={reportInfo} data={PDFDownloadData} version={PDFVersion} />}
                  fileName={`${reportInfo.reportName}.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    // console.log('blob:', blob, 'url:', url, 'loading:', loading, 'error:', error);
                    return !url && !blob && !error ? (
                      <div className='sk-fading-circle'>
                        <div className='sk-circle1 sk-circle'></div>
                        <div className='sk-circle2 sk-circle'></div>
                        <div className='sk-circle3 sk-circle'></div>
                        <div className='sk-circle4 sk-circle'></div>
                        <div className='sk-circle5 sk-circle'></div>
                        <div className='sk-circle6 sk-circle'></div>
                        <div className='sk-circle7 sk-circle'></div>
                        <div className='sk-circle8 sk-circle'></div>
                        <div className='sk-circle9 sk-circle'></div>
                        <div className='sk-circle10 sk-circle'></div>
                        <div className='sk-circle11 sk-circle'></div>
                        <div className='sk-circle12 sk-circle'></div>
                      </div>
                    ) : error ? (
                      <div className='' onClick={() => setDownloadModal(false)}>
                        <IonRow className='ion-align-items-stretch ion-no-padding' style={{ padding: '6px' }}>
                          <IonRow className='fullWidth ion-align-items-end'>
                            <IonCol className='' size='7'>
                              <strong>
                                Whoops! Something went wrong. <br />
                              </strong>
                              <p>Sorry about that!</p>
                            </IonCol>
                            <IonCol size='5' className=''>
                              <IonImg src={errorComponent} />
                            </IonCol>
                          </IonRow>
                          <IonRow className='fullWidth ion-justify-content-center'>
                            <IonButton
                              className='primary-red'
                              onClick={() => {
                                setDownloadModal(false);
                              }}
                            >
                              close
                            </IonButton>
                          </IonRow>
                        </IonRow>
                      </div>
                    ) : (
                      <div
                        className='download_Modal_Download'
                        onClick={() =>
                          setTimeout(() => {
                            setDownloadModal(false);
                          }, 500)
                        }
                      >
                        <IonIcon src={cloudDownloadOutline} className='download_Modal_DownloadIcon' />
                        <p className='download_Modal_DownloadLink'>Click to download</p>
                      </div>
                    );
                  }}
                </PDFDownloadLink>
              ) : PDFDownloadChoosen && !dataReady ? (
                <div className='sk-fading-circle'>
                  <div className='sk-circle1 sk-circle'></div>
                  <div className='sk-circle2 sk-circle'></div>
                  <div className='sk-circle3 sk-circle'></div>
                  <div className='sk-circle4 sk-circle'></div>
                  <div className='sk-circle5 sk-circle'></div>
                  <div className='sk-circle6 sk-circle'></div>
                  <div className='sk-circle7 sk-circle'></div>
                  <div className='sk-circle8 sk-circle'></div>
                  <div className='sk-circle9 sk-circle'></div>
                  <div className='sk-circle10 sk-circle'></div>
                  <div className='sk-circle11 sk-circle'></div>
                  <div className='sk-circle12 sk-circle'></div>
                </div>
              ) : (
                <>
                  <IonCol size='6' className=''>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol
                        size='10'
                        className='download_buttonCol ion-text-center'
                        onClick={() => {
                          setPDFDownloadChoosen(true);
                          setPDFVersion('Specialist');
                        }}
                      >
                        <IonIcon src={stethoscopeIcon} className='download_buttonIcon' />
                        <h5 className=''>
                          <small>Specialist Report</small>
                        </h5>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size='6' className=''>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol
                        size='10'
                        className='download_buttonCol ion-text-center'
                        onClick={() => {
                          setPDFDownloadChoosen(true);
                          setPDFVersion('Client');
                        }}
                      >
                        <IonIcon src={personCircleOutline} className='download_buttonIcon' />
                        <h5 className=''>
                          <small>Client Report</small>
                        </h5>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </>
              )}
            </>
          </IonRow>
        </IonContent>
      </IonModal>

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        mode='ios'
        buttons={[
          {
            text: 'Open Report',
            icon: eyeOutline,
            handler: () => {
              switch (reportInfo.reportType) {
                case ReportType.WALK:
                  history.push(`/reportsV2/Walk/${reportInfo.id}`);
                  break;
                case ReportType.RUN:
                  history.push(`/reportsV2/Run/${reportInfo.id}`);
                  break;
                case ReportType.SQUAT:
                  history.push(`/reportsV2/Squat/${reportInfo.id}`);
                  break;
                case ReportType.SLSQUAT:
                  history.push(`/reportsV2/SingleLegSquat/${reportInfo.id}`);
                  break;
                default:
                  break;
              }
            },
          },
          {
            text: 'Generate PDF',
            icon: cloudDownloadOutline,
            handler: () => {
              setDownloadModal(true);
            },
          },
          {
            text: 'Delete',
            role: 'destructive',
            icon: trashOutline,
            handler: () => {
              if (reportInfo.id) {
                doDelete(reportInfo.id);
              } else {
                setShowErrorDoDelete(true);
                if (import.meta.env.MODE !== 'production')
                  console.log(
                    `No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`,
                  );
              }
            },
          },

          {
            text: 'Cancel',
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              if (import.meta.env.MODE !== 'production') console.log('Cancel clicked');
            },
          },
        ]}
      ></IonActionSheet>

      <IonCard
        // routerLink={success ? `/reports/${reportInfo.id}` : '#'}
        // routerDirection='forward'
        className={cardClassName}
        button
        style={{ height: '100%' }}
      >
        <IonCardHeader mode='md' className='attractorCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
              <IonCol size='10' className=''>
                <IonCardTitle className='attractorCard_Title'>
                  {reportInfo.reportName ? reportInfo.reportName : name}
                </IonCardTitle>
                {error && (
                  <IonCardSubtitle className='attractorCard_Subtitle' color='danger'>
                    Sorry, we couldn&#39;t process your report. Please contact support.
                  </IonCardSubtitle>
                )}
              </IonCol>

              <IonCol size='2' className='ion-justify-content-center'>
                {pending ? (
                  <div className=''>
                    <IonSpinner color='primary' name='bubbles' />
                  </div>
                ) : (
                  <IonButtons className='ion-justify-content-center'>
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='attractorCard_Header_Buttons'
                      onClick={() => setShowActionSheet(true)}
                    >
                      <IonIcon icon={menuOutline} />
                    </IonButton>
                  </IonButtons>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>

        <IonCardContent
          className='reportcardContent'
          onClick={() => {
            if (majorVersion == 1) {
              // history.push(`/reportsV1/${reportInfo.id}`);
              switch (reportInfo.reportType) {
                case ReportType.WALK:
                  history.push(`/reportsV1/Walk/${reportInfo.id}`);
                  break;
                case ReportType.RUN:
                  history.push(`/reportsV1/Run/${reportInfo.id}`);
                  break;
                default:
                  break;
              }
            } else {
              switch (reportInfo.reportType) {
                case ReportType.WALK:
                  history.push(`/reportsV2/Walk/${reportInfo.id}`);
                  break;
                case ReportType.RUN:
                  history.push(`/reportsV2/Run/${reportInfo.id}`);
                  break;
                case ReportType.SQUAT:
                  history.push(`/reportsV2/Squat/${reportInfo.id}`);
                  break;
                case ReportType.SLSQUAT:
                  history.push(`/reportsV2/SingleLegSquat/${reportInfo.id}`);
                  break;
                default:
                  break;
              }
            }
          }}
        >
          <IonList lines='none'>
            <IonItem>
              <IonIcon icon={calendarOutline} slot='start' />
              <IonLabel>{reportInfo.uploadTimestamp.toLocaleString()}</IonLabel>
            </IonItem>

            {reportRUN && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={Runner} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportWALK && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={Walker} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportSQUAT && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={squatter} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportSLSQUAT && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={slsquatter} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {areasOfConcern >= 0 && success && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                {/* <IonLabel>Areas of Concern: {areasOfConcern}</IonLabel> */}
                <IonLabel>{areasOfConcern} Area(s) of concern</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.KNEEL && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Left Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.KNEER && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Right Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.ERROR && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Data quality is poor.</IonLabel>
              </IonItem>
            )}
          </IonList>
          {reportInfo.tags[0] != '' && (
            <IonRow className='ion-justify-content-center'>
              <IonCol size='12' className='ion-text-center'>
                <>
                  {reportInfo.tags.map((tag) => (
                    <IonChip key={tag}>{tag}</IonChip>
                  ))}
                </>
              </IonCol>
            </IonRow>
          )}
        </IonCardContent>
      </IonCard>

      {PDFDownloadChoosen && dataReceived && reportRUN && (
        <div style={{ display: 'none' }}>
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtInitialContactSeries_Left}
            subtitle='Initial Contact'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtInitialContact_Left']}
          />
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtMidStanceSeries_Left}
            subtitle='MidStance'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtMidStance_Left']}
          />
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtHeelOffSeries_Left}
            subtitle='Heel Off'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtHeelOff_Left']}
          />
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtInitialContactSeries_Right}
            subtitle='Initial Contact'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtInitialContact_Right']}
          />
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtMidStanceSeries_Right}
            subtitle='MidStance'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtMidStance_Right']}
          />
          <ProjectionFootPlantGraph
            data={PDFDownloadData.footplantAtHeelOffSeries_Right}
            subtitle='Heel Off'
            forPdf={true}
            ref={runChartComponentRefs['footplantAtHeelOff_Right']}
          />
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
            strokeWidth={2}
            forPdf={true}
            ref={runChartComponentRefs['pelvisKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Drop (-) / Hike (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
            ref={runChartComponentRefs['pelvisKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
            ref={runChartComponentRefs['pelvisKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
            ref={runChartComponentRefs['hipKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Abduction (-) / Adduction (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
            ref={runChartComponentRefs['hipKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
            ref={runChartComponentRefs['hipKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
            ref={runChartComponentRefs['kneeKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Valgus (-) / Varus (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
            ref={runChartComponentRefs['kneeKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
            ref={runChartComponentRefs['kneeKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
            ref={runChartComponentRefs['ankleKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Eversion (-) / Inversion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
            ref={runChartComponentRefs['ankleKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
            ref={runChartComponentRefs['ankleKinematicTransversal']}
          />
        </div>
      )}
      {PDFDownloadChoosen && dataReceived && reportWALK && (
        <div style={{ display: 'none' }}>
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
            ref={walkChartComponentRefs['pelvisKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Drop (-) / Hike (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
            ref={walkChartComponentRefs['pelvisKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
            ref={walkChartComponentRefs['pelvisKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
            ref={walkChartComponentRefs['hipKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Abduction (-) / Adduction (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
            ref={walkChartComponentRefs['hipKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.hipKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
            ref={walkChartComponentRefs['hipKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
            ref={walkChartComponentRefs['kneeKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Valgus (-) / Varus (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
            ref={walkChartComponentRefs['kneeKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
            ref={walkChartComponentRefs['kneeKinematicTransversal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
            ref={walkChartComponentRefs['ankleKinematicSagittal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Eversion (-) / Inversion (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
            ref={walkChartComponentRefs['ankleKinematicCoronal']}
          />
          <KinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            graphAnnotations={PDFDownloadData.graphAnnotations}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
            ref={walkChartComponentRefs['ankleKinematicTransversal']}
          />
        </div>
      )}
      {PDFDownloadChoosen && dataReceived && reportSQUAT && (
        <div style={{ display: 'none' }}>
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
            ref={squatChartComponentRefs['pelvisKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Drop (-) / Hike (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
            ref={squatChartComponentRefs['pelvisKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
            ref={squatChartComponentRefs['pelvisKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
            ref={squatChartComponentRefs['hipKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Abduction (-) / Adduction (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
            ref={squatChartComponentRefs['hipKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
            ref={squatChartComponentRefs['hipKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
            ref={squatChartComponentRefs['kneeKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Valgus (-) / Varus (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
            ref={squatChartComponentRefs['kneeKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
            ref={squatChartComponentRefs['kneeKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
            ref={squatChartComponentRefs['ankleKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Eversion (-) / Inversion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
            ref={squatChartComponentRefs['ankleKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
            ref={squatChartComponentRefs['ankleKinematicTransversal']}
          />
        </div>
      )}
      {PDFDownloadChoosen && dataReceived && reportSLSQUAT && (
        <div style={{ display: 'none' }}>
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
            ref={slSquatChartComponentRefs['pelvisKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Drop (-) / Hike (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
            ref={slSquatChartComponentRefs['pelvisKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.pelvisKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
            ref={slSquatChartComponentRefs['pelvisKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
            ref={slSquatChartComponentRefs['hipKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Abduction (-) / Adduction (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
            ref={slSquatChartComponentRefs['hipKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.hipKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
            ref={slSquatChartComponentRefs['hipKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Extension (-) / Flexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
            ref={slSquatChartComponentRefs['kneeKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Valgus (-) / Varus (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
            ref={slSquatChartComponentRefs['kneeKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.kneeKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
            ref={slSquatChartComponentRefs['kneeKinematicTransversal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Sagittal.series}
            title={'Sagittal'}
            subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
            ref={slSquatChartComponentRefs['ankleKinematicSagittal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Coronal.series}
            title={'Frontal'}
            subtitle={'Eversion (-) / Inversion (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
            ref={slSquatChartComponentRefs['ankleKinematicCoronal']}
          />
          <FunctionalMovementKinematicsGraph
            data={PDFDownloadData.ankleKinematicData.Transversal.series}
            title={'Transversal'}
            subtitle={'External Rotation (-) / Internal Rotation (+)'}
            strokeWidth={2}
            forPdf={true}
            yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
            ref={slSquatChartComponentRefs['ankleKinematicTransversal']}
          />
        </div>
      )}
    </>
  );
};
