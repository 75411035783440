import React from 'react';
import { useReportInfo, useReports } from '../hooks/useReports';
import { ReportDict, ReportInfoDict } from '../model';
import { useAuthContext } from './authContext';

type ReportContextProps =
  | {
      reportInfoDict: ReportInfoDict;
      isLoading: 'reportInfo' | undefined;
      isError: Error | undefined;
      clearError: () => void;
      doDelete: (reportId: string) => void;
      getReportData: (reportId: string) => any;
      dataReady: boolean;
      setDataReady: (dataReady: boolean) => void;
      editTags: (reportId: string, tags: string[]) => void;
      editReportName: (reportId: string, reportName: string) => void;
      addReportNotes: (reportId: string, notes: string) => void;
      editReportNotes: (reportId: string, notes: string, index: number) => void;
      isCompleted: boolean;
      tags: string[];
      reportName: string;
      notes: string[];
      fileListener: (reportId: string) => Promise<void>;
      data: any;
      resetData: () => void;
    }
  | undefined;
const reportContext = React.createContext<ReportContextProps>(undefined);

interface ReportProviderProps {
  children: React.ReactNode;
}
export const ReportProvider = ({ children }: ReportProviderProps) => {
  const userId = useAuthContext().state.userState?.user.uid;

  let value: ReportContextProps = undefined;
  if (userId) {
    const reportInfoHook = useReportInfo(userId);
    const reportHook = useReports(userId);

    const isLoading = reportInfoHook.isLoading ? 'reportInfo' : undefined;
    const isError = reportInfoHook.isError ? reportInfoHook.isError : reportHook.isError;
    const clearError = () => {
      reportInfoHook.clearError();
      reportHook.clearError();
    };
    const doDelete = (reportId: string) => {
      reportHook.doDelete(reportId);
    };
    const getReportData = (reportId: string) => {
      reportHook.getReportData(reportId);
    };

    const editTags = (reportId: string, tags: string[]) => {
      reportHook.editTags(reportId, tags);
    };

    const editReportName = (reportId: string, reportName: string) => {
      reportHook.editReportName(reportId, reportName);
    };

    const addReportNotes = (reportId: string, notes: string) => {
      reportHook.addReportNotes(reportId, notes);
    };

    const editReportNotes = (reportId: string, notes: string, index: number) => {
      reportHook.editReportNotes(reportId, notes, index);
    };

    const dataReady = reportHook.dataReady;

    value = {
      reportInfoDict: reportInfoHook.reportInfoDict,
      isLoading: isLoading,
      isError: isError,
      clearError: clearError,
      doDelete: doDelete,
      getReportData: getReportData,
      editTags: editTags,
      editReportName: editReportName,
      addReportNotes: addReportNotes,
      editReportNotes: editReportNotes,
      isCompleted: reportHook.isCompleted,
      dataReady: dataReady,
      setDataReady: reportHook.setDataReady,
      tags: reportHook.tags,
      notes: reportHook.notes,
      reportName: reportHook.reportName,
      fileListener: reportHook.fileListener,
      data: reportHook.data,
      resetData: reportHook.resetData,
    };
  }
  return <reportContext.Provider value={value}>{children}</reportContext.Provider>;
};

export const useReportContext = () => {
  const context = React.useContext(reportContext);
  if (context === undefined) {
    throw new Error('useReportContext must be used within a ReportProvider');
  }
  return context;
};
