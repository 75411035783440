import { StyleSheet } from '@react-pdf/renderer';

export const PDFFormatting = StyleSheet.create({
  avatar: {
    width: '100px',
    alignSelf: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  avatar_NoMargin: {
    width: '100px',
    alignSelf: 'center',
  },
  attractorCardAvatar: {
    width: '85px',
    alignSelf: 'center',
  },
  contentContainer_65: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    height: '65%',
    marginHorizontal: '12px',
    alignSelf: 'center',
    marginBottom: '5px',
    paddingVertical: '10px',
  },
  contentContainer_45H: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    height: '45%',
    marginHorizontal: '12px',
    alignSelf: 'center',
    paddingVertical: '10px',
  },
  contenContainer_50w: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '50%',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  tableContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  cardResultContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  attractorCardContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    flexDirection: 'column',
    width: '25%',
    // flexWrap: 'nowrap',
    // flex: '0 0 125px',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  KpiCardscontainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    marginHorizontal: '12px',
    alignSelf: 'center',
    marginBottom: '5px',
    paddingVertical: '10px',
  },
});
