/** form to enter a new password */
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useAuthContext } from '../../components/authContext';
import logo from '../../assets/oryx-logo.png';
import './loginForm.css';
import { eyeOffOutline, eyeOutline, lockClosedOutline } from 'ionicons/icons';
import './newPassword.css';
import { MustContainItem } from './PasswordMustContainItem';
import { useHistory } from 'react-router';

export const NewPassword = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [password, setPassword] = useState<string>('');
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setShowPassword(!showPassword);
  };

  //getting the OOB code from the URL params, this oob code is sent in an email (embedded in a link)
  const urlParams = new URLSearchParams(window.location.search);
  let passwordResetCode = '';
  try {
    passwordResetCode = urlParams.get('oobCode')!.toString();
  } catch {
    passwordResetCode = '';
  }

  const { confirmPasswordReset } = useAuthContext();

  async function submitNewPassword(e: React.FormEvent) {
    e.preventDefault();
    setFormSubmitted(true);
    if (password && (await confirmPasswordReset(passwordResetCode, password))) {
      history.push(`/`);
    } else {
      alert('An error has occured, please contact support.');
    }
  }

  const handlePassword = (e: any) => {
    const passwordInput = e.detail.value;
    setPassword(passwordInput);
  };

  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  // checks all validations are true
  const [allValid, setAllValid] = useState(false);

  const mustContainData = [
    ['An uppercase letter (A-Z)', containsUL],
    ['A lowercase letter (a-z)', containsLL],
    ['A number (0-9)', containsN],
    ['A special character (!@#$)', containsSC],
    ['At least 8 characters', contains8C],
  ];

  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUL(true);
    else setContainsUL(false);

    //has lowercase letter
    if (password.toUpperCase() != password) setContainsLL(true);
    else setContainsLL(false);

    //has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    //has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\"():<>?]/g.test(password)) setContainsSC(true);
    else setContainsSC(false);

    //has 8 characters
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);

    //all validations passed
    if (containsUL && containsLL && containsN && containsSC && contains8C) setAllValid(true);
    else setAllValid(false);
  };

  return (
    <form noValidate onSubmit={submitNewPassword}>
      <IonGrid fixed className='login_form'>
        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <IonImg src={logo} className='login_logo'></IonImg>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <div>Please enter a new password</div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <IonList>
              <IonItem lines='full'>
                {showPassword ? (
                  <IonIcon icon={eyeOffOutline} slot='end' onClick={togglePassword} id='eyeOff'></IonIcon>
                ) : (
                  <IonIcon icon={eyeOutline} slot='end' onClick={togglePassword} id='eyeOn'></IonIcon>
                )}
                <IonIcon icon={lockClosedOutline} slot='start'></IonIcon>
                <IonInput
                  placeholder='New password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  onIonChange={handlePassword}
                  onKeyUp={validatePassword}
                  id='password-input'
                  value={password}
                ></IonInput>
              </IonItem>

              {formSubmitted && !password && (
                <IonText color='danger'>
                  <p className='ion-padding-start'>A Password is required.</p>
                </IonText>
              )}
            </IonList>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='6' className='ion-padding'>
            <IonButton type='submit' expand='block' disabled={!allValid} className='primary'>
              Save
            </IonButton>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol id='invalids-container'>
            <div>The password must:</div>
            {mustContainData.map((data) => (
              <MustContainItem key={''} data={data} />
            ))}
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};
