import { SpeedLabel } from '../../../model/reportV2.model';

export const defineSpeedLabel = (speed: number): SpeedLabel => {
  if (speed === 1) {
    return 'Ok';
  } else if (speed === 2) {
    return 'Slow';
  } else if (speed === 3) {
    return 'Too slow';
  } else {
    return 'Too fast';
  }
};
