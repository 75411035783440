import { getAllStridesKinematicData, getMeanRunKinematicData } from '.';
import { Joint, KinematicData } from '../../../model/reportV2.model';

/**
 * Retrieves all kinematic data for a specific joint.
 *
 * @param data - The input data.
 * @param joint - The joint to retrieve kinematic data for.
 * @returns The kinematic data object containing all strides and the mean.
 */
export function getAllRunKinematicData(data: any, joint: Joint): KinematicData {
  const allStrides = getAllStridesKinematicData(data, joint);
  const mean = getMeanRunKinematicData(data, joint);

  const KinematicData = {
    allStrides,
    mean,
  };
  return KinematicData;
}
