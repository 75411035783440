/** Convert report data entry from a dict of colId->row to
 * an array of [col][row]
 * @param data - Entry from the report data, e.g., report.data.romData
 */

// Disabling next line because the fix for it is breaking so far, and it probably has a reason to be of type 'any'
// eslint-disable-next-line
export function toMatrix(data: any): number[][] {
  const f = () => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        return data[colId];
      });
  };
  return f();
}
