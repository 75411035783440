/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2024
 * ==================================================================
 *
 * Pitching analysis Page.
 */

import { IonContent, IonGrid, IonPage, IonRow, isPlatform, useIonAlert } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ORYXHeader } from '../../components/menuItems/Header';
import './pitchingAnalysis.css';
import { useAuthContext } from '../../components/authContext';

export const PitchingDashboard = () => {
  const history = useHistory();
  const [present] = useIonAlert();
  const platform = isPlatform('capacitor') ? 'capacitor' : 'web';
  const userId = useAuthContext().state.userState?.user.uid;
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <IonPage id='main'>
      <ORYXHeader backTarget='/dashboard' />
      <IonContent className='ion-padding' id='main' fullscreen color='light'>
        <IonGrid>
          <IonRow>I am in need of Content!</IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
