/** Main menu shown on the top right */
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonFooter,
  IonImg,
  IonMenuToggle,
  IonButton,
} from '@ionic/react';
import {
  accessibilityOutline,
  barbellOutline,
  barChartOutline,
  baseball,
  baseballOutline,
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
  homeOutline,
  peopleOutline,
  settingsOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useAuthContext } from '../authContext';
import logo from '../../assets/oryx-logo.png';
import icon from '../../assets/icons/icon.svg';
import { useLocation } from 'react-router';
import './menu.css';
import { useState } from 'react';

import ORYX_KnowledgeBaseIcon from '../../assets/icons/ORYX_KnowledgeBaseIcon.svg';

/** Main menu shown top-left
 * Note: must be linked to the "Nav" item with the same id="main"
 * to be able to be visible.
 * Also, every content item on every page has the same id="main", not
 * fully sure if that is needed.
 */

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  status: string;
  identifier: string;
  toShow: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/dashboard',
    identifier: 'dashboard',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    status: 'active',
    toShow: 'production',
  },
  {
    title: 'Reports',
    url: '/reports',
    identifier: 'reports',
    iosIcon: barChartOutline,
    mdIcon: barChartOutline,
    status: 'active',
    toShow: 'production',
  },
  {
    title: 'New test',
    url: '/measurements',
    identifier: 'measurements',
    iosIcon: accessibilityOutline,
    mdIcon: accessibilityOutline,
    status: 'active',
    toShow: 'development',
  },
  {
    title: 'Pitching analysis',
    url: '/Pitching',
    identifier: 'pitching',
    iosIcon: baseballOutline,
    mdIcon: baseballOutline,
    status: 'active',
    toShow: 'development',
  },
  {
    title: 'Training Builder Tool',
    url: '/dashboard',
    identifier: 'trainingBuilder',
    iosIcon: barbellOutline,
    mdIcon: barbellOutline,
    status: 'disabled',
    toShow: 'development',
  },
  {
    title: 'Customers',
    url: '/dashboard',
    identifier: 'customers',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    status: 'disabled',
    toShow: 'development',
  },
  {
    title: 'Knowledge Base',
    url: '/docs',
    identifier: 'docs',
    iosIcon: ORYX_KnowledgeBaseIcon,
    mdIcon: ORYX_KnowledgeBaseIcon,
    status: 'active',
    toShow: 'development',
  },
  {
    title: 'Settings',
    url: '/dashboard',
    identifier: 'settings',
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
    status: 'disabled',
    toShow: 'development',
  },
];
export const Menu: React.FC = () => {
  const { state, signOut } = useAuthContext();
  const location = useLocation();
  const pathArray = window.location.pathname.split('/');
  const [size, setSize] = useState<'small' | 'large'>('small');

  return (
    <IonMenu contentId='main' type='push' className={size}>
      {size == 'small' ? (
        <>
          <IonContent forceOverscroll={false} className='menu'>
            <IonHeader mode='ios' className='oryx_header ion-no-border'>
              <IonImg src={logo} className='header_logo_small' />
            </IonHeader>

            {!!state.userState && (
              <>
                <IonList lines='none'>
                  {appPages.map((appPage, _) => {
                    let menuClassNames = '';
                    let color = '';
                    if (appPage.status === 'disabled') {
                      menuClassNames = 'disabled';
                      color = 'disabled';
                    } else if (location.pathname === appPage.url || pathArray[1] === appPage.identifier) {
                      menuClassNames = 'selected';
                      color = 'primary';
                    }
                    // Only show menu items that are set to show in the current environment and always show 'production' items
                    if (appPage.toShow === 'production' || appPage.toShow === import.meta.env.MODE) {
                      if (appPage.status === 'active') {
                        return (
                          <IonMenuToggle key={appPage.title} autoHide={false}>
                            <IonItem
                              className={menuClassNames}
                              routerLink={appPage.url}
                              routerDirection='none'
                              lines='none'
                              detail={false}>
                              <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} color={color} />
                            </IonItem>
                          </IonMenuToggle>
                        );
                      } else {
                        return (
                          <IonMenuToggle key={appPage.title} autoHide={false}>
                            <IonItem className={menuClassNames} lines='none' detail={false}>
                              <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} color={color} />
                            </IonItem>
                          </IonMenuToggle>
                        );
                      }
                    }
                  })}
                </IonList>
              </>
            )}
          </IonContent>
          <IonFooter className='ion-no-border'>
            <IonToolbar>
              <IonButton slot='start' onClick={() => setSize('large')} fill='clear' className='menuToggleButton'>
                <IonIcon icon={chevronForwardCircleOutline} />
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </>
      ) : (
        <>
          <IonContent forceOverscroll={false} className='menu'>
            <IonHeader mode='ios' className='oryx_header ion-no-border'>
              <IonImg src={logo} className='header_logo_small' />
            </IonHeader>

            {!!state.userState && (
              <>
                <IonList lines='none'>
                  {appPages.map((appPage) => {
                    let menuClassNames = '';
                    let color = '';
                    if (appPage.status === 'disabled') {
                      menuClassNames = 'disabled';
                      color = 'disabled';
                    } else if (location.pathname === appPage.url || pathArray[1] === appPage.identifier) {
                      menuClassNames = 'selected';
                      color = 'primary';
                    }
                    if (appPage.toShow === 'production' || appPage.toShow === import.meta.env.MODE) {
                      if (appPage.status === 'active') {
                        return (
                          <IonMenuToggle key={appPage.title} autoHide={false}>
                            <IonItem
                              className={menuClassNames}
                              routerLink={appPage.url}
                              routerDirection='none'
                              lines='none'
                              detail={false}>
                              <IonIcon slot='start' ios={appPage.iosIcon} md={appPage.mdIcon} color={color} />
                              <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        );
                      } else {
                        return (
                          <IonMenuToggle key={appPage.title} autoHide={false}>
                            <IonItem className={menuClassNames} lines='none' detail={false}>
                              <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} color={color} />
                              <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        );
                      }
                    }
                  })}
                </IonList>
              </>
            )}
          </IonContent>
          <IonFooter className='ion-no-border'>
            <IonToolbar>
              <IonButton slot='start' onClick={() => setSize('small')} fill='clear' className='menuToggleButton'>
                <IonIcon icon={chevronBackCircleOutline} />
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </>
      )}
    </IonMenu>
  );
};
