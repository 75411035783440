import { flattenData, toGraphData, toVariabilityData } from '.';
import { KneeVariabilityData, KneeVariabilityData_FunctionalMovement } from '../../../model/reportV2.model';
import { colorSS, colorCT, colorST, variabilityAll_Left, variabilityAll_Right } from '../../../theme/colors';

/**
 * Retrieves knee variability data based on the provided parameters.
 *
 * @param data - The data object containing the knee variability data.
 * @param side - The side of the knee ('Left' or 'Right').
 * @returns The knee variability data.
 */
export function getKneeVariabilityData_FunctionalMovement(
  data: any,
  side: 'Left' | 'Right',
): KneeVariabilityData_FunctionalMovement {
  const overviewColor = side === 'Left' ? variabilityAll_Left : variabilityAll_Right;
  const crpDataThighCalf = toGraphData(data[`crpKnee${side}`][`crpThighCalf_${side}`]);
  const crpSeriesDataThighCalf = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (s)',
      data: flattenData(crpDataThighCalf),
      color: colorSS,
    },
  ];

  const crpDataThighCalfT = toGraphData(data[`crpKnee${side}`][`crpThighCalfT_${side}`]);
  const crpSeriesDataThighCalfT = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (t)',
      data: flattenData(crpDataThighCalfT),
      color: colorST,
    },
  ];

  const crpDataThighCCalfT = toGraphData(data[`crpKnee${side}`][`crpThighCCalfT_${side}`]);
  const crpSeriesDataThighCCalfT = [
    {
      type: 'line',
      name: 'Thigh (c) / Calf (t)',
      data: flattenData(crpDataThighCCalfT),
      color: colorCT,
    },
  ];

  const coordinativeVariabilityDataAllKnee = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityDataKnee_${side}`].col0,
  );
  const coordinativeVariabilitySeriesAllKnee = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataAllKnee,
      color: overviewColor,
    },
  ];

  const coordinativeVariabilityDataThighCalf = toVariabilityData(
    data.coordinativeVariabilitySquat[`coordinativeVariabilityThighCalf_${side}`].col0,
  );
  const coordinativeVariabilitySeriesThighCalf = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (s)',
      data: coordinativeVariabilityDataThighCalf,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataThighCalfT = toVariabilityData(
    data.coordinativeVariabilitySquat[`coordinativeVariabilityThighCalf_${side}`].col1,
  );
  const coordinativeVariabilitySeriesThighCalfT = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (t)',
      data: coordinativeVariabilityDataThighCalfT,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataThighCCalfT = toVariabilityData(
    data.coordinativeVariabilitySquat[`coordinativeVariabilityThighCalf_${side}`].col2,
  );
  const coordinativeVariabilitySeriesThighCCalfT = [
    {
      type: 'area',
      name: 'Thigh (c) / Calf (t)',
      data: coordinativeVariabilityDataThighCCalfT,
      color: colorCT,
    },
  ];

  return {
    crpSeriesDataThighCalfT,
    crpSeriesDataThighCCalfT,
    crpSeriesDataThighCalf,
    coordinativeVariabilitySeriesAllKnee,
    coordinativeVariabilitySeriesThighCalf,
    coordinativeVariabilitySeriesThighCalfT,
    coordinativeVariabilitySeriesThighCCalfT,
  };
}
