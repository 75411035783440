import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonButton,
  IonButtons,
  IonCardSubtitle,
} from '@ionic/react';
import './modalindex.css';
import IBimagePelvicobliquity1 from './assets/IBimagePelvicobliquity1.png';
import IBimagePelvicobliquity2 from './assets/IBimagePelvicobliquity2.png';
import { chevronDownOutline, chevronUpOutline, closeCircleOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';
import { ORYXModalHeader } from '../../../../components/componentHeaders';
interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}

export const ModalPelvicObliquity = ({ buttons, setShowModal }: ModalProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Pelvis obliquity' subtitle='Midstance' setShowInfo={cleanUp} buttons={true} />

      <Swiper
        modules={[Pagination, Mousewheel, Keyboard]}
        pagination={pagination}
        mousewheel={true}
        keyboard={true}
        initialSlide={0}
        speed={400}
        direction='vertical'
        className='mySwiper'
      >
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagePelvicobliquity1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  At initial contact the position of it is almost neutral (0° obliquity). Throughout loading response,
                  the hip of the trailing leg begins to drop, increasing the magnitude of pelvic obliquity. Hip
                  abductors become active to halt the contralateral pelvic drop.
                  <br></br>
                  <br></br>
                  After midstance, the motion of the pelvis is reversed, with the swing-side hip lifting to regain
                  neutral position again. This movement is called ‘hike’. People with a normal healthy gait, typically
                  exhibit 5° to 7° of pelvic obliquity towards each side. The magnitude of pelvic obliquity increases
                  linearly with walking speed.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagePelvicobliquity2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='11'>
                <div>
                  Since the weight of the upper body acts downward through the pelvis, control of pelvic motion is vital
                  to maintaining whole body balance in the coronal plane.
                  <br></br>
                  <br></br>
                  Excessive pelvic drop (Trendelenburg sign) is due to weakness of the hip abductors on the supporting
                  leg side.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
