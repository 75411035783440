/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Header Component, used on all pages.
 */
import {
  IonBreadcrumb,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonProgressBar,
  IonToolbar,
} from '@ionic/react';
import {
  arrowBack,
  arrowForwardCircle,
  chevronDownOutline,
  chevronForwardOutline,
  closeCircleOutline,
  languageOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from '../authContext';
import English from '../../assets/icons/united-kingdom.svg';
import Dutch from '../../assets/icons/netherlands.svg';
import './index.css';
import { YourProfile } from './YourProfile';
import { YourSubscriptions } from './YourSubscriptions';
import { useUserInfoContext } from '../userInfoContext';
import { MagneticFieldData, NativeSensorControl } from '../../capacitor_plugins/NativeSensorControlPluginClass';

type HeaderProps = {
  loading?: boolean;
  progress?: number;
  backTarget?: string;
  children?: React.ReactNode;
  crumb?: string;
  state?: boolean;
};

interface userMenuItem {
  id: number;
  name: string;
  selected: boolean;
  key: string;
  modal?: string;
  url?: string;
}

interface languageMenuItem {
  id: number;
  name: string;
  selected: boolean;
  key: string;
  icon: string;
  status: string;
}

const userMenuItems: userMenuItem[] = [
  {
    id: 1,
    name: 'Your profile',
    selected: false,
    key: 'userMenu',
    modal: 'YourProfile',
  },
  // { id: 2, name: 'Your subscriptions', selected: false, key: 'userMenu', modal: 'YourSubscriptions' },
  // { id: 3, name: 'Admin console', selected: false, key: 'userMenu', url: '/Admin' },
];

const languageMenuItems: languageMenuItem[] = [
  {
    id: 1,
    name: 'English',
    selected: true,
    key: 'userMenu',
    icon: English,
    status: 'active',
  },
  {
    id: 2,
    name: 'Dutch',
    selected: false,
    key: 'userMenu',
    icon: Dutch,
    status: 'disabled',
  },
];

/** Main header used on all pages
 * Providing the back button if needed, logo, and menu button.
 * Shows the Login button instead of the menu if not authenticated.
 */
export const ORYXHeader = ({ loading, progress, backTarget, crumb, state }: HeaderProps) => {
  const history = useHistory();
  const user = useAuthContext().state.userState?.user;
  const role = useAuthContext().state.userState?.role;
  const { signOut } = useAuthContext();
  const userInfo = useUserInfoContext().userInfo;
  const addReportType = state ? { type: 'report' } : null;
  const goBack = (backTarget: string) => {
    history.push(backTarget, addReportType);
  };

  const [showUserMenu, setShowUserMenu] = useState<{ showUserMenu: boolean; event: Event | undefined }>({
    showUserMenu: false,
    event: undefined,
  });
  const [showLanguageMenu, setShowLanguageMenu] = useState({
    showLanguageMenu: false,
    event: undefined,
  });

  const [showUserInfo, setShowUserInfo] = useState('');

  // debug stuff
  const [debug1, setDebug1] = useState<MagneticFieldData>({ magneticFieldAccuracy: 0, magneticFieldValue: 0 });

  return (
    <>
      {/* Used for userMenu dropdown.... */}
      <IonPopover
        className=''
        event={showUserMenu.event}
        isOpen={showUserMenu.showUserMenu}
        onDidDismiss={() => setShowUserMenu({ showUserMenu: false, event: undefined })}
        mode='ios'
        side='bottom'
        alignment='center'
      >
        <IonList className='userMenu_List' lines='full'>
          {userMenuItems.map((userMenuItem, id) => {
            if ('modal' in userMenuItem) {
              return (
                <IonItem
                  key={userMenuItem.id}
                  onClick={() => {
                    setShowUserInfo(userMenuItem.modal!);
                    setShowUserMenu({ showUserMenu: false, event: undefined });
                  }}
                  button
                  routerDirection='none'
                  detail={false}
                >
                  <IonLabel className='TextAlign'>{userMenuItem.name}</IonLabel>
                </IonItem>
              );
            } else {
              return (
                <IonItem
                  key={userMenuItem.id}
                  onClick={() => {
                    setShowUserMenu({ showUserMenu: false, event: undefined });
                  }}
                  button
                  routerLink={userMenuItem.url}
                  routerDirection='none'
                  detail={false}
                >
                  <IonLabel className='TextAlign'>{userMenuItem.name}</IonLabel>
                </IonItem>
              );
            }
          })}
          {/* {role == 'ORYX_Admin' && (
            <IonItem button routerLink='/ORYX_Admin' routerDirection='forward' detail={false}>
              <IonLabel className='TextAlign'>ORYX Admin</IonLabel>
            </IonItem>
          )} */}
          <IonItem
            button
            onClick={() => {
              signOut();
              history.push('/');
            }}
            detail={false}
          >
            <IonLabel className='TextAlign'>Sign out</IonLabel>
          </IonItem>

          {/*
          ====================================================================
          TEST BUTTON FOR DEVELOPMENT ONLY
          ====================================================================
          */}
          {/* <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.startMagneticFieldMeasuring();
              await NativeSensorControl.on('magneticFieldData', (data: MagneticFieldData) => {
                setDebug1(data);
              });

            }}
            detail={false}>
            <IonLabel className='TextAlign'>Test button</IonLabel>
          </IonItem>

          <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.stopMagneticFieldMeasuring();
              await NativeSensorControl.removeAllListeners();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Test button 2</IonLabel>
          </IonItem> */}

          {/* <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.on('discover', (discoveredSensor) => {
                if (import.meta.env.MODE !== 'production') console.log(discoveredSensor.macAddress);
              });

              await NativeSensorControl.startScanning();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Startscan</IonLabel>
          </IonItem>

          <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.stopScanning();
              await NativeSensorControl.removeAllListeners();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Stopscan</IonLabel>
          </IonItem>

          <IonItem detail={false}>
            <IonLabel className='TextAlign'>{`${debug1.magneticFieldAccuracy} / ${debug1.magneticFieldValue}`}</IonLabel>
          </IonItem> */}

          {/* <IonItem
            button
            onClick={async () => {
              updateArrayCache('testrecording2.csv', 'saved_recordings');
            }}
            detail={false}>
            <IonLabel className='TextAlign'>testbtn 3</IonLabel>
          </IonItem>

          <IonItem detail={false}>
            <IonLabel className='TextAlign'>{}</IonLabel>
          </IonItem> */}
        </IonList>
      </IonPopover>

      {/* Used for languageMenu dropdown.... */}
      <IonPopover
        event={showLanguageMenu.event}
        isOpen={showLanguageMenu.showLanguageMenu}
        onDidDismiss={() => setShowLanguageMenu({ showLanguageMenu: false, event: undefined })}
        mode='ios'
        side='bottom'
        alignment='center'
      >
        <IonList className='languageMenu_List'>
          {languageMenuItems.map((languageMenuItem, id) => {
            let menuClassNames = '';
            if (languageMenuItem.status === 'disabled') {
              menuClassNames = 'disabled';
            } else if (languageMenuItem.selected === true) {
              menuClassNames = 'selected';
            }
            return (
              <IonItem key={languageMenuItem.id} detail={false} className={menuClassNames}>
                <IonIcon src={languageMenuItem.icon} />
                <IonLabel className='TextAlign'>{languageMenuItem.name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonPopover>

      {/* Ion Modal to show Your Profile */}
      <IonModal isOpen={showUserInfo !== ''} className='userInfo-modal' onDidDismiss={() => setShowUserInfo('')}>
        {showUserInfo == 'YourProfile' && (
          <>
            <YourProfile user={user!} Info={userInfo} />
            <IonButton
              slot='icon-only'
              onClick={() => setShowUserInfo('')}
              fill='clear'
              className='userInfo-modal-close'
            >
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </>
        )}
        {showUserInfo == 'YourSubscriptions' && (
          <>
            <YourSubscriptions user={user!} userInfo={userInfo} />
            <IonButton
              slot='icon-only'
              onClick={() => setShowUserInfo('')}
              fill='clear'
              className='userInfo-modal-close'
            >
              <IonIcon icon={closeCircleOutline} />
            </IonButton>

            <IonButton onClick={() => ''} fill='clear' className='go-to-billing-button' size='small'>
              <IonIcon icon={chevronForwardOutline} slot='end' className='button_Dropdown_Icon' />
              Go to billing
            </IonButton>
          </>
        )}
      </IonModal>

      <IonHeader mode='md'>
        <IonToolbar>
          {backTarget && (
            <IonButtons slot='start'>
              <IonButton shape='round' onClick={() => goBack(backTarget)}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
          )}
          {crumb && (
            <IonBreadcrumb>
              <small>{crumb}</small>
              <IonIcon slot='start' icon={arrowForwardCircle} size='small' />
            </IonBreadcrumb>
          )}
          {/* <IonSearchbar
            className='searchBar'
            value={searchText}
            onIonChange={(e) => onSearch(e)}
            showCancelButton='focus'
            animated
            disabled
            placeholder='Search for tags or filenames'></IonSearchbar> */}

          <IonButtons
            slot='primary'
            className='laguage_Buttons'
            onClick={(e: any) => {
              e.persist();
              setShowLanguageMenu({ showLanguageMenu: true, event: e.nativeEvent });
            }}
          >
            <IonButton>
              <IonIcon icon={languageOutline} className='language_Buttons_Icon_Language' />
            </IonButton>
            <IonButton className='second_Language_Button'>
              <IonIcon icon={chevronDownOutline} className='button_Dropdown_Icon' />
            </IonButton>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton
              className='user_Button'
              onClick={(e: any) => {
                e.persist();

                setShowUserMenu({ showUserMenu: true, event: e.nativeEvent });
              }}
            >
              {userInfo.firstName} {userInfo.lastName}
              <IonIcon slot='end' icon={chevronDownOutline} className='button_Dropdown_Icon' />
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {loading &&
          (progress !== undefined ? (
            <IonProgressBar value={progress}></IonProgressBar>
          ) : (
            <IonProgressBar type='indeterminate'></IonProgressBar>
          ))}
      </IonHeader>
    </>
  );
};
