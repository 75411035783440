import { GraphSeriesData, LegendEntry } from '../../../model/reportV2.model';
import { uuid } from '../../../utilities';

/**
 * Creates a variability legend based on the provided data.
 *
 * @param crpData - The data for the main chart.
 * @param coordinativeVariabilityData_Left - The data for the left coordinative variability chart.
 * @param coordinativeVariabilityData_Right - The data for the right coordinative variability chart.
 * @returns An array of legend entries.
 */
export function createVariabilityLegend(
  crpData: ApexAxisChartSeries | ApexNonAxisChartSeries | GraphSeriesData[][],
  coordinativeVariabilityData_Left: ApexAxisChartSeries | ApexNonAxisChartSeries | GraphSeriesData[][],
  coordinativeVariabilityData_Right: ApexAxisChartSeries | ApexNonAxisChartSeries | GraphSeriesData[][],
): LegendEntry[] {
  const crpLegend = crpData.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
    };
  });

  const coordinativeVariabilityLegend_Left = coordinativeVariabilityData_Left.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
      side: 'Left',
    };
  });

  const coordinativeVariabilityLegend_Right = coordinativeVariabilityData_Right.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
      side: 'Right',
    };
  });

  return [...crpLegend, ...coordinativeVariabilityLegend_Left, ...coordinativeVariabilityLegend_Right];
}
