import React from 'react';

import { View, StyleSheet, Image, Text } from '@react-pdf/renderer';
import logo from '../../../../../assets/oryx-logo.png';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderBottomColor: '#E2E2E2',
    // borderBottomStyle: 'solid',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
  },
  logo: {
    maxWidth: '100px',
    maxHeight: 38,
    padding: 2,
  },
  nameColumn: {
    paddingLeft: 10,
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: '#767676',
  },
});

interface HeaderProps {
  reportName: string;
  logoURL: string;
}

export const Header = ({ reportName, logoURL }: HeaderProps) => {
  const imgURL = new URL(`../headerLogos/${logoURL}`, import.meta.url).href;

  return (
    <View style={styles.container}>
      <Text style={styles.nameColumn}> {reportName}</Text>
      <Image src={imgURL} style={styles.logo} />
    </View>
  );
};
