import React from 'react';
import { Page, View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { Header } from './PDFHeader';
import logo from '../headerLogos/c7be044f-5e4d-48ea-a1ef-d888decb5988.png';

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '10px',
    width: '100%',
  },
  logo: {
    width: '75px',
    padding: 10,
  },
  nameColumn: {
    paddingLeft: 10,
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: '#767676',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#767676',
    fontFamily: 'Montserrat',
  },
  logoContainer: {
    width: '98%',
    marginHorizontal: '12px',
    position: 'absolute',
    borderTop: 1,
    borderTopColor: '#E2E2E2',
    borderTopStyle: 'solid',
    right: -15,
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export interface PDFPageProps {
  reportName: string;
  logoUrl: string;
  PageNumber: number;
  children?: React.ReactNode;
}

export const PDFPage = ({ reportName, logoUrl, PageNumber, children }: PDFPageProps) => {
  return (
    <>
      <Page size='A4' style={{ padding: '10px' }}>
        <Header reportName={reportName} logoURL={logoUrl} />
        {children}
        {/* Footer component */}
        <View style={styles.footerContainer}>
          {logoUrl != 'c7be044f-5e4d-48ea-a1ef-d888decb5988.png' && (
            <View style={styles.logoContainer}>
              <Text style={styles.nameColumn}>Powered by: </Text>
              <Image src={logo} style={styles.logo} />
            </View>
          )}
          <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${PageNumber} -`} fixed />
        </View>
      </Page>
    </>
  );
};
