import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonListHeader,
  IonTitle,
  IonCard,
} from '@ionic/react';
import './index.css';
import { User, UserInfo } from '../../model';
import LogoRunner from '../../assets/icons/LogoRunner.svg';
import LogoTraining from '../../assets/icons/LogoTraining.svg';
import LogoLearning from '../../assets/icons/LogoLearning.svg';

interface YourSubscriptionsProps {
  user: User;
  userInfo: UserInfo;
}

type SubscriptionCardProps = {
  className: string;
  img: string;
  title: string;
  licenseType: string;
  paymentType: string;
};

const SubscriptionCard = ({ className, img, title, licenseType, paymentType }: SubscriptionCardProps) => {
  return (
    <IonCard className={className}>
      <IonGrid>
        <IonRow>
          <IonCol size='3' className='ion-align-items-center'>
            <IonImg src={img} alt='' className='subscription_card_img' />
          </IonCol>
          <IonCol size='9'>
            <h1 className='subscription_card_title'>{title}</h1>
            <p className='subscription_card_text'>
              {licenseType} | {paymentType} payment
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

const SubscriptionCard_dark_text = ({ className, img, title, licenseType, paymentType }: SubscriptionCardProps) => {
  return (
    <IonCard className={className}>
      <IonGrid>
        <IonRow>
          <IonCol size='3' className='ion-align-items-center'>
            <IonImg src={img} alt='' className='subscription_card_img' />
          </IonCol>
          <IonCol size='9'>
            <h1 className='subscription_card_title_dark'>{title}</h1>
            <p className='subscription_card_text_dark'>
              {licenseType} | {paymentType} payment
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export const YourSubscriptions = ({ user, userInfo }: YourSubscriptionsProps) => {
  return (
    <>
      <IonHeader class='ion-no-border'>
        <IonTitle className='userInfo-modal-header'>Your Subscriptions</IonTitle>
      </IonHeader>
      <IonContent className='userInfo-modal-content' scrollEvents={false} scrollY={false}>
        <IonGrid fixed>
          <IonRow className='ion-align-items-center ion-justify-content-center yourSubscriptions-modal-row'>
            <IonCol size='12'>
              <p className='yourSubscriptions_text'>
                These are the licenses currently assigned to you and your unique ID. If you need help accessing a
                product or service, this information may be helpful for your admin or ORYX support.{' '}
              </p>
              <IonList lines='none' mode='ios'>
                <IonListHeader mode='ios'>User Unique Identifier:</IonListHeader>
                <IonItem className='yourSubscriptions-modal-listItem'>{user.uid}</IonItem>
              </IonList>
              {Object.entries(userInfo.organization.licenses).map(([documentID, license]) => {
                if (documentID == 'ORYX GO analysis Tool') {
                  return (
                    <SubscriptionCard
                      key={documentID}
                      className={'ORYX_analysis_subscription'}
                      img={LogoRunner}
                      title={documentID}
                      licenseType={license.licenseType}
                      paymentType={license.paymentType}
                    />
                  );
                } else if (documentID == 'Training Builder Tool') {
                  return (
                    <SubscriptionCard_dark_text
                      key={documentID}
                      className={'Training_Builder_subscription'}
                      img={LogoTraining}
                      title={documentID}
                      licenseType={license.licenseType}
                      paymentType={license.paymentType}
                    />
                  );
                } else if (documentID == 'coaching') {
                  return (
                    <SubscriptionCard
                      key={documentID}
                      className={'Coaching_subscription'}
                      img={LogoLearning}
                      title={documentID}
                      licenseType={license.licenseType}
                      paymentType={license.paymentType}
                    />
                  );
                }
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};
