import { JointCouplingSeriesData, StridesData } from '../../../model/reportV2.model';

/**
 * Function to combine the data of two different joints/segments where the first parameter
 * becomes the yValue, and the second parameter becomes the xvalue for each strides. Returns an object in the form:
 * {
      type: string;
      name: string;
      data: {
          x: number;
          y: number;
      }[];
    }[]
 */
export function toJointCouplingData(kneeAngles: StridesData, hipAngles: StridesData): JointCouplingSeriesData[] {
  const numCycles = Object.keys(kneeAngles).length;

  const graphData: { x: number; y: number }[][] = [];
  const serie: { type: string; name: string; data: { x: number; y: number }[] }[] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number }[] = [];

    kneeAngles[`col${i}`].forEach((yValue: any, index: number) => {
      const xValue = hipAngles[`col${i}`][index];
      cycleData.push({ x: xValue, y: yValue });
    });
    serie.push({ type: 'line', name: `stride ${i + 1}`, data: cycleData });
  }

  return serie;
}
