/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 */
import { IonPage, IonContent } from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../../components/menuItems/Header';
import './index.css';

export const AdminPage = () => {
  return (
    <IonPage>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light'>
        This will become the admin page
      </IonContent>
    </IonPage>
  );
};
