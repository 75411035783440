import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from '.';

interface Props {
  path: string;
  children: React.ReactNode;
}

export const GuestRoute = ({ children, ...rest }: Props) => {
  const { isAuthorized } = useAuthContext();

  return (
    <Route
      {...rest}
      render={() =>
        isAuthorized() ? (
          <Redirect
            to={{
              pathname: '',
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};
