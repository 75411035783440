import { createAllStridesSeriesData, createKinematicYAxis } from '.';
import { Joint, GraphSeriesData, KinematicYAxis } from '../../../model/reportV2.model';

/**
 * Retrieves kinematic data for all strides of a specific joint in different planes.
 *
 * @param data - The data object containing the kinematic data.
 * @param joint - The joint for which to retrieve the kinematic data ('Pelvis', 'Hip', 'Knee', or 'Ankle').
 * @returns An object containing kinematic series data for each plane.
 */
export function getAllStridesKinematicData(
  data: any,
  joint: Joint,
): { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } {
  const planes = ['Sagittal', 'Coronal', 'Transversal'];
  const result: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } = {};

  planes.forEach((plane) => {
    const dataKey = plane === 'Sagittal' ? 'strides' : plane === 'Coronal' ? 'stridesCoronal' : 'stridesTransversal';
    const jointKey = plane === 'Sagittal' ? '' : `_${plane}`;

    const seriesData = createAllStridesSeriesData(
      data[`${dataKey}Left`][`left${joint}${jointKey}`],
      data[`${dataKey}Right`][`right${joint}${jointKey}`],
    );
    const yAxisData = createKinematicYAxis(seriesData);

    result[plane] = {
      series: seriesData,
      yAxis: yAxisData,
    };
  });

  return result;
}
