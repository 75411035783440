/**
 * Returns the label at the specified index from the given array of labels.
 *
 * @param labels - The array of labels.
 * @param kpi - The index of the label to retrieve.
 * @returns The label at the specified index.
 */
export function createLabel(labels: string[], kpi: number): string {
  return labels[kpi - 1];
}
