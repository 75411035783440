import { StyleSheet } from '@react-pdf/renderer';
import { succesColor, warningColor, dangerColor } from '../../../../theme/colors';

export const PDFLabelStyles = StyleSheet.create({
  succesLabel: {
    backgroundColor: succesColor,
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    // paddingHorizontal: '10px',
    marginRight: '2px',
    color: '#ffffff',
    fontSize: 10,
    fontFamily: 'Montserrat',
    textAlign: 'center',
    marginVertical: 'auto',
  },
  warningLabel: {
    backgroundColor: warningColor,
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    // paddingHorizontal: '5px',
    marginRight: '2px',
    color: '#ffffff',
    fontSize: 10,
    fontFamily: 'Montserrat',
    textAlign: 'center',
    marginVertical: 'auto',
  },
  dangerLabel: {
    backgroundColor: dangerColor,
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    // paddingHorizontal: '10px',
    marginRight: '2px',
    color: '#ffffff',
    fontSize: 10,
    fontFamily: 'Montserrat',
    textAlign: 'center',
    marginVertical: 'auto',
  },
});
