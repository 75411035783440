/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import './Login.css';
import login_bg from '../../assets/login/login_bg.png';
import login_image from '../../assets/login/login_image.png';
import { LoginForm } from '../../components/forms/loginForm';
import { useAuthContext } from '../../components/authContext';
import { ResetPasswordForm } from '../../components/forms/ResetPasswordForm';
import { ErrorToast } from '../../components/ErrorToast';
import { NewPassword } from '../../components/forms/newPassword';

export const LoginPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className='login'>
        <IonGrid fixed={true}>
          <IonRow class='ion-align-items-center'>
            <IonCol size='6' className='login-col_left ion-hide-md-down'>
              <IonImg src={login_bg} className='intro_bg'></IonImg>
              <IonImg src={login_image} className='intro_image'></IonImg>
            </IonCol>
            <IonCol>
              <LoginForm />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export const ResetPasswordPage: React.FC = () => {
  const { state, clearError } = useAuthContext();

  return (
    <IonPage>
      <IonContent className='login ion-no-padding' id='main'>
        <IonGrid fixed className='ion-no-padding ion-no-margin'>
          <IonRow>
            <IonCol size='6' className='login-col_left ion-hide-md-down'>
              <IonImg src={login_bg} className='intro_bg'></IonImg>
              <IonImg src={login_image} className='intro_image'></IonImg>
            </IonCol>

            <IonCol size-md='6' size-sm='12' className='login-col'>
              <ResetPasswordForm />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <ErrorToast message={state.error} clearError={clearError} />
    </IonPage>
  );
};

export const NewPasswordPage: React.FC = () => {
  const { state, clearError } = useAuthContext();

  return (
    <IonPage>
      <IonContent className='login ion-no-padding' id='main'>
        <IonGrid fixed className='ion-no-padding ion-no-margin'>
          <IonRow>
            <IonCol size='6' className='login-col_left ion-hide-md-down'>
              <IonImg src={login_bg} className='intro_bg'></IonImg>
              <IonImg src={login_image} className='intro_image'></IonImg>
            </IonCol>

            <IonCol size-md='6' size-sm='12' className='login-col'>
              <NewPassword />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <ErrorToast message={state.error} clearError={clearError} />
    </IonPage>
  );
};
