import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
// import { ReportStatus } from '../../../../model';
import { Report, ReportInfo, ReportType, UserInfo } from '../../../model';
import { PDFReportCard } from './Components/PDFReportCard';
import { PDFRunReport } from './PDFRunReport';

//Fonts
import MontserratMediumItalic from '../../../assets/fonts/MontserratMediumItalic.ttf';
import MontserratMedium from '../../../assets/fonts/MontserratMedium.ttf';
import MontserratBold from '../../../assets/fonts/MontserratBold.ttf';
import { PDFWalkReport } from './PDFWalkReport';
import { PDFSquatReport } from './PDFSquatReport';
import { PDFSLSquatReport } from './PDFSLSquatReport';

export type PDFVersion = 'Specialist' | 'Client';

export const styles = StyleSheet.create({
  frontLogo: { width: '400px', marginHorizontal: 100, marginVertical: 100 },
  ReportCard: { bottom: 50, position: 'absolute' },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#767676',
  },
  versionNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#767676',
    opacity: 0.5,
  },
});

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: MontserratMedium }, // Regular
    { src: MontserratBold, fontWeight: 'bold' }, // Bold
    {
      src: MontserratMediumItalic,
      fontStyle: 'italic',
    }, // Italic
  ],
});

Font.register({
  family: 'Roboto',
  src: `https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf`,
});
Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});
Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});
Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export interface DocumentProps {
  reportInfo: ReportInfo;
  report?: Report;
  data?: any;
  version: PDFVersion;
  // reportDict: ReportInfoDict;
}
//eslint-disable-next-line
export const PDFDocument: React.FC<DocumentProps> = ({ reportInfo, data, version }) => {
  const imgURL = new URL(`./clientLogos/${data.logoURL}`, import.meta.url).href;

  const walkReport = reportInfo.reportType === ReportType.WALK;
  const runReport = reportInfo.reportType === ReportType.RUN;
  const squatReport = reportInfo.reportType === ReportType.SQUAT;
  const singleLegSquatReport = reportInfo.reportType === ReportType.SLSQUAT;

  return (
    <Document>
      <Page size='A4'>
        <View>
          <Image style={styles.frontLogo} src={imgURL} />
        </View>
        <View style={styles.ReportCard}>
          <PDFReportCard reportInfo={reportInfo} />
        </View>
        <Text style={styles.versionNumber}>Versie 1</Text>
      </Page>
      {walkReport && <PDFWalkReport reportInfo={reportInfo} data={data} version={version} />}
      {runReport && <PDFRunReport reportInfo={reportInfo} data={data} version={version} />}
      {squatReport && <PDFSquatReport reportInfo={reportInfo} data={data} version={version} />}
      {singleLegSquatReport && <PDFSLSquatReport reportInfo={reportInfo} data={data} version={version} />}
      {/* <Page size='A4'>
        <Header />
        <PDFMobilityStatus report={report} />
        <WalkExplainerCards report={report} />
        <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${pageNumber - 1} -`} fixed />
      </Page> */}
    </Document>
  );

  // if (data?.reportType === 1) {
  //   return (
  //     <Document>
  //       <Page size='A4'>
  //         <View>
  //           <Image style={styles.frontLogo} src={logo} />
  //         </View>
  //         <View style={styles.ReportCard}>
  //           <PDFReportCard report={report} reportInfo={reportInfo} />
  //         </View>
  //         <Text style={styles.versionNumber}>Versie 1</Text>
  //       </Page>
  //       <Page size='A4'>
  //         <Header />
  //         <PDFMobilityStatus report={report} />
  //         <WalkAnalysisCards report={report} />
  //         <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${pageNumber - 1} -`} fixed />
  //       </Page>
  //     </Document>
  //   );
  // } else
  //   return (
  //     <Document>
  //       <Page size='A4'>
  //         <View>
  //           <Image style={styles.frontLogo} src={logo} />
  //         </View>
  //         <View style={styles.ReportCard}>
  //           <PDFReportCard report={report} reportInfo={reportInfo} />
  //         </View>
  //         <Text style={styles.versionNumber}>Versie 1</Text>
  //       </Page>
  //       <Page size='A4'>
  //         <Header />
  //         <RunAnalysisCards report={report} />
  //         <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${pageNumber - 1} -`} fixed />
  //       </Page>
  //     </Document>
  //   );
};
