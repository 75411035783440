import { AttractorLabels, Attractors } from '../../../model/reportV2.model';

export function defineAttractorLabels(data: Attractors, labels: AttractorLabels): AttractorLabels {
  return data.col0.map((_value: number, rowIdx: number) => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        const labelIdx = data[colId][rowIdx] - 1;
        return labels[rowIdx][labelIdx];
      });
  });
}
