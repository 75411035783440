import { DataPoint } from '../../../model/reportV2.model';

/**
 * Converts the provided data into an array of DataPoint arrays for the scissors graph.
 *
 * @param data - The data to be converted.
 * @returns An array of DataPoint arrays representing the graph data.
 */
export function toScissorsData_V2(data: any): DataPoint[][] {
  if (!data) {
    return [];
  }
  const numCycles = Object.keys(data).length;
  const graphData: { x: number; y: number }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number }[] = [];

    cycleData.push({ x: data[i][0], y: data[i][1] });

    graphData.push(cycleData);
  }

  return graphData;
}
