/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import ankleStiffness from '../../../assets/avatars/run/analysis/AnkleStiffness.png';
import footPlant from '../../../assets/avatars/run/analysis/FootPlant.png';
import hipLock from '../../../assets/avatars/run/analysis/HipLock.png';
import kneeAngleMidswing from '../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import kneeExtensionPreswing from '../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import kneeFlexionMidstance from '../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';
import positiveRunningToeOff from '../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import projectionFootplant from '../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import scissors from '../../../assets/avatars/run/analysis/Scissors.png';
import swingLegRecovery from '../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import swingLegRetraction from '../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import { AttractorCard } from '../components/Cards';
import { RunAttractorData } from '../../../model/reportV2.model';

type AttractorsReportProps = RouteComponentProps & {
  tabData: RunAttractorData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunAttractorsReportTab = ({ tabData }: AttractorsReportProps) => {
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          {tabData && (
            <IonGrid fixed>
              <IonRow className='ion-no-padding'>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Ankle stiffness'
                    subtitle='Terminal stance'
                    info={'Ankle Stiffness'}
                    img={ankleStiffness}
                    labels={tabData.attractorLabels[1]}
                    attractorRow={tabData.attractorData[1]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Knee extension'
                    subtitle='Terminal stance'
                    info={'Knee extension at Terminal stance'}
                    img={kneeExtensionPreswing}
                    labels={tabData.attractorLabels[2]}
                    attractorRow={tabData.attractorData[2]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Hip lock'
                    subtitle='Terminal stance'
                    info={'Hip lock'}
                    img={hipLock}
                    labels={tabData.attractorLabels[3]}
                    attractorRow={tabData.attractorData[3]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Positive running'
                    subtitle='Toe-off'
                    info={'Positive running at toe-off'}
                    img={positiveRunningToeOff}
                    labels={tabData.attractorLabels[0]}
                    attractorRow={tabData.attractorData[0]}
                    type='Run'
                  />
                </IonCol>
              </IonRow>

              <IonRow className='ion-no-padding'>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Swing leg recovery'
                    subtitle='Initial swing'
                    info={'Swing leg recovery'}
                    img={swingLegRecovery}
                    labels={tabData.attractorLabels[5]}
                    attractorRow={tabData.attractorData[5]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Knee flexion'
                    subtitle='Midswing'
                    info={'Knee flexion at midswing'}
                    img={kneeAngleMidswing}
                    labels={tabData.attractorLabels[6]}
                    attractorRow={tabData.attractorData[6]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Swing leg retraction'
                    subtitle='Terminal Swing'
                    info={'Swing leg retraction'}
                    img={swingLegRetraction}
                    labels={tabData.attractorLabels[9]}
                    attractorRow={tabData.attractorData[9]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Scissors motion'
                    subtitle='Terminal swing'
                    info={'Scissors'}
                    img={scissors}
                    labels={tabData.attractorLabels[10]}
                    attractorRow={tabData.attractorData[10]}
                    type='Run'
                  />
                </IonCol>
              </IonRow>

              <IonRow className='ion-no-padding'>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Footplant from above'
                    subtitle='Midstance'
                    info={'Foot plant from above'}
                    img={footPlant}
                    labels={tabData.attractorLabels[11]}
                    attractorRow={tabData.attractorData[11]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Projection footplant'
                    subtitle='Midstance'
                    info={'Projection footplant'}
                    img={projectionFootplant}
                    labels={tabData.attractorLabels[8]}
                    attractorRow={tabData.attractorData[8]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Knee flexion'
                    subtitle='Midstance'
                    info={'Knee flexion at midstance'}
                    img={kneeFlexionMidstance}
                    labels={tabData.attractorLabels[14]}
                    attractorRow={tabData.attractorData[14]}
                    type='Run'
                  />
                </IonCol>
                <IonCol size-sm='12' size-md='6' size-lg='3'>
                  <AttractorCard
                    title='Positive running'
                    subtitle='Midstance'
                    info={'Positive running at midstance'}
                    img={positiveRunningMidstance}
                    labels={tabData.attractorLabels[7]}
                    attractorRow={tabData.attractorData[7]}
                    type='Run'
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    );
  }
};
