/** Squat analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonThumbnail,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import doubleLegSquatAvatar from '../../../assets/avatars/squat/doubleLegSquatAvatar.png';
import pelvisSagittalMovement from '../../../assets/icons/kinematicsIcons/pelvisSagittalMovement.png';
import hipSagittalMovement from '../../../assets/icons/kinematicsIcons/hipSagittalMovement.png';
import hipTransversalMovement from '../../../assets/icons/kinematicsIcons/hipTransversalMovement.png';
import kneeSagittalMovement from '../../../assets/icons/kinematicsIcons/kneeSagittalMovement.png';
import kneeCoronalMovement from '../../../assets/icons/kinematicsIcons/kneeCoronalMovement.png';
import ankleSagittalMovement from '../../../assets/icons/kinematicsIcons/ankleSagittalMovement.png';
import './SquatsAnalysisReportTab.css';
import { StatusDot_Centered } from '../components/Fields';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { SquatAnalysisData } from '../../../model/reportV2.model';

type SquatReportProps = RouteComponentProps & {
  tabData: SquatAnalysisData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SquatAnalysisReportTab = ({ tabData }: SquatReportProps) => {
  const stdRepTime = tabData.repetitionData.col1[0].toFixed(2);

  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('');
  const [tableSize, setTableSize] = useState('');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
        setTableSize('ORYX_Table_Large');
      } else {
        setWindowSize('small');
        setTableSize('ORYX_Table_Small');
      }
    }
  }, [width]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid className=''>
            <IonRow className='ion-align-items-center ion-justify-content-center remaining_height'>
              <IonCard className='ORYX_DashboardCard'>
                <IonRow className='border_Bottom ion-padding-vertical'>
                  Rep Time:&nbsp; <b>{tabData.repetitionData.col0[0].toFixed(2)}</b>&nbsp;(± {stdRepTime}) sec.
                </IonRow>
                <IonCardContent className='content-container'>
                  <IonRow className='ion-align-items-center ion-justify-content-center'>
                    <IonCol size='5' className=''>
                      <IonImg
                        src={doubleLegSquatAvatar}
                        class='SquatsAnalysisReportTab_Avatar'
                        style={{ width: windowSize === 'large' ? '50%' : '50%' }}
                      />
                    </IonCol>
                    <IonCol size='7' className=''>
                      {windowSize !== '' && (
                        <table className={tableSize}>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td>
                                {tabData.romData[0][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[0][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[0][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[0][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[0][4].toFixed(0) + '%'}</td>
                              <td>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Pelvis Sagittal Movement' src={pelvisSagittalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td>
                                {tabData.romData[1][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[1][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[1][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[1][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[1][4].toFixed(0) + '%'}</td>
                              <td>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Hip Sagittal Movement' src={hipSagittalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>External rotation</td>
                              <td>
                                <StatusDot_Centered status={tabData.qualityData[2][0]} />
                              </td>
                              <td>
                                <StatusDot_Centered status={tabData.qualityData[2][1]} />
                              </td>
                              <td className='No_Border_Bottom'></td>
                              <td rowSpan={2}>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Hip Transversal Movement' src={hipTransversalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Internal rotation</td>
                              <td className='ion-text-center'>
                                <StatusDot_Centered status={tabData.qualityData[3][0]} />
                              </td>
                              <td>
                                <StatusDot_Centered status={tabData.qualityData[3][1]} />
                              </td>
                              <td className='No_Border_Top'></td>
                            </tr>

                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td>
                                {tabData.romData[2][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[2][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[2][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[2][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[2][4].toFixed(0) + '%'}</td>
                              <td>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Knee Sagittal Movement' src={kneeSagittalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent No_Border_Bottom'>Varus / Valgus</td>
                              <td className='No_Border_Bottom'>
                                <StatusDot_Centered status={tabData.qualityData[4][0]} />
                              </td>
                              <td className='No_Border_Bottom'>
                                <StatusDot_Centered status={tabData.qualityData[4][1]} />
                              </td>
                              <td className='No_Border_Bottom'></td>
                              <td rowSpan={2}>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Knee Coronal Movement' src={kneeCoronalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent No_Border_Top'></td>
                              <td className='No_Border_Top'>{tabData.varusValgusLabel_Left}</td>
                              <td className='No_Border_Top'>{tabData.varusValgusLabel_Right}</td>

                              <td className='No_Border_Top'></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td>
                                {tabData.romData[3][0].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {tabData.romData[3][2].toFixed(1) + '°'}
                                <br /> ± {tabData.romData[3][3].toFixed(1) + '°'}
                              </td>
                              <td>{tabData.romData[3][4].toFixed(0) + '%'}</td>
                              <td rowSpan={2}>
                                <IonThumbnail
                                  className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}
                                >
                                  <img alt='Ankle Sagittal Movement' src={ankleSagittalMovement} />
                                </IonThumbnail>
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Early heel raise</td>
                              <td>
                                <StatusDot_Centered status={tabData.qualityData[0][0]} />
                              </td>
                              <td>
                                <StatusDot_Centered status={tabData.qualityData[0][1]} />
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
