import React from 'react';

import { View } from '@react-pdf/renderer';
import { Table, TableCell, TableRow } from './PDFTableComponents';

interface PDFRunInfoTableProps {
  runningStatusData: any;
  romData: any;
}

export const PDFRunInfoTable = ({ runningStatusData, romData }: PDFRunInfoTableProps) => {
  return (
    <View>
      <Table>
        <TableRow borderBottom>
          <TableCell borderRight rowHeader>
            Stride duration
          </TableCell>
          <TableCell>{runningStatusData[0][0].toFixed(2) + ' s'}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell borderRight rowHeader>
            Cadence
          </TableCell>
          <TableCell>{(runningStatusData[2][0] / 2).toFixed(0) + ' strides/min'}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </Table>

      <Table>
        <TableRow borderBottom>
          <TableCell borderRight />
          <TableCell borderRight columnHeader>
            Left
          </TableCell>
          <TableCell borderRight columnHeader>
            Right
          </TableCell>
          <TableCell columnHeader>Symmetry</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight rowHeader>
            Ground contact time
          </TableCell>
          <TableCell borderRight>{runningStatusData[1][0].toFixed(2) + ' s'}</TableCell>
          <TableCell borderRight>{runningStatusData[1][1].toFixed(2) + ' s'}</TableCell>
          <TableCell>{runningStatusData[1][2].toFixed(2) + ' %'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight rowHeader>
            Propulsive velocity
          </TableCell>
          <TableCell borderRight>{runningStatusData[4][0].toFixed(0) + ' m/s'}</TableCell>
          <TableCell borderRight>{runningStatusData[4][1].toFixed(0) + ' m/s'}</TableCell>
          <TableCell>{runningStatusData[4][2].toFixed(2) + ' %'}</TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight rowHeader>
            Pelvis
          </TableCell>
          <TableCell borderRight>{romData[0][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[0][1].toFixed(1) + '°'}</TableCell>
          <TableCell>{romData[0][2].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight rowHeader>
            Hip
          </TableCell>
          <TableCell borderRight>{romData[1][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[1][1].toFixed(1) + '°'}</TableCell>
          <TableCell>{romData[1][2].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Flexion
          </TableCell>
          <TableCell borderRight>{romData[2][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[2][1].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Extension
          </TableCell>
          <TableCell borderRight>{romData[3][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[3][0].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight rowHeader>
            Knee
          </TableCell>
          <TableCell borderRight>{romData[4][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[4][1].toFixed(1) + '°'}</TableCell>
          <TableCell>{romData[4][2].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Stance flexion
          </TableCell>
          <TableCell borderRight>{romData[5][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[5][1].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Varus / Valgus
          </TableCell>
          <TableCell borderRight>{romData[6][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[6][1].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow backgroundColor={'#f2f2f2'} borderBottom>
          <TableCell borderRight rowHeader>
            Ankle
          </TableCell>
          <TableCell borderRight>{romData[7][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[7][1].toFixed(1) + '°'}</TableCell>
          <TableCell>{romData[7][2].toFixed(0) + '%'}</TableCell>
        </TableRow>
        <TableRow borderBottom>
          <TableCell borderRight indent>
            Dorsal flexion
          </TableCell>
          <TableCell borderRight>{romData[8][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[8][1].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell borderRight indent>
            Plantar flexion
          </TableCell>
          <TableCell borderRight>{romData[9][0].toFixed(1) + '°'}</TableCell>
          <TableCell borderRight>{romData[9][1].toFixed(1) + '°'}</TableCell>
          <TableCell />
        </TableRow>
      </Table>
    </View>
  );
};
