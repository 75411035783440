import React from 'react';
import { IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import './modalindex.css';
import IBimagePositiverunning1 from './assets/IBimagePositiverunning1.png';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';
import { ORYXModalHeader } from '../../../../components/componentHeaders';

interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}

export const ModalPositiveRunningToeoff = ({ buttons, setShowModal }: ModalProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Positive running' subtitle='Toe off' setShowInfo={cleanUp} buttons={true} />

      <Swiper
        modules={[Pagination, Mousewheel, Keyboard]}
        pagination={pagination}
        mousewheel={true}
        keyboard={true}
        initialSlide={0}
        speed={400}
        direction='vertical'
        className='mySwiper'
      >
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagePositiverunning1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  Together with multiple smaller attractors, the scissors contribute to an overall stable pattern called{' '}
                  <i>&quot;Positive Running at toe off&quot;</i>.<br></br>
                  <br></br>
                  When running at speed, positive running pose is shown as the bisector of both thigh angles. The line
                  that divides the angle into two equal segments should point markedly forward (positive) if underlying
                  attractors are performed well. For each runner there is an ideal direction for this angle.
                  <br></br>
                  <br></br>
                  If technique changes due to fatigue or physical issues, the direction of the bisector will point more
                  downwards (less positive) resulting in decrease in performance.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
