import React from 'react';

import { View, Text, Image } from '@react-pdf/renderer';
import { AttractorField } from './PDFFields';
import { PDFFormatting, PDFTextStyles } from '../styles';
import ankleStiffness from '../../../../assets/avatars/run/analysis/AnkleStiffness.png';
import kneeExtensionPreswing from '../../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import hipLock from '../../../../assets/avatars/run/analysis/HipLock.png';
import positiveRunningToeOff from '../../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import swingLegRecovery from '../../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import kneeAngleMidswing from '../../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import swingLegRetraction from '../../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import scissors from '../../../../assets/avatars/run/analysis/Scissors.png';
import footPlant from '../../../../assets/avatars/run/analysis/FootPlant.png';
import projectionFootplant from '../../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import kneeFlexionMidstance from '../../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';

type AttractorLabels = string[][];

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  img: string;
  labels: string[];
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const AttractorCard = ({ title, subtitle, img, labels }: AttractorCardProps) => (
  <View style={PDFFormatting.attractorCardContainer} debug={false}>
    <Text style={PDFTextStyles.title}>{title}</Text>
    <Text style={PDFTextStyles.subtitle}>{subtitle}</Text>
    <Image style={PDFFormatting.avatar_NoMargin} src={img} />
    <View style={PDFFormatting.cardResultContainer}>
      <AttractorField name={'Left'} value={labels[0]} />
      <AttractorField name={'Right'} value={labels[1]} />
    </View>
  </View>
);

type RunReportProps = {
  attractorLabels: AttractorLabels;
};

export const RunAnalysisCards = ({ attractorLabels }: RunReportProps) => {
  return (
    <>
      <View style={PDFFormatting.KpiCardscontainer}>
        <AttractorCard
          title={'Ankle stiffness'}
          subtitle={'Terminal stance'}
          img={ankleStiffness}
          labels={attractorLabels[1]}
        />
        <AttractorCard
          title={'Knee extension'}
          subtitle={'Terminal stance'}
          img={kneeExtensionPreswing}
          labels={attractorLabels[2]}
        />
        <AttractorCard title={'Hip Lock'} subtitle={'Terminal stance'} img={hipLock} labels={attractorLabels[3]} />
        <AttractorCard
          title={'Positive running'}
          subtitle={'Toe-off'}
          img={positiveRunningToeOff}
          labels={attractorLabels[0]}
        />
      </View>
      <View style={PDFFormatting.KpiCardscontainer}>
        <AttractorCard
          title={'Swing leg recovery'}
          subtitle={'Initial swing'}
          img={swingLegRecovery}
          labels={attractorLabels[5]}
        />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midswing'}
          img={kneeAngleMidswing}
          labels={attractorLabels[6]}
        />
        <AttractorCard
          title={'Swing leg retraction'}
          subtitle={'Terminal swing'}
          img={swingLegRetraction}
          labels={attractorLabels[9]}
        />
        <AttractorCard
          title={'Scissors motion'}
          subtitle={'Terminal swing'}
          img={scissors}
          labels={attractorLabels[10]}
        />
      </View>
      <View style={PDFFormatting.KpiCardscontainer}>
        <AttractorCard
          title={'Footplant from above'}
          subtitle={'Midstance'}
          img={footPlant}
          labels={attractorLabels[11]}
        />
        <AttractorCard
          title={'Projection footplant'}
          subtitle={'Midstance'}
          img={projectionFootplant}
          labels={attractorLabels[8]}
        />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midstance'}
          img={kneeFlexionMidstance}
          labels={attractorLabels[14]}
        />
        <AttractorCard
          title={'Positive running'}
          subtitle={'Midstance'}
          img={positiveRunningMidstance}
          labels={attractorLabels[7]}
        />
      </View>
    </>
  );
};
