// Load the data for the PDF based on the reportType
import firebase from 'firebase/compat/app';
// Required for side-effects
import 'firebase/compat/firestore';
import { database } from '../../../../config/firebaseConfig';
import { ReportInfo, UserInfo } from '../../../../model';
import { getCache, CACHE_KEYS } from '../../../../utilities';
import {
  createKinematicGraphsAnnotations,
  getMeanKinematicData,
  toMatrix,
  transpose,
} from '../../../../hooks/useReports/dataFunctions';

export const loadWALKData = async (reportInfo: ReportInfo, userId: string) => {
  // load the userdata from the cache to retrieve the right logo
  const [logoURL, userInfo, userInfoError] = await getCache<UserInfo>(CACHE_KEYS.USERINFO_CACHE_KEY)
    .then(async (data) => {
      return [`${data?.organization.logoURL}.png`, data as UserInfo, false];
    })
    .catch((error) => {
      // If no logo is found, return the default (ORYX) logo
      return [`c7be044f-5e4d-48ea-a1ef-d888decb5988.png`, null, true];
    });

  const userRef = database.collection('users').doc(userId);
  // load the data for the PDF based on the reportType, using only the data that is needed
  const pdfData = await getWalkData(reportInfo.reportType, reportInfo, userRef)
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.error(error);
    });

  // Create the right data structures for the content of the pdf
  const annotations_Left = pdfData.reportId.pointsOfInterests.col0;
  const annotations_Right = pdfData.reportId.pointsOfInterests.col1;
  const pelvisKinematicData = getMeanKinematicData(pdfData, 'Pelvis');
  const hipKinematicData = getMeanKinematicData(pdfData, 'Hip');
  const kneeKinematicData = getMeanKinematicData(pdfData, 'Knee');
  const ankleKinematicData = getMeanKinematicData(pdfData, 'Ankle');

  const graphAnnotations = createKinematicGraphsAnnotations(annotations_Left, annotations_Right);

  const romData: number[][] = transpose(pdfData.reportId.rom);
  const mobilityData = transpose(pdfData.reportId.mobilityStatus);
  const spatioTemporalData: number[][] = toMatrix(pdfData.reportId.spatioTemporal);
  const kpiData = transpose(pdfData.reportId.keyPerformanceIndicators);
  const kpiLabels = [
    ['Ok', 'Fair', 'Poor'],
    ['Hike', 'Ok', 'Drop'],
    ['Ok', 'Fair', 'Poor', 'No extension'],
  ];

  // Create data structure with the data for the PDF
  const data = {
    logoURL,
    pelvisKinematicData,
    hipKinematicData,
    kneeKinematicData,
    ankleKinematicData,
    graphAnnotations,
    romData,
    mobilityData,
    spatioTemporalData,
    kpiData,
    kpiLabels,
    userInfoError,
  };

  return data;
};

async function getWalkData(
  reportType: number,
  reportInfo: firebase.firestore.DocumentData,
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) {
  const promises = [];
  const collectionMappings: any = {
    1: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    2: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    3: {
      reportId: 'reports',
      fileId: 'fileId',
    },
    4: {
      reportId: 'reports',
      fileId: 'fileId',
    },
  };
  const collectionKeys = Object.keys(collectionMappings[reportType]);

  for (const [key, value] of Object.entries(reportInfo)) {
    if (collectionKeys.includes(key)) {
      if (key === 'fileId') {
        const collectionRef = userRef.collection('kinematicsFiles');
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
        continue;
      } else {
        const collectionName = collectionMappings[reportType][key];
        const collectionRef = userRef.collection(collectionName);
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
      }
    }
  }

  const results = Object.assign({}, ...promises);
  return results;
}

function calculateYAxis(data: any) {
  let yMin = 0;
  let yMax = 0;
  data.forEach((series: any) => {
    series.data.forEach((point: any) => {
      if (point.y < yMin) {
        // setYMin(Math.round(point.y));
        yMin = Math.round(point.y);
      }
      if (point.y > yMax) {
        // setYMax(Math.round(point.y));
        yMax = Math.round(point.y);
      }
    });
  });
  yMin = yMin - 10;
  yMax = yMax + 10;

  const yRange = yMax - yMin;
  const number = Math.round(yRange / 10 + 2);
  const tickAmount = number > 5 ? 5 : number;

  return { yMin, yMax, tickAmount };
}
