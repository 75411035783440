/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonContent, IonLoading, IonPage } from '@ionic/react';

import * as React from 'react';
import { useParams } from 'react-router';

import { ORYXHeader } from '../../../components/menuItems/Header';
import { useReportContext } from '../../../components/reportContext';
import { ReportInfoId, ReportType } from '../../../model';

import { useEffect, useState } from 'react';
import { SingleLegSquatTabBar } from '../components/Tabs';
import { SingleLegSquatReportData } from '../../../model/reportV2.model';
import { createSingleLegSquatReportData } from '../../../hooks/useReports';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const SingleLegSquatReportDetailPageV2 = () => {
  const { id } = useParams<ParamTypes>();
  const { getReportData, dataReady, data, setDataReady, resetData } = useReportContext();
  const [loading, setLoading] = useState(true);
  const [reportName, setReportName] = useState('');
  const [reportData, setSLSquatReportData] = useState<SingleLegSquatReportData | undefined>();
  // Fetch the report data

  useEffect(() => {
    getReportData(id);
    // Cleanup the data when the component unmounts
    return () => {
      setLoading(true);
      setReportName('');
      setSLSquatReportData(undefined);
      setDataReady(false); // Reset dataReady in the context
      resetData();
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      setSLSquatReportData(createSingleLegSquatReportData(data));
      setReportName(data.fileId.reportName);
    } else {
      setSLSquatReportData(undefined);
    }
  }, [dataReady]);

  useEffect(() => {
    if (reportData !== undefined && data) setLoading(false);
  }, [reportData]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('reportData', reportData);
  }, [reportData]);

  return (
    <IonPage>
      <ORYXHeader backTarget='/reports' loading={loading} crumb={data && !loading ? reportName : ''} />
      <IonContent className='ion-padding' id='main' color='light'>
        {loading ? (
          <IonLoading cssClass='my-custom-class' isOpen={!dataReady} message={'Fetching data please wait...'} />
        ) : (
          <SingleLegSquatTabBar id={id} reportData={reportData!} />
        )}

        {/* {data && !loading && <SingleLegSquatTabBar id={id} data={data} />} */}
      </IonContent>
    </IonPage>
  );
};
