import {
  IonModal,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react';
import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import './dashboardCard.css';
import { ModalMobilityStatus } from '../../ReportV2/components/ModalsWalk';
import { ORYXCardHeader } from '../../../components/componentHeaders';

type DashboardCardProps = {
  title: string;
  subtitle?: string;
  info?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const DashboardCard = ({ title, subtitle, info, children, className }: DashboardCardProps) => {
  const [showModal, setShowModal] = useState({
    show: false,
  });

  return (
    <>
      <IonModal isOpen={showModal.show} className='ORYX_Modal_Large' onDidDismiss={() => setShowModal({ show: false })}>
        {info === 'Mobility Status' && <ModalMobilityStatus buttons={true} setShowModal={setShowModal} />}
      </IonModal>

      <IonCard className={className}>
        <ORYXCardHeader title={title} subtitle={subtitle} setShowInfo={setShowModal} info={info ? true : false} />
        {/* <IonCardHeader className='dashboardCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center dashboardCard_Header_Row'>
              <IonCol size='11' className='ion-padding-start'>
                <IonCardTitle className='dashboardCard_Title'>{title}</IonCardTitle>
                {subtitle && <IonCardSubtitle className='dashboardCard_Subtitle'>{subtitle}</IonCardSubtitle>}
              </IonCol>
              {info && (
                <IonCol size='1' className='ion-justify-content-center'>
                  <IonButtons className='ion-justify-content-center'>
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='dashboardCard_Header_Buttons'
                      onClick={() => setShowModal({ show: true })}>
                      <IonIcon icon={informationCircleOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonCardHeader> */}

        <IonCardContent className='full_height ion-no-padding'>
          <IonGrid class='' fixed>
            {children}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
