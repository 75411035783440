// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom } from 'swiper';

interface ORYXVerticalSwiperProps {
  children: React.ReactNode;
}

export const ORYXVerticalSwiper = ({ children }: ORYXVerticalSwiperProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <Swiper
      modules={[Pagination, Mousewheel, Keyboard]}
      pagination={pagination}
      mousewheel={true}
      keyboard={true}
      initialSlide={0}
      speed={400}
      direction='vertical'
      className=''
      style={{ height: '100%' }}
    >
      {children}
    </Swiper>
  );
};
