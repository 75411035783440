import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { useEffect } from 'react';
import { IonSpinner } from '@ionic/react';
import { KinematicYAxis, kinematicGraphAnnotations } from '../../../../model/reportV2.model';

type KinematicsGraphProps = {
  data: any;
  title: string;
  subtitle: string;
  graphAnnotations: kinematicGraphAnnotations;
  strokeWidth: number;
  forPdf?: boolean;
  yAxis: KinematicYAxis;
};

export const KinematicsGraph = React.forwardRef(
  (
    { data, title, subtitle, graphAnnotations, strokeWidth, forPdf = false, yAxis }: KinematicsGraphProps,
    ref: React.ForwardedRef<Chart>,
  ) => {
    const [loading, setLoading] = React.useState(!forPdf ? true : false);

    const options: ApexOptions = {
      chart: {
        type: 'line',
        width: '100%',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        offsetX: forPdf ? -5 : -10,
      },
      xaxis: {
        type: 'numeric',
        labels: {
          style: {
            fontSize: '8px',
          },
        },
      },
      yaxis: {
        show: true,
        min: yAxis.yMin,
        max: yAxis.yMax,
        tickAmount: yAxis.tickAmount,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(0);
          },
          style: {
            fontSize: '8px',
          },
        },
        axisTicks: {
          show: true,
        },
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      title: {
        text: !forPdf ? title : '',
        align: 'center',
        margin: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat',
          color: '#2066FA',
        },
      },
      subtitle: {
        text: subtitle,
        align: 'center',
        margin: forPdf ? 5 : -10,
        floating: forPdf ? true : false,
        offsetY: forPdf ? 5 : 25,
        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat',
        },
      },
      stroke: {
        width: strokeWidth,
        dashArray: [0],
      },
      legend: {
        show: forPdf ? false : true,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '9px',
        markers: {
          width: 8,
          height: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      annotations: {
        xaxis: graphAnnotations,
        yaxis: [
          {
            y: 0,
            borderColor: 'red',
          },
        ],
      },
      markers: {
        size: [0, 0, 2],
        strokeWidth: 0,
        showNullDataPoints: false,
      },
      tooltip: {
        enabled: false,
      },
    };

    useEffect(() => {
      if (!forPdf) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }, [data]);

    return (
      <div className='kinematicsGraph_Wrapper'>
        {!loading ? (
          <Chart
            ref={ref}
            options={options}
            series={data}
            type='line'
            height={forPdf ? 'auto' : '100%'}
            width={forPdf ? 250 : '100%'}
          />
        ) : (
          <IonSpinner name='bubbles' color='primary' />
        )}
      </div>
    );
  },
);
