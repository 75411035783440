import { flattenData, toGraphData } from '.';
import { GraphSeriesData, StridesData } from '../../../model/reportV2.model';
import { colorLeft, colorRight } from '../../../theme/colors';

/**
 * Creates an array of KinematicSeriesData from the provided left and right StridesData.
 *
 * @param leftData - The left StridesData.
 * @param rightData - The right StridesData.
 * @returns An array of KinematicSeriesData.
 */
export function createAllStridesSeriesData(leftData: StridesData, rightData: StridesData): GraphSeriesData[] {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: flattenData(left), color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: flattenData(right), color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}
