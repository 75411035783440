import { DataPoint } from '../../../model/reportV2.model';

/**
 * Function to create tuples for scatterdata where the x and y values are in 1 column.
 * used in the scissors for example to plot the scissors dots. returns:
 * {
      x: number;
      y: number | null;
    }[][]
 */
export function toScissorsData(data: any): DataPoint[][] {
  if (!data) {
    return [];
  }
  const numCycles = Object.keys(data).length;
  const graphData: DataPoint[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: DataPoint[] = [];

    cycleData.push({ x: data[`col${i}`][0], y: data[`col${i}`][1] });

    graphData.push(cycleData);
  }

  return graphData;
}
