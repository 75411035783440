import { SingleSideVariabilityGraphAnnotations } from '../../../model/reportV2.model';

/**
 * Creates annotations for a single-side variability graph.
 *
 * @param annotations - An array of annotations.
 * @param highlights - An object containing the x and x2 values for the highlights.
 * @returns An array of graph annotations or undefined.
 */
export function createVariabilitySingleSideGraphAnnotations(
  annotations: any[],
  highlights: { x: any; x2: any },
): SingleSideVariabilityGraphAnnotations[] {
  if (annotations[5] > annotations[3]) {
    return [
      {
        x: annotations[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: highlights.x,
        x2: highlights.x2,
        borderColor: '#c2c2c2',
        fillColor: '#ABF263',
      },
    ];
  } else
    return [
      {
        x: annotations[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[5],
        borderColor: '#c2c2c2',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: highlights.x,
        x2: highlights.x2,
        borderColor: '#c2c2c2',
        fillColor: '#ABF263',
      },
    ];
}
