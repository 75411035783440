export {};
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSearchbar,
  IonText,
  IonToast,
} from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../../components/menuItems/Header';

// custom imports
import { ORYXMeasurements } from './ORYX_Measurements';
import { KnowledgeBaseDashboardCard } from './components';
import { ORYXAnalyses } from './ORYX_Analysis';
import { Route } from 'react-router';

export const KBHomePage = () => {
  return (
    <IonPage>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed className='full_height'>
          <IonRow className='ion-padding-vertical border_Bottom'>
            <IonCol size='12'>
              <IonText className='ion-text-center'>
                <h5>ORYX Measurements</h5>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center' style={{ paddingTop: '20px' }}>
            {ORYXMeasurements.map((ORYXMeasurement) => {
              return (
                <>
                  <IonCol size='8' size-md='4' key={ORYXMeasurement.id}>
                    <KnowledgeBaseDashboardCard
                      title={ORYXMeasurement.title}
                      icon={ORYXMeasurement.iosIcon}
                      description={ORYXMeasurement.description}
                      url={ORYXMeasurement.url}
                    />
                  </IonCol>
                </>
              );
            })}
          </IonRow>
          <IonRow className='ion-padding-vertical border_Bottom'>
            <IonCol size='12'>
              <IonText className='ion-text-center'>
                <h5>ORYX Analysis</h5>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            {ORYXAnalyses.map((ORYXAnalysis) => {
              return (
                <>
                  <IonCol size='8' size-md='4' key={ORYXAnalysis.id}>
                    <KnowledgeBaseDashboardCard
                      title={ORYXAnalysis.title}
                      icon={ORYXAnalysis.iosIcon}
                      description={ORYXAnalysis.description}
                      url={ORYXAnalysis.url}
                    />
                  </IonCol>
                </>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
