import { kinematicGraphAnnotations } from '../../../model/reportV2.model';

/**
 * Creates annotations for kinematic graphs.
 *
 * @param annotations_Left - An array of annotations for the left graph.
 * @param annotations_Right - An array of annotations for the right graph.
 * @returns An array of graph annotations or undefined.
 */
export function createKinematicGraphsAnnotations(
  annotations_Left: any[],
  annotations_Right: any[],
): kinematicGraphAnnotations {
  if (annotations_Left[5] > annotations_Left[3] && annotations_Right[5] < annotations_Right[3]) {
    return [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[5],
        borderColor: '#00E396',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
  } else if (annotations_Left[5] < annotations_Left[3] && annotations_Right[5] > annotations_Right[3]) {
    return [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[5],
        borderColor: '#c2c2c2',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },

      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
  } else if (annotations_Left[5] > annotations_Left[3] && annotations_Right[5] > annotations_Right[3]) {
    return [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
  } else
    return [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[5],
        borderColor: '#c2c2c2',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 10,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },

      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[5],
        borderColor: '#00E396',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'top',
          orientation: 'horizontal',
          offsetY: -8,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
}
