// #region Graph Data types
export type StridesData = {
  [key: string]: any;
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2 ...
};
export type HighLightData = {
  x: number;
  x2: number;
};

export type AnnotationData = {
  title: string;
  x: number;
};

export type kinematicGraphAnnotations = {
  x: any;
  borderColor: string;
  label: {
    text: string;
    borderColor: string;
    position: string;
    orientation: string;
    offsetY: number;
    style: { background: string; fontSize: string; fontFamily: string };
  };
}[];

export type SingleSideVariabilityGraphAnnotations =
  | {
      x: any;
      borderColor: string;
      label: {
        text: string;
        borderColor: string;
        position: string;
        orientation: string;
        offsetY: number;
        style: { background: string; fontSize: string; fontFamily: string };
      };
      x2?: undefined;
      fillColor?: undefined;
    }
  | { x: any; x2: any; borderColor: string; fillColor: string; label?: undefined };
[];

export type KinematicYAxis = {
  yMin: number;
  yMax: number;
  yRange: number;
  tickAmount: number;
};

export type Annotations_Stance_Left = {
  ICAnnotation_Left: AnnotationData;
  MStAnnotation_Left: AnnotationData;
  HOAnnotation_Left: AnnotationData;
  TOAnnotation_Left: AnnotationData;
};

export type Annotations_Stance_Right = {
  ICAnnotation_Right: AnnotationData;
  MStAnnotation_Right: AnnotationData;
  HOAnnotation_Right: AnnotationData;
  TOAnnotation_Right: AnnotationData;
};

export type LegendEntry = {
  name: string;
  color: string;
  id: string;
  side?: string;
};

export type DataPoint = {
  x: number;
  y: number;
};

export type DataPointORNUll = {
  x: number;
  y: number | null;
};

export type GraphSeriesData = {
  type: string;
  name: string;
  data: DataPoint[] | DataSeries;
  color: string;
};

export type BarGraphData = {
  name?: string;
  data: number[];
  color?: string;
}[];

export type LineGraphData = {
  name: string;
  data: DataSeries;
}[];

export type KinematicData = {
  allStrides: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } };
  mean: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } };
};

export type FootplantSeriesData = {
  type: string;
  name: string;
  data: DataPoint[];
};

export type JointCouplingSeriesData = {
  type: string;
  name: string;
  data: DataPoint[];
};

export type JointCouplingAxisRange = {
  xMinValue: number;
  xMaxValue: number;
  xTickAmount: number;
};

export type DataSeries = { x: number; y: number }[];

export type GraphData = DataPoint[] | DataPoint[][] | DataPointORNUll[] | DataPointORNUll[][];

export type PelvisVariabilityData = {
  crpSeriesDataPelvisThighT: GraphSeriesData[];
  meanCrpSeriesDataPelvisThigh: GraphSeriesData[];
  crpSeriesDataPelvisCThighT: GraphSeriesData[];
  meanCrpSeriesDataPelvisThighT: GraphSeriesData[];
  crpSeriesDataPelvisCThigh: GraphSeriesData[];
  meanCrpSeriesDataPelvisCThighT: GraphSeriesData[];
  crpSeriesDataPelvisThigh: GraphSeriesData[];
  meanCrpSeriesDataPelvisCThigh: GraphSeriesData[];
  coordinativeVariabilitySeriesAllHip: GraphSeriesData[];
  coordinativeVariabilitySeriesMeanHip: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisThigh: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisThighT: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisCThighT: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisCThigh: GraphSeriesData[];
};

export type VariabilityJointGraphDataEntry = {
  Left: {
    crpData: GraphSeriesData[];
    coordinativeVariabilityData: GraphSeriesData[];
  };
  Right: {
    crpData: GraphSeriesData[];
    coordinativeVariabilityData: GraphSeriesData[];
  };
};

export type VariabilityGraphData = {
  Pelvis: VariabilityJointGraphDataEntry;
  Knee: VariabilityJointGraphDataEntry;
  Ankle: VariabilityJointGraphDataEntry;
};

export type PelvisVariabilityData_FunctionalMovement = {
  crpSeriesDataPelvisThighT: GraphSeriesData[];
  crpSeriesDataPelvisCThighT: GraphSeriesData[];
  crpSeriesDataPelvisCThigh: GraphSeriesData[];
  crpSeriesDataPelvisThigh: GraphSeriesData[];
  coordinativeVariabilitySeriesAllHip: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisThigh: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisThighT: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisCThighT: GraphSeriesData[];
  coordinativeVariabilitySeriesPelvisCThigh: GraphSeriesData[];
};

export type KneeVariabilityData = {
  crpSeriesDataThighCalfT: GraphSeriesData[];
  meanCrpSeriesDataThighCalf: GraphSeriesData[];
  crpSeriesDataThighCCalfT: GraphSeriesData[];
  meanCrpSeriesDataThighCCalfT: GraphSeriesData[];
  crpSeriesDataThighCalf: GraphSeriesData[];
  meanCrpSeriesDataThighCalfT: GraphSeriesData[];
  coordinativeVariabilitySeriesAllKnee: GraphSeriesData[];
  coordinativeVariabilitySeriesMeanKnee: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCalf: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCalfT: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCCalfT: GraphSeriesData[];
};

export type KneeVariabilityData_FunctionalMovement = {
  crpSeriesDataThighCalfT: GraphSeriesData[];
  crpSeriesDataThighCCalfT: GraphSeriesData[];
  crpSeriesDataThighCalf: GraphSeriesData[];
  coordinativeVariabilitySeriesAllKnee: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCalf: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCalfT: GraphSeriesData[];
  coordinativeVariabilitySeriesThighCCalfT: GraphSeriesData[];
};

export type AnkleVariabilityData = {
  crpSeriesDataCalfTFootC: GraphSeriesData[];
  meanCrpSeriesDataCalfFoot: GraphSeriesData[];
  crpSeriesDataCalfFoot: GraphSeriesData[];
  meanCrpSeriesDataCalfTFootC: GraphSeriesData[];
  coordinativeVariabilitySeriesAllAnkle: GraphSeriesData[];
  coordinativeVariabilitySeriesMeanAnkle: GraphSeriesData[];
  coordinativeVariabilitySeriesCalfFoot: GraphSeriesData[];
  coordinativeVariabilitySeriesCalfTFootC: GraphSeriesData[];
};

export type AnkleVariabilityData_FunctionalMovement = {
  crpSeriesDataCalfTFootC: GraphSeriesData[];
  crpSeriesDataCalfFoot: GraphSeriesData[];
  coordinativeVariabilitySeriesAllAnkle: GraphSeriesData[];
  coordinativeVariabilitySeriesCalfFoot: GraphSeriesData[];
  coordinativeVariabilitySeriesCalfTFootC: GraphSeriesData[];
};

//#endregion

//#region general types
export type Joint = 'Pelvis' | 'Hip' | 'Knee' | 'Ankle';
export type FileInfo = {
  filename: string;
  notes: string[];
  option: string;
  reportName: string;
  tags: string[];
  uploadTimestamp: number;
};

export enum ReportType {
  WALK = 1,
  RUN = 2,
  SQUAT = 3,
  SLSQUAT = 4,
  ERROR = 999,
  KNEEL = 998,
  KNEER = 997,
}

export type JointCouplingData = {
  seriesJointCoupling_Left: JointCouplingSeriesData[];
  seriesJointCoupling_Right: JointCouplingSeriesData[];
  jointCouplingAxisRange: JointCouplingAxisRange;
  jointCouplingZoomModalContent: {
    seriesJointCoupling_Left: JointCouplingSeriesData[];
    seriesJointCoupling_Right: JointCouplingSeriesData[];
  };
};

//#endregion

//#region Walk Report Data types
export const kpiLabels = [
  ['Ok', 'Fair', 'Poor'],
  ['Hike', 'Ok', 'Drop'],
  ['Ok', 'Fair', 'Poor', 'No extension'],
];

export type SpeedLabel = 'Ok' | 'Slow' | 'Too slow' | 'Too fast';

export type MobilityStatusData = {
  romData: number[][];
  statusData: number[][];
  tableData: number[][];
  speedLabel: SpeedLabel;
};

export type AnalysisData = {
  romData: number[][];
  kpiData: number[][];
  kpiLabels: string[][];
  varusValgusLabel_Left: string;
  varusValgusLabel_Right: string;
};

export type walkVariabilityData = {
  graphAnnotationsHipAnkle_Left: SingleSideVariabilityGraphAnnotations[];
  graphAnnotationsHipAnkle_Right: SingleSideVariabilityGraphAnnotations[];
  graphAnnotationsKnee_Left: SingleSideVariabilityGraphAnnotations[];
  graphAnnotationsKnee_Right: SingleSideVariabilityGraphAnnotations[];
  annotations_Left: number[];
  highlightsHip_Left: HighLightData;
  highlightsKnee_Left: HighLightData;
  highlightsKnee_Right: HighLightData;
  annotations_Right: number[];
  highlightsHip_Right: HighLightData;
  mean: VariabilityGraphData;
  allStrides: VariabilityGraphData;
  modalData: VariabilityGraphData;
  pelvisLegend: LegendEntry[];
  kneeLegend: LegendEntry[];
  ankleLegend: LegendEntry[];
};

export type WalkKinematicData = {
  romData: number[][];
  tableData: number[][];
  graphAnnotations: kinematicGraphAnnotations;
  tableClassNames: any;
  pelvisKinematicData: KinematicData;
  hipKinematicData: KinematicData;
  kneeKinematicData: KinematicData;
  ankleKinematicData: KinematicData;
};

export type WalkReportData = {
  mobilityStatusData: MobilityStatusData;
  analysisData: AnalysisData;
  walkVariabilityData: walkVariabilityData;
  walkKinematicData: WalkKinematicData;
  walkJointCouplingData: JointCouplingData;
  fileInfo: FileInfo;
  reportType: ReportType;
};
//#endregion

//#region Run Report Data types
export const attractorLabelsV1 = [
  ['Positive', 'Negative'], // Positive Running - Toe off (0)
  ['Sufficient', 'Absent'], // Ankle Stiffness (1)
  ['Reactive', 'No extension', 'Hyperext.'], //Knee Extension - preswing (2)
  ['Lock', 'Ok', 'Drop'], // Hip Lock (3)
  ['Straight', 'Round'], // Rear Pendulum (4)
  ['Fierce', 'Slow'], // Swingleg Recovery (5)
  ['Ok', 'Obtuse'], // Knee angle - Midswing (6)
  ['Positive', 'Negative'], // Positive Running - Midstance (7)
  ['Ok', 'Poor'], // Projection Footplant (8)
  ['Active', 'Passive'], // Swingleg Retraction (9)
  ['Ok', 'Fair', 'Late'], // Scissors (10)
  ['Ok', 'Poor'], // Footplant from above (11)
  ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
  ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
  ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
  ['Ok', 'Fair', 'Poor'], // Hip Lock after version 2.1.9 (15)
  ['Ok', 'Fair', 'Poor'], // Ankle Stiffness after version 2.1.9 (16)
];
// After version 2.1.10
export const attractorLabelsV2 = [
  ['Positive', 'Negative'], // Positive Running - Toe off (0)
  ['Ok', 'Fair', 'Poor'], // Ankle Stiffness (1)
  ['Reactive', 'No extension', 'Hyperext.'], //Knee Extension - preswing (2)
  ['Ok', 'Fair', 'Poor'], // Hip Lock (3)
  ['Straight', 'Round'], // Rear Pendulum (4)
  ['Fierce', 'Slow'], // Swingleg Recovery (5)
  ['Ok', 'Obtuse'], // Knee angle - Midswing (6)
  ['Positive', 'Negative'], // Positive Running - Midstance (7)
  ['Ok', 'Poor'], // Projection Footplant (8)
  ['Active', 'Passive'], // Swingleg Retraction (9)
  ['Ok', 'Fair', 'Late'], // Scissors (10)
  ['Ok', 'Poor'], // Footplant from above (11)
  ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
  ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
  ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
];

export type Attractors = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

export type RearPendulumPointsOfInterests_Left = {
  [key: string]: number[];
  col0: number[]; // Cycle 1
  col1: number[]; // Cycle 2...
  // x positions toeOff Left
  // z positions toeOff Left
  // x positions midSwing Left
  // z positions midSwing Left
};

export type RearPendulumGraphData = {
  rearPendulumGraphData_Left: JointCouplingSeriesData[];
  rearPendulum_ToeOff_Left: DataPoint;
  rearPendulum_MidSwing_Left: DataPoint;
  rearPendulumStance_Left: JointCouplingSeriesData;
  rearPendulumVariance_ToeOff_Left: any;
  rearPendulumVariance_MidSwing_Left: any;
  rearPendulumGraphData_Right: JointCouplingSeriesData[];
  rearPendulum_ToeOff_Right: DataPoint;
  rearPendulum_MidSwing_Right: DataPoint;
  rearPendulumStance_Right: JointCouplingSeriesData;
  rearPendulumVariance_ToeOff_Right: number;
  rearPendulumVariance_MidSwing_Right: number;
  rearPendulumZoomModalContent: {
    rearPendulumGraphData_Left: JointCouplingSeriesData[];
    rearPendulum_ToeOff_Left: DataPoint;
    rearPendulum_MidSwing_Left: DataPoint;
    rearPendulumVariance_ToeOff_Left: any;
    rearPendulumVariance_MidSwing_Left: any;
    rearPendulumGraphData_Right: JointCouplingSeriesData[];
    rearPendulum_ToeOff_Right: DataPoint;
    rearPendulum_MidSwing_Right: DataPoint;
    rearPendulumVariance_ToeOff_Right: number;
    rearPendulumVariance_MidSwing_Right: number;
    rearPendulumStance_Left: JointCouplingSeriesData;
    rearPendulumStance_Right: JointCouplingSeriesData;
  };
};

export type FootplantSeries_Left = {
  footplantAtInitialContactSeries_Left: FootplantSeriesData[];
  footplantAtMidStanceSeries_Left: FootplantSeriesData[];
  footplantAtHeelOffSeries_Left: FootplantSeriesData[];
};

export type FootplantSeries_Right = {
  footplantAtInitialContactSeries_Right: FootplantSeriesData[];
  footplantAtMidStanceSeries_Right: FootplantSeriesData[];
  footplantAtHeelOffSeries_Right: FootplantSeriesData[];
};

export type AttractorLabels = string[][];

export type RunAttractorData = {
  attractorData: number[][];
  attractorLabels: AttractorLabels;
};

export type ScissorsData = {
  graphData: GraphSeriesData[];
  colors: string[];
  firstMarker: string;
  secondMarker: string;
};

export type SymmetryTableData = {
  left: number;
  right: number;
  symmetry: number;
  unit: string;
};

export type VelocityTableData = {
  terminalSwing: SymmetryTableData;
  stance: SymmetryTableData;
  peakTerminalSwing?: SymmetryTableData;
  peakTerminalStance?: SymmetryTableData;
};

export type AccelerationTableData = {
  terminalSwing: SymmetryTableData;
  stance: SymmetryTableData;
};

export type ScissorsTableEntry = {
  averageVelocity: SymmetryTableData;
};

export type ScissorsTableData = {
  scissors: ScissorsTableEntry;
  averageVelocities: VelocityTableData;
  peakVelocitiesAndAccelerations: {
    velocity: VelocityTableData;
    acceleration: AccelerationTableData;
  };
};

export type HamstringBarGraphData = {
  thighVelocityBarData: BarGraphData;
  thighAccelerationBarData: BarGraphData;
  thighVelocityBarData_std: any;
  thighAccelerationBarData_std: any;
  yAxisLabelAcceleration: string;
  yAxisLabelVelocity: string;
};

export type CoordinativeVariabilityStanceData = {
  coordinativeVariabilityData_Left: {
    series: GraphSeriesData[];
    annotations: Annotations_Stance_Left;
  };
  coordinativeVariabilityData_Right: {
    series: GraphSeriesData[];
    annotations: Annotations_Stance_Right;
  };
};

export type SwingLegRetractionData = {
  thighAccelerationSeriesData_Left: LineGraphData;
  thighAccelerationSeriesData_Right: LineGraphData;
  yMinValue: number;
  yMaxValue: number;
};

export type RunKinematicData = {
  graphAnnotations: kinematicGraphAnnotations;
  pelvisKinematicData: KinematicData;
  hipKinematicData: KinematicData;
  kneeKinematicData: KinematicData;
  ankleKinematicData: KinematicData;
};

export type RunVariabilityData = {
  graphAnnotationsHip_Left: SingleSideVariabilityGraphAnnotations[];
  graphAnnotationsHip_Right: SingleSideVariabilityGraphAnnotations[];
  graphAnnotations_Left: SingleSideVariabilityGraphAnnotations[];
  graphAnnotations_Right: SingleSideVariabilityGraphAnnotations[];
  annotations_Left: number[];
  highlightsHip_Left: HighLightData;
  highlights_Left: HighLightData;
  highlights_Right: HighLightData;
  annotations_Right: number[];
  highlightsHip_Right: HighLightData;
  mean: VariabilityGraphData;
  allStrides: VariabilityGraphData;
  modalData: VariabilityGraphData;
  pelvisLegend: LegendEntry[];
  kneeLegend: LegendEntry[];
  ankleLegend: LegendEntry[];
};

export type RunningStatusData = {
  runningStatusTableData: number[][];
  romData: number[][];
  rearPendulumData: RearPendulumGraphData;
  footplantSeries_Left: FootplantSeries_Left;
  footplantSeries_Right: FootplantSeries_Right;
  respectTheSagittalPlaneSeries: GraphSeriesData[];
  annotations: number[];
};

export type ScissorsReportData = {
  scissorsData_Left: ScissorsData;
  scissorsData_Right: ScissorsData;
  scissorsBarGraphData: BarGraphData;
  runJointCouplingData: JointCouplingData;
  hamstringData: HamstringBarGraphData;
  coordinativeVariabilityData: CoordinativeVariabilityStanceData;
  swingLegRetractionData: SwingLegRetractionData;
  scissorsTableData: ScissorsTableData;
};

export type RunReportData = {
  runAttractorData: RunAttractorData;
  runningStatusData: RunningStatusData;
  runScissorsData: ScissorsReportData;
  runKinematicData: RunKinematicData;
  runVariabilityData: RunVariabilityData;
  fileInfo: FileInfo;
  reportType: ReportType;
};
//#endregion

//#region Squat Report Data types
export const varusValgusLabels_FunctionalMovement = [['Ok', 'Fair', 'Poor', 'No Varus/Valgus']];
export const squatLabels = [
  ['No', 'Yes'], // Early Heel raise,
  ['Ok', 'Fair', 'Poor'], // Hip Flexion
  ['Ok', 'Fair', 'Poor', 'No exorotation'], // Hip Abduction
  ['Ok', 'Fair', 'Poor', 'No endorotation'], // Hip adduction
  ['Ok', 'Fair', 'Poor', 'No Varus/Valgus'], // Varus Valgus
];

export type RepetitionTimeData = {
  [key: string]: number[];
  col0: number[]; // Repetition Time
  col1: number[]; // Repetition Time Std
  col2: number[]; // Repetition Time Quality
  col3: number[]; // Number of Repetitions
};
export type RepetitionTimesData = {
  [key: string]: number[];
  col0: number[]; // repetitionTimes
  col1: number[]; // repetitionQualities
};

export type SquatHipLabels = {
  externalRotationLabel_Left: string;
  externalRotationLabel_Right: string;
  internalRotationLabel_Left: string;
  internalRotationLabel_Right: string;
};

export type SquatAnkleLabels = {
  earlyHeelRaiseLabel_Left: string;
  earlyHeelRaiseLabel_Right: string;
};

export type SquatAnalysisData = {
  romData: number[][];
  qualityData: number[][];
  repetitionData: RepetitionTimeData;
  varusValgusLabel_Left: string;
  varusValgusLabel_Right: string;
};

export type SquatKinematicData = {
  romData: number[][];
  hipLabels: SquatHipLabels;
  ankleLabels: SquatAnkleLabels;
  varusValgusLabel_Left: string;
  varusValgusLabel_Right: string;
  pelvisKinematicData: KinematicData;
  hipKinematicData: KinematicData;
  kneeKinematicData: KinematicData;
  ankleKinematicData: KinematicData;
};

export type SquatVariabilityData = {
  crpSeriesPelvisThigh_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewHip_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesHip_Left: GraphSeriesData[];
  crpSeriesPelvisThigh_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewHip_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesHip_Right: GraphSeriesData[];
  crpSeriesThighCalf_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewKnee_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesKnee_Left: GraphSeriesData[];
  crpSeriesThighCalf_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewKnee_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesKnee_Right: GraphSeriesData[];
  crpSeriesCalfFoot_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewAnkle_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesAnkle_Left: GraphSeriesData[];
  crpSeriesCalfFoot_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewAnkle_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesAnkle_Right: GraphSeriesData[];
  pelvisLegend: LegendEntry[];
  kneeLegend: LegendEntry[];
  ankleLegend: LegendEntry[];
};

export type SquatReportData = {
  squatAnalysisData: SquatAnalysisData;
  squatKinematicData: SquatKinematicData;
  squatVariabilityData: SquatVariabilityData;
  fileInfo: FileInfo;
  reportType: ReportType;
};
//#endregion

//#region Single Leg Squat Report Data types
export type QScore = 'Excellent' | 'Good' | 'Moderate' | 'Poor';

export const singleLegSquatLabels = [
  ['No', 'Yes'], // Early Heel raise,
  ['Ok', 'Fair', 'Poor'], // Hip Flexion
  ['Ok', 'Fair', 'Poor', 'No exorotation'], // Hip Exorotation
  ['Ok', 'Fair', 'Poor', 'No endorotation'], // Hip Endorotation
  ['Ok', 'Fair', 'Poor', 'No abduction'], // Hip Abduction
  ['Ok', 'Fair', 'Poor', 'No adduction'], // Hip Adduction
  ['Ok', 'Fair', 'Poor', 'No Varus/Valgus'], // Varus Valgus
];

export type SingleLegSquatHipLabels = {
  externalRotationLabel_Left: string;
  externalRotationLabel_Right: string;
  internalRotationLabel_Left: string;
  internalRotaionLabel_Right: string;
  abductionLabel_Left: string;
  abductionLabel_Right: string;
  adductionLabel_Left: string;
  adductionLabel_Right: string;
};

export type SingleLegSquatAnkleLabels = {
  earlyHeelRaiseLabel_Left: string;
  earlyHeelRaiseLabel_Right: string;
};

export type SLSquatAnalysisData = {
  romData: number[][];
  qualityData: number[][];
  repTime_Left: number;
  stdRepTime_Left: number;
  repTime_Right: number;
  stdRepTime_Right: number;
  qScore_Left: number;
  qScore_Right: number;
  qScoreLabel_Left: QScore;
  qScoreLabel_Right: QScore;
  varusValgusLabel_Left: string;
  varusValgusLabel_Right: string;
};

export type SingleLegSquatKinematicData = {
  romData: number[][];
  hipLabels: SingleLegSquatHipLabels;
  ankleLabels: SingleLegSquatAnkleLabels;
  varusValgusLabel_Left: string;
  varusValgusLabel_Right: string;
  pelvisKinematicData: KinematicData;
  hipKinematicData: KinematicData;
  kneeKinematicData: KinematicData;
  ankleKinematicData: KinematicData;
};
export type SingleLegSquatVariabilityData = {
  crpSeriesPelvisThigh_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewHip_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesHip_Left: GraphSeriesData[];
  crpSeriesPelvisThigh_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewHip_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesHip_Right: GraphSeriesData[];
  crpSeriesThighCalf_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewKnee_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesKnee_Left: GraphSeriesData[];
  crpSeriesThighCalf_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewKnee_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesKnee_Right: GraphSeriesData[];
  crpSeriesCalfFoot_Left: GraphSeriesData[];
  coordinativeVariabilityOverviewAnkle_Left: GraphSeriesData[];
  coordinativeVariabilitySeriesAnkle_Left: GraphSeriesData[];
  crpSeriesCalfFoot_Right: GraphSeriesData[];
  coordinativeVariabilityOverviewAnkle_Right: GraphSeriesData[];
  coordinativeVariabilitySeriesAnkle_Right: GraphSeriesData[];
  pelvisLegend: LegendEntry[];
  kneeLegend: LegendEntry[];
  ankleLegend: LegendEntry[];
};

export type SingleLegSquatReportData = {
  squatAnalysisData: SLSquatAnalysisData;
  squatKinematicData: SingleLegSquatKinematicData;
  squatVariabilityData: SingleLegSquatVariabilityData;
  fileInfo: FileInfo;
  reportType: ReportType;
};
//#endregion
