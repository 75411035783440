import React, { useEffect, useReducer, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonLoading, IonSpinner } from '@ionic/react';
import './modalindex.css';

import { ORYXModalHeader } from '../../../../components/componentHeaders';
import { ORYXVerticalSwiper } from '../../../../components/swiper';

// Slides
import { SwiperSlide } from 'swiper/react';
import { RearPendulumModalGraph } from '../Graphs';

interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
  data: any;
}

export const ZoomModalRearPendulum = ({ buttons, setShowModal, data }: ModalProps) => {
  const [loading, setIsLoading] = useState(true);
  console.log(data);

  const cleanUp = () => {
    setShowModal({ show: false });
  };
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    return () => {
      setIsLoading(true);
    };
  }, []);

  return (
    <>
      <ORYXModalHeader title='Rear Pendulum' setShowInfo={cleanUp} buttons={true} />
      {loading ? (
        <IonSpinner name='bubbles' className='Modal_Spinner' color='primary' />
      ) : (
        <ORYXVerticalSwiper>
          <SwiperSlide className='' style={{ height: '100%' }}>
            <IonGrid fixed className='' style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
                <IonCol size='6' className='' style={{ height: '75%' }}>
                  <IonRow style={{ height: '75%' }} className=''>
                    <RearPendulumModalGraph
                      side={'Left'}
                      data={data.rearPendulumGraphData_Left}
                      TOLocation={data.rearPendulum_ToeOff_Left}
                      MSwLocation={data.rearPendulum_MidSwing_Left}
                      stanceData={data.rearPendulumStance_Left}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <p> &sigma; TO: {data.rearPendulumVariance_ToeOff_Left}</p>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <p> &sigma; MSw: {data.rearPendulumVariance_MidSwing_Left}</p>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size='6' style={{ height: '75%' }} className='ion-align-items-center'>
                  <IonRow style={{ height: '75%' }} className=''>
                    <RearPendulumModalGraph
                      side={'Right'}
                      data={data.rearPendulumGraphData_Right}
                      TOLocation={data.rearPendulum_ToeOff_Right}
                      MSwLocation={data.rearPendulum_MidSwing_Right}
                      stanceData={data.rearPendulumStance_Right}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <p> &sigma; TO: {data.rearPendulumVariance_ToeOff_Right}</p>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <p> &sigma; MSw: {data.rearPendulumVariance_MidSwing_Right}</p>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>
        </ORYXVerticalSwiper>
      )}
    </>
  );
};
